
export const environment = {
  production: true,
  API_URL: "https://clubclassadminapi.galerijabelgrade.com/",
  BASE_URL: "https://clubclassadmin.galerijabelgrade.com/",
  ty_url: "https://thankyouapi.galerijabelgrade.com",
	ty_admin_url: "https://thankyouadmin.galerijabelgrade.com",
  envName: "production",
  RULES_ENGINE_URL: "https://clubclassadminapi.galerijabelgrade.com/api/tenant/update_rules/",
  RE_API_TOKEN: "YLJuX2htqUBmWRw1n7zf",
  RAFFLE_IMG_URL: "https://clubclassadminapi.galerijabelgrade.com/uploads/raffle_images/",
};
