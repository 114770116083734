import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class RoleService {

    constructor(private custom_api_calling : Custom_api_calling) { }

    create_tenant_user_role(data, callback) {
        this.custom_api_calling.http_calling_file_post("api/tenant/role/add_tenant_user_role ", data.body, (result) => callback(result));
    }

    edit_tenant_user_role(data, callback) {
        this.custom_api_calling.http_calling_json_put("api/tenant/role/edit_tenant_user_role/" + data.id, data.body, (result) => callback(result));
    }

    get_tenant_user_role_by_id(data, callback) {
        this.custom_api_calling.http_calling_get("api/tenant/role/get_tenant_user_role/" + data.id, (result) => callback(result));
    }

    get_all_tenant_user_role(data, callback) {
        // let url = "api/tenant/role/get_tenant_user_roles_list/?";
        // url += (data.search ? "&search=" + data.search : "");
        // url += (data.from_date ? "&from_date=" + data.from_date : "");
        // url += (data.to_date ? "&to_date=" + data.to_date : "");
		// this.custom_api_calling.http_calling_get(url, (result) => callback(result));
        let query_data = this.get_query_data(data);
        this.custom_api_calling.http_calling_get('api/tenant/role/get_tenant_user_roles_list/?'+query_data, (result) => callback(result));
	}
	get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }
    update_tenant_user_role_status(data, callback) {
        this.custom_api_calling.http_calling_json_put("api/tenant/role/update_status", data.body, (result) => callback(result));
    }

    get_role_privileges(data, callback) {
        this.custom_api_calling.http_calling_get("api/tenant/privileges/get_tenant_role_privileges?role_id=" + data.role_id, (result) => callback(result));
    }

    update_role_privileges(data, callback) {
        this.custom_api_calling.http_calling_json_put("api/tenant/privileges/update_tenant_role_privileges", data.body, (result) => callback(result));
    }

}
