import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import {HttpClient} from '@angular/common/http';

@Injectable()

export class AffiliateReportService {

	constructor(private api_adapter: Custom_api_calling, private http: HttpClient) {

	}

	getAffiliateReport(data: any, callback: any) {
		let queryData = this.queryData(data);
		this.api_adapter.http_calling_get("api/tenant/affiliate/getAffiliateReport?" + queryData, (result: any) => callback(result));
    }
    
    exportAffiliateReport(data: any, callback: any) {
		let queryData = this.queryData(data);
		this.api_adapter.http_calling_file_get("api/tenant/affiliate/exportAffiliateReport?" + queryData, (result: any) => callback(result));
    }

    uploadAffiliateReportFile(data: any, callback: any) {
        this.api_adapter.http_calling_file_post("api/tenant/affiliate/uploadAffiliateReportFile", data, (result: any) => callback(result));
    }

    getAffiliateFiles(data: any, callback: any) {
		let queryData = this.queryData(data);
		this.api_adapter.http_calling_get("api/tenant/affiliate/getAffiliateFiles?" + queryData, (result: any) => callback(result));
    }

	// common functions

    queryData(data: any) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

}
