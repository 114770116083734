import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { Injectable } from '@angular/core';

@Injectable()
export class ReferralProgramTypeService {

	constructor(private api_adapter: Custom_api_calling) { }

	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}

	fetchActivityList(callback) {
		this.api_adapter.http_calling_get("api/tenant/ref_program_type/list", (result) => callback(result));
	}

	add_ref_program_type(data, callback) {
		const api = 'api/tenant/ref_program_type/add_ref_program_type';
		this.api_adapter.http_calling_file_post(api, data, callback);
	}

	edit_ref_program_type(data, callback) {
		this.api_adapter.http_calling_json_put('api/tenant/ref_program_type/edit_ref_program_type', data, (result) => {
			callback(result);
		})
	}

	ref_program_type_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get('api/tenant/ref_program_type/get_referral_program_type_list?' + query_data, (result) => {
			callback(result);
		});
	}

	fetch_referral_program_type_data(data, callback) {
		this.api_adapter.http_calling_get('api/tenant/ref_program_type/get_ref_program_type_id/' + data.program_type_id, callback);
	}

}
