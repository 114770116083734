import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit,OnDestroy {
    tenantInfo:any = {};

    @Output() processSuccess : EventEmitter<any> = new EventEmitter();
    constructor(private userService:UserService) {

    }

    ngOnInit() {
        let tenantSubscription = this.userService.castTenant.subscribe((tenant)=>{
            this.tenantInfo = tenant;
        })
        this.userService.addModuleSubscription('WelcomeComponent',tenantSubscription);
    }

    ngOnDestroy(){
        this.userService.destroyModuleSubscriptions('WelcomeComponent');
    }
}
