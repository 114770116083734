import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { IMyDrpOptions } from 'mydaterangepicker';
import { DashBoardService } from './dash-board.service';
import { FlashMessageService } from '../libraries/flash-message/services/flash-message.service';
import { DatePipe } from '@angular/common';
import { CustomRoutes } from '../custom-routes';
declare let $: any;

/***
* Description : Dashboard Component.
* Author : Fahad.
***/
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {
	loader_status: boolean = false;
	currentDate: Date = new Date();
	startDate: Date = new Date();

	/* For Dashboard Count */
	total_customers: number = 0;
	total_active_customers: number = 0;
	total_loggedin_customers: number = 0;
	active_percentage_customers: number = 0;
	accrual_point: number = 0;
	redeemed_point: number = 0;
	spend_point: number = 0;
	campaign_point: number = 0;
	total_points_cost: number = 0;
	expired_point: number = 0;
	liability_point: number = 0;
	reward_count: number = 0;
	offer_count: number = 0;
	total_parent: number = 0;
	total_staff: number = 0;

	ttl_num_of_pts: number = 0;
	ttl_num_of_normal_pts: number = 0;
	ttl_num_of_promotion_pts: number = 0;
	ttl_pts_will_be_expired: number = 0;
	ttl_expired_pts: number = 0;
	ttl_used_pts: number = 0;
	tierNames: any[] = [];
	customerTierNameCount: any[] = [];
	avg_transaction_value: number = 0;
	avg_spend_member: number = 0;
	total_spend: number = 0;
	total_good_will_points : number = 0;
	total_reversal_points: number = 0;

	public timeSpan: any[];
	selectedTime: any = 'LTD';
	defaultTime: any = 1;

	graphDateRangeOptions: IMyDrpOptions = {
		dateFormat: 'yyyy-mm-dd',
		sunHighlight: true,
		showClearBtn: false,
		editableDateRangeField: false,
		openSelectorOnInputClick: true,
		showClearDateRangeBtn: false,
		disableSince: { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() + 1 }
	};

	totalCountDateRangeOptions: IMyDrpOptions = {
		dateFormat: 'yyyy-mm-dd',
		sunHighlight: true,
		showClearBtn: false,
		editableDateRangeField: false,
		openSelectorOnInputClick: true,
		showClearDateRangeBtn: false,
		disableSince: { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() + 1 }
	};
	totalCountDateModel: any = 0;
	graphDateModel: any = 0;
	pickerDate: any = '';
	math = Math;
	public accrualRedemptionChartData: Array<any> = [
		{ data: [], label: 'Accrual Activity' },
		{ data: [], label: 'Redeemed Activity' }
	];

	lineChartLabels: Array<any> = [];
	public lineChartLegend: boolean = true;
	public lineChartType: string = 'line';
	public lineChartColors: Array<any> = [
		{
			backgroundColor: 'rgba(148,159,177,0.2)',
			borderColor: '#8c59a4',
			pointBackgroundColor: 'rgba(148,159,177,1)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(148,159,177,0.8)',
		},
		{
			backgroundColor: 'rgba(77,83,96,0.2)',
			borderColor: '#2fa732',
			pointBackgroundColor: 'rgba(77,83,96,1)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(77,83,96,1)'
		}
	];

	public lineChartOptions: any = {
		responsive: true
	};

	constructor(private cookieService: CookieService, private router: Router, private dashboardservice: DashBoardService, private user: UserService, private flashMessageService: FlashMessageService, private datePipe: DatePipe,
		private userService:UserService) {

		this.timeSpan = [
			{ 'id': 1, 'itemName': 'LTD' },
			{ 'id': 2, 'itemName': 'YTD' },
			{ 'id': 3, 'itemName': 'MTD' },
			{ 'id': 4, 'itemName': 'Previous Day' },
		];
	}

	async ngOnInit() {
		let stopProcess = 0;

		await new Promise((resolve,reject)=>{
			console.log(this.userService.getFromLocalStorage('log_in_role'));
			if(this.userService.getFromLocalStorage('log_in_role') == 42){
				CustomRoutes.navigate([CustomRoutes.routes.customer.customer_list]);
				stopProcess =1;
			} else if(this.userService.getFromLocalStorage('log_in_role') == 43){
				CustomRoutes.navigate([CustomRoutes.routes.dashboard.dashboard_program_calendar]);
			}
			resolve();
		});
		if(stopProcess==1){
			return;
		}
		console.log(('dashboard'))
		this.loader_status = true;
		this.ttl_num_of_pts = 0;
		this.ttl_num_of_normal_pts = 0;
		this.ttl_num_of_promotion_pts = 0;
		this.ttl_pts_will_be_expired = 0;
		this.ttl_expired_pts = 0;
		this.ttl_used_pts = 0;

		this.accrual_point = 0;
		this.redeemed_point = 0;
		this.spend_point = 0;
		this.campaign_point = 0;
		this.total_points_cost = 0;
		this.reward_count= 0;
		this.offer_count= 0;
		this.total_customers = 0;
		this.total_active_customers = 0;
		this.total_loggedin_customers = 0;
		this.total_spend = 0;
		this.total_good_will_points = 0;
		this.total_reversal_points = 0;
		this.active_percentage_customers = 0;
		this.tierNames = [];
		this.customerTierNameCount = [];
		this.startDate.setMonth(this.currentDate.getMonth() - 4);

		this.totalCountDateModel = {
			beginDate: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
			endDate: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'),
		};
		this.graphDateModel = {
			beginDate: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
			endDate: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd'),
		};

		this.load_count({ start_date: this.startDate, end_date: this.currentDate });
		this.load_graph({ start_date: this.startDate, end_date: this.currentDate });
		this.load_points(this.currentDate);
		// (<any>window).init_chart_doughnut();
		// (<any>window).init_gauge();
		// (<any>window).init_flot_chart();
	}

    /***
	* Description : Handle Event.
    * Params : event.
    * Author : Fahad.
    ***/
	onCountDateRangeChange($event) {
		this.load_count({ start_date: $event.beginJsDate, end_date: $event.endJsDate });
	}

    /***
	* Description : Handle Event.
    * Params : event.
    * Author : Fahad.
    ***/
	onChartDateRangeChange($event) {
		this.load_graph({ start_date: $event.beginJsDate, end_date: $event.endJsDate });
	}

    /***
	* Description : Loads Count.
    * Params : dateData.
    * Author : Fahad.
    ***/
	load_count(dateData: any) {
		this.loader_status = true;
		const queryDate = this.transformDate(dateData);
		queryDate['timeSpan'] = this.selectedTime;
		console.log(queryDate)
		this.dashboardservice.get_dashboard_count(queryDate, (result) => {
			console.log("result", result)
			if (result['status']) {
				this.loader_status = false;
				/* Point Count */
				this.accrual_point = Math.round(result['values']['pointCount'][0].total_accrual_point);
				this.redeemed_point = Math.round(result['values'].total_redeemed_point);
				this.spend_point = Math.round(result['values']['pointCount'][0].total_spend_point);
				this.campaign_point = Math.round(result['values']['pointCount'][0].total_campaign_point);
				this.total_points_cost = Math.round(this.accrual_point * 0.02);
				// this.expired_point = Math.round(result['values']['pointCount'][0].total_expired_point);
				// this.liability_point = Math.round(result['values']['pointCount'][0].total_liability_point);
				// this.reward_count = Math.round(result['values']['offerRewardCount'][0].reward_count);
				// this.offer_count = Math.round(result['values']['offerRewardCount'][0].offer_count);
				/* Member Count */
				this.total_customers = result['values']['memberCount'][0].total_customers;
				this.total_active_customers = result['values']['memberCount'][0].total_active_customers;
				this.total_loggedin_customers = result['values']['memberCount'][0].total_loggedin_customers;
				this.active_percentage_customers = Math.round((this.total_active_customers / this.total_loggedin_customers) * 100);
				this.avg_transaction_value = result['values'].avg_transaction_value;
				this.avg_spend_member = result['values'].avg_spend_member;
				this.total_spend = result['values'].total_spend;
				this.total_good_will_points = result['values'].total_good_will_points;
				this.total_reversal_points = result['values'].total_reversal_points;

			}
		});
	}
	getTierCount(tier_id){
		let count = 0
		this.customerTierNameCount.forEach((item)=>{
			if((item['id'])==Number(tier_id)){
				count = item['count'];
			}
		})
		return count;
	}
    /***
	* Description : Loads Graph.
    * Params : dateData.
    * Author : Fahad.
    ***/
	load_graph(dateData: any) {
		this.loader_status = true;
		const queryDate = this.transformDate(dateData);
		this.dashboardservice.get_dashboard_graph(queryDate, (data) => {
			this.accrualRedemptionChartData = [];
			this.lineChartLabels = [];
			const accrualData = [];
			const redemptionData = [];

			if (data['status']) {
				for (const i in data['values']) {
					accrualData.push(Math.round(data['values'][i]['total_accrued_points']));
					redemptionData.push(Math.round(data['values'][i]['total_redeemed_points']));
					this.lineChartLabels.push(data['values'][i]['date'].split('T')[0]);
				}
				this.accrualRedemptionChartData.push({ data: accrualData, label: 'Accrual Activity' });
				this.accrualRedemptionChartData.push({ data: redemptionData, label: 'Redeemed Activity' });
			} else {
				this.flashMessageService.showMessage('Dashboard Graph Information Not Found!', 'alert alert-danger');
			}
			this.loader_status = false;
		});
	}

    /***
	* Description : Transform Date Object to formaated Date.
    * Params : dateObj.
    * Author : Fahad.
    ***/
	transformDate(dateObj: any) {
		return {
			start_date: this.datePipe.transform(dateObj.start_date, 'yyyy-MM-dd'),
			end_date: this.datePipe.transform(dateObj.end_date, 'yyyy-MM-dd')
		};
	}

	load_points(param) {
		this.loader_status = true;
		this.dashboardservice.overall_points({ date: param }, (result) => {
			if (result['status']) {
				this.loader_status = false;
				if (result['values'].length > 0) {
					this.ttl_num_of_pts = result['values'][0]['ttl_num_of_pts'];
					this.ttl_num_of_normal_pts = result['values'][0]['ttl_num_of_normal_pts'];
					this.ttl_num_of_promotion_pts = result['values'][0]['ttl_num_of_promotion_pts'];
					this.ttl_pts_will_be_expired = result['values'][0]['ttl_pts_will_be_expired'];
					this.ttl_expired_pts = result['values'][0]['ttl_expired_pts'];
					this.ttl_used_pts = result['values'][0]['ttl_used_pts'];
				}
			}
		});
	}

	setTimeSpan(value) {
		this.selectedTime = this.timeSpan[value - 1]['itemName'];
		this.load_count({ start_date: this.startDate, end_date: this.currentDate });
	}
}
