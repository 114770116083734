import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { ApiService } from '../services/ApiService/api-service.service';
import { UserService } from '../services/user.service';

@Injectable()
export class ActivityService {

  constructor(private customCallingApi: Custom_api_calling, private apiService: ApiService, private userService: UserService) { }

  add_activity(data, callback) {
    const api = 'api/tenant/rule_activity/addRuleActivity';
    this.customCallingApi.http_calling_file_post(api, data, callback);

  }

  edit_activity(data, callback) {
    const api = 'api/tenant/rule_activity/editRuleActivity';
    this.customCallingApi.http_calling_file_put(api, data, callback);
  }

  getRuleActivityById(data, callback) {
    // let query_data =  this.apiService.get_query_data(data);   
    console.log(data.rule_activity_id);
    this.customCallingApi.http_calling_get('api/tenant/rule_activity/getRuleActivityById/' + data.rule_activity_id, callback);
  }

  getActivityList(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/rule_activity/getAllRuleActivity?' + query_data, callback);
  }

  getLanguages(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/rule_activity/getLanguages?' + query_data, callback);
  }

}
