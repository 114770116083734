import { Component, OnInit } from '@angular/core';
import { RewardEngineService } from '../reward_engine/reward-engine.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from "../constants/CustomValidators";
import { FlashMessageService } from "../libraries/flash-message/services/flash-message.service";
import { CustomRoutes } from '../custom-routes';
import { UserService } from '../services/user.service';
import { Constants } from '../constants/constants';
import { HttpClient } from '@angular/common/http';
import { LobService } from '../masters/lob/lob.service';

@Component({
  selector: 'app-round-off-mechanism',
  templateUrl: './round-off-mechanism.component.html',
  styleUrls: ['./round-off-mechanism.component.css']
})
export class RoundOffMechanismComponent implements OnInit {

  routes = CustomRoutes.routes;
  dropdown_round_off_type = [
    { id: 1, itemName: "Upper Value", codeName: "upper" },
    { id: 2, itemName: "Lower Value", codeName: "lower" },
    { id: 3, itemName: "Mid Value", codeName: "mid" },
  ];
  round_off_form: FormGroup;
  lob_id;
  transaction_type;
  engine_type;
  round_off_rule;
  round_off_rule_id;
  lob;
  mode='Update';

  constructor(
    private rewardEngineService: RewardEngineService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private flashMessageService: FlashMessageService,
    private fb: FormBuilder,
    private http: HttpClient,    private lobService: LobService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.lob_id = params.lob_id;
      this.transaction_type = params.transaction_type;
      this.engine_type = params.engine_type;
    });
  }


  ngOnInit() {
    this.round_off_form = this.fb.group({
      multiple_of: [null, Validators.compose([Validators.required, CustomValidators.onlyNumber, Validators.minLength(1), Validators.maxLength(4)])],
      round_off_type: [null, Validators.compose([Validators.required])]
    });
    this.getData();
    this.lobService.getLob(this.lob_id, (result) => {
      this.lob = result.values[0];
    });
  }

  getData() {
    
    let body = { lob_id: this.lob_id, transaction_type: this.transaction_type, engine_type: this.engine_type };

    this.rewardEngineService.getRoundOffRule(body, async result => {
      if (result["status"] == true) {
        this.api_result(result)
        this.round_off_rule = await result.values[0];
        this.round_off_rule_id = this.round_off_rule.id
        this.round_off_form.patchValue({
          multiple_of: this.round_off_rule["multiple_of"],
          round_off_type: this.dropdown_round_off_type.filter(
            data => data.codeName == this.round_off_rule["round_off_type"]
          )
        })
      } else{
        this.mode = 'Add';
        this.api_result(result);
      }
    });
  }

  submit() {
    if (this.userService.isFormValid(this.round_off_form)) {
      document.getElementById("buttonModal").click();
    }
  }

  add_update_data() {
    let body = {};
    body['multiple_of'] = this.round_off_form.value.multiple_of;
    body['round_off_type'] = this.round_off_form.value.round_off_type[0].codeName;
    if(this.mode == 'Update'){
      this.rewardEngineService.updateRoundOffRule(
        this.round_off_rule_id, body,
        op => this.update_rules_engine(op)
      )
    } else {
      body['lob_id'] = this.lob_id;
      body['transaction_type'] = this.transaction_type;
      body['engine_type'] = this.engine_type;
      this.rewardEngineService.addRoundOffRule(body,
        op => this.update_rules_engine(op)
      );
    }
  }

  update_rules_engine(result) {
    if (result.status && Constants.RULES_ENGINE_URL) {

      this.rewardEngineService.update_rules_engine(
        `updateRoundOff?trans_type=${this.transaction_type}&engine_type=${this.engine_type}&lob=${JSON.stringify(this.lob)}`, REResult => {
          if (REResult.status)
            this.api_result(result, true);
          else this.api_result(REResult);
        });

    } else this.api_result(result, true);
  }

  api_result(result, ruleUpdate = false) {

    if (result["status"] == true) {

      this.flashMessageService.showMessage(result.message, 'alert alert-success');

      if (ruleUpdate) {
        if (this.engine_type == 'rule') {
          this.transaction_type == 'accrual' && this.router.navigate(["reward_engine/reward_engine_list", this.lob_id]);
          this.transaction_type == 'redemption' && this.router.navigate(["burn_engine/burn_engine_list", this.lob_id]);
          this.transaction_type == 'cash_miles' && this.router.navigate(["cash_miles_rules/cash_miles_rules_list", this.lob_id]);
          this.transaction_type == 'miles_for_cash' && this.router.navigate(["miles_for_cash/list", this.lob_id]);
        }
        if (this.engine_type == 'coupon') {
          this.transaction_type == 'accrual' && this.router.navigate(["coupon_engine/coupon_engine_list", this.lob_id]);
          this.transaction_type == 'redemption' && this.router.navigate(["coupon_burn_engine/coupon_burn_engine_list", this.lob_id]);
        }
      }
      // this.router.navigate(["reward_engine/reward_engine_list",this.lob_id]);
    } else {
      this.flashMessageService.showMessage(
        result["message"],
        "alert alert-danger"
      );
    }


  }

  getFieldValue(param, is_multiselect = false) {
    let return_value = '';

    if (is_multiselect) {
      if (this.round_off_form.controls[param].value && this.round_off_form.controls[param].value.length > 0) {
        let elemnts = this.round_off_form.controls[param].value.map((item) => {
          return item.codeName;
        })
        return_value = elemnts.join(", ");
      }
    } else {
      return_value = this.round_off_form.controls[param].value;
    }
    return return_value;
  }

}
