import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class TagService {
    tagsApi: any;
    tagListApi: any;
    data: any;
    tags = new BehaviorSubject([]);
    castTags = this.tags.asObservable();
    tag = new BehaviorSubject({});
    castTag = this.tag.asObservable();

  constructor(private customCallingApi: Custom_api_calling) {}

  loadTag(data, callback) {
    let query_data = this.get_query_data(data);
    this.tagListApi  = 'api/tenant/tags/get_tags_list?' + query_data;
    this.customCallingApi.http_calling_get(this.tagListApi, (result) => {
        callback(result);
    });
  }
  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        if(data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]]!= undefined)
        query_data += (query_data === ""  ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
 }

  loadNoteTags(searchParam, callback) {
    this.tagListApi  = 'api/tenant/tags/get_noteTags_list?search=' + searchParam;
    this.customCallingApi.http_calling_get(this.tagListApi, (data) => {
        if (data['status'] === true) {
            callback(data['values']);
        }
    });
  }

  fetchTag(tag_id, callback) {
    this.tagsApi = "api/tenant/tags/get_tag/" + tag_id;
    this.customCallingApi.http_calling_get(this.tagsApi, (data) => {
      if (data['status'] === true) {
        callback(data['values'][0]);
      }
    });
  }

  fetchActivityList(data, callback) {
	let activityApi = 'api/tenant/tags/get_tag_activities_list';
	this.customCallingApi.http_calling_get(activityApi, (result) => {
		callback(result);
	})
}

getSelectedActivityList(data, callback){
	this.customCallingApi.http_calling_get('api/tenant/tags/get_selected_activity_list/' + data.tagId, callback);
  }

}
