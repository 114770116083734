import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { Common_methods } from '../libraries/Common_methods';

@Injectable()
export class DashBoardService {

    constructor(private customCallingApi: Custom_api_calling) {
    }

    get_dashboard_graph(data: any, callback) {
        this.customCallingApi.http_calling_get('api/tenant/user/getDashboardGraph?' + Common_methods.get_query_data(data), (result) => {
            callback(result);
        });
    } liability_point

    get_dashboard_count(data: any, callback) {
        this.customCallingApi.http_calling_get('api/tenant/user/getDashboardCount?' + Common_methods.get_query_data(data), (response) => {
            callback(response);
        });
    }

    overall_points(data: any, callback) {
        this.customCallingApi.http_calling_get('api/tenant/user/overallPoints?' + Common_methods.get_query_data(data), (response) => {
            callback(response);
        });
    }

    get_dashboard_count_calendar(data: any, callback) {
        this.customCallingApi.http_calling_get('api/tenant/user/getDashboardCountByDate?' + Common_methods.get_query_data(data), (response) => {
            callback(response);
        });
    }
}



