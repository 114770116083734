import { Component, OnInit, EventEmitter, Output, OnDestroy, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WalletService } from '../wallet.service';
import { FormComponentStruct } from '../../libraries/form-component-struct';
import { FlashMessageService } from '../../libraries/flash-message/services/flash-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PointTypeMasterService } from '../../point_type_master/point-type-master.service';
import { UserService } from '../../services/user.service';
import { ViewChild } from '@angular/core';
import { CustomValidators } from '../../constants/CustomValidators';
import { MatDialog } from '@angular/material';
import { WalletPointTypeComponent } from '../wallet-point-type/wallet-point-type.component';
import { CustomRoutes } from '../../custom-routes';

declare let $: any;

@Component({
  selector: 'app-tenant-wallet-form',
  templateUrl: './tenant-wallet-form.component.html',
  styleUrls: ['./tenant-wallet-form.component.css']
})

export class TenantWalletFormComponent extends FormComponentStruct implements OnInit,OnDestroy {
    walletForm : FormGroup;
    walletImage :any = {};
    walletId:number = 0;
    dropdown_setting = {};
    wallet_Point_type_list:any = [];
    priorityArr:any = [];
    priorityArrWithId:any = [];
    @Output() processSuccess : EventEmitter<any> = new EventEmitter();
    loader_status:boolean = true;
    @ViewChild('myFile', {static: true})
    myFileVaraible: ElementRef;
    constructor(private dialog: MatDialog ,private router: Router, private fb:FormBuilder,private walletService : WalletService,private flashMessageService:FlashMessageService , private activatedRoute: ActivatedRoute, private pointTypeService:PointTypeMasterService, private userService:UserService) {
        super({
            add:"addWallet",
            edit:"editWallet",
        })        
     }

    ngOnInit() {
        if(this.formType == 'add'){
            this.loader_status = false;
        }
        
        this.walletForm = this.fb.group({ 
            name:[null,Validators.compose([Validators.required, CustomValidators.aplhaNumeric])],
            description:[null,Validators.required],
            image_validation : [null],
        })
        if(this.formType == 'add')
            this.walletForm.controls.image_validation.setValidators(Validators.required);

        let routeSubscription = this.activatedRoute.params.subscribe((params) => {
           this.walletId = params.id;
          })

        if(this.formType == 'edit'){
            this.walletService.getWallet({wallet_id:this.walletId},(result) => {
                this.loader_status = false;
                if(result.status){
                    let walletData = result.values[0];
                    this.wallet_Point_type_list = result.values;
                    this.walletForm.patchValue({
                        name : walletData.name,
                        description : walletData.description,
                    });
                    this.walletForm.controls['image_validation'].clearValidators();

                    for(var i = 0; i < this.wallet_Point_type_list.length; i++) {
                        let obj ={
                            'point_type_id':this.wallet_Point_type_list[i]['point_type_id'],
                            'point_priority':this.wallet_Point_type_list[i]['point_priority'],
                            'input_id':i,
                        }
                        this.priorityArr.push(this.wallet_Point_type_list[i]['point_priority']);
                        this.priorityArrWithId.push(obj);
                     }

                }else{
                    this.wallet_Point_type_list = [];
                }
            });
        }
         
        this.userService.addModuleSubscription('TenantWalletFormComponent',routeSubscription);

    }

    addWallet(value){
    if(this.userService.isFormValid(this.walletForm))
    {
        let data = {
            body : new FormData(),
        };
        for(let index in value){
            data.body.append(index,value[index]);
        }
        data.body.append('image',this.walletImage);

        this.walletService.addWallet(data, (result) => this.postFormProcess(result))
    }
    }

    editWallet(value){
    if(this.userService.isFormValid(this.walletForm))
    {
        let data = {
            wallet_id : this.walletId,
            body : new FormData(),
        };
        for(let index in value){
            data.body.append(index,value[index]);
        }
        if(this.walletImage.name)
            data.body.append('image',this.walletImage);
        this.walletService.editWallet(data, (result) => this.postFormProcess(result))
    }
    }

    postFormProcess(result){
        if(result.status){
            this.processSuccess.emit();
            this.flashMessageService.showMessage(result.message,'alert alert-success');
            CustomRoutes.navigate([CustomRoutes.routes.wallet.manage_wallet]);
        }else{
            this.flashMessageService.showMessage(result.message,'alert alert-danger');
        }
    }

    submitPriority(){
        this.priorityArrWithId;
        this.loader_status = true;
        
        let body ={}
        body['wallet_id'] = this.walletId;
        body['priority_data'] = this.priorityArrWithId;
        this.walletService.updatePointPriorityOnWallet(body, (result) => {
            this.loader_status = false;
            if(result.status){                
                this.flashMessageService.showMessage(result.message,'alert alert-success');
            }else{
                this.flashMessageService.showMessage(result.message,'alert alert-danger');
            }
        });
    }

    onPriorityChange(event1,point_id,input){
        let event_val = parseInt(event1);
        let prev_id:number;
        let set_prev_val = '';
        let _arr_index:number;
        let element:HTMLInputElement = document.getElementById('priority_input'+input) as HTMLInputElement;

        if(event_val <= this.priorityArrWithId.length){
            element.style.borderColor = "#CCC";
            for(var i = 0; i < this.priorityArrWithId.length; i++) {  
                let elementEnable:HTMLInputElement = document.getElementById('priority_input'+i) as HTMLInputElement;
                elementEnable.disabled = false; 
                if(this.priorityArrWithId[i]['point_priority'] == event_val){
                    prev_id = this.priorityArrWithId[i]['input_id'];
                    _arr_index = i;
                }
                if(this.priorityArrWithId[i]['input_id'] == input){
                    set_prev_val =this.priorityArrWithId[i]['point_priority'];
                    this.priorityArrWithId[i]['point_priority'] = event_val;
                    $("#invalid-priority"+input).remove();
                }
            }
            this.priorityArrWithId[_arr_index]['point_priority'] = set_prev_val;
            let element1:HTMLInputElement = document.getElementById('priority_input'+prev_id) as HTMLInputElement;
            element1.value = set_prev_val;    
        }else{
            element.style.borderColor = "red";           
                let x = document.createElement("label");
                let t = document.createTextNode("Invalid priority set.");
                x.setAttribute("for", "error");
                x.setAttribute("class", "invalid-priority");
                x.setAttribute("id", "invalid-priority"+input);
                x.setAttribute("style", "margin: 0;float: right;color:#fff;padding: 4px 10px 0px;text-align: center;position: relative;background-color: rgba(231, 76, 60, 0.88);");
                x.appendChild(t);
                document.getElementById("tbl-th"+input).insertBefore(x,document.getElementById("priority_input"+input));
             
                setTimeout(()=>{
                    $('#invalid-priority'+input).remove();
                },3000);
                for(var i = 0; i < this.priorityArrWithId.length; i++) {
                    let element1:HTMLInputElement = document.getElementById('priority_input'+i) as HTMLInputElement;                    
                    if(i !== input){
                        element1.disabled = true;
                    }
                }
        }
    }

    onFileChange(files) {
        let file = files[0];
        this.walletForm.controls['image_validation'].setValue(file ? file.name : '');
        this.walletImage = file;
    }
    resetForm(){
        this.walletForm.reset();
        this.myFileVaraible.nativeElement.value = "";
        CustomRoutes.navigate([CustomRoutes.routes.wallet.manage_wallet]);
    }
    ngOnDestroy(){
        this.userService.destroyModuleSubscriptions('TenantWalletFormComponent');
    }

    openWalletPoint()
    {
        this.dialog.open(WalletPointTypeComponent, {
            width: '800px',
            data: this.walletId
          })
    }
}
