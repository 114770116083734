import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class LanguageService {

  constructor(private api_adapter: Custom_api_calling) {
  }

  get_all_language(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/language/get_language_list?" + query_data, (result) => callback(result));
   
  }
  get_all_languages(callback) {
    this.api_adapter.http_calling_get("api/tenant/language/get_languages_list", (result) => callback(result));
   
  }
  create_language(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/language/add_language", data.body, (result) => callback(result));
  }

  modify_language(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/language/edit_language", data.body, (result) => callback(result));
  }
  get_language(data, callback) {
    this.api_adapter.http_calling_get("api/tenant/language/get_language_by_id/" + data, (result) => callback(result));
  }
  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }
}
