export class PointTypeLanguage{
    
    id: number;
    point_type_id: number;
    language_name: string;
    language_code: string;
    point_type_name: string;
    point_type_lable: string;
    

    constructor(info){
        this.id = info.id;
        this.point_type_id = info.point_type_id;
        this.language_name = info.language_name;
        this.language_code = info.language_code;
        this.point_type_name = info.point_type_name;
        this.point_type_lable = info.point_type_lable;
    
    }
}