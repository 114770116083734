import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';
import { Common_methods } from '../../libraries/Common_methods';

@Injectable()
export class AttributesService {

  constructor(private api_adapter: Custom_api_calling) { }

  addAttribute(body, callback) {
    this.api_adapter.http_calling_post("api/tenant/attribute/add_attribute", body, (result: any[]) => { callback(result) })
  }

  getOperators(callback) {
    this.api_adapter.http_calling_get("api/tenant/attribute/get_operator", (result) => callback(result))
  }

  getAttributeFormatList(callback) {
    this.api_adapter.http_calling_get("api/tenant/attribute/get_attribute_format", (result) => callback(result))
  }

  getAttributeSourceList(callback) {
    this.api_adapter.http_calling_get("api/tenant/attribute/get_attribute_source", (result) => callback(result))
  }

  getAttributeList(data, callback) {
    let query_data = Common_methods.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/attribute/get_attribute?" + query_data, (result) => callback(result))
  }

  update_attribute_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/attribute/update_status", data.statusBody, (result) => callback(result));
  }

  getAttribute(attributeId, callback) {
    let attribute_id = parseInt(attributeId);
    this.api_adapter.http_calling_get(`api/tenant/attribute/get_attribute/${attribute_id}`, (result) => callback(result))
  }

  getAttributeOperators(attributeId, callback) {
    let attribute_id = parseInt(attributeId);
    this.api_adapter.http_calling_get(`api/tenant/attribute/get_attribute_operators/${attribute_id}`, (result) => callback(result))
  }

  getAttributeFormatOperators(id, callback) {
    this.api_adapter.http_calling_get(`api/tenant/attribute/get_attribute_format_operators/${id}`, (result) => callback(result))
  }

  getAttributeValues(attributeId,data, callback) {
    let attribute_id = parseInt(attributeId);
    let query_data = Common_methods.get_query_data(data);
    this.api_adapter.http_calling_get(`api/tenant/attribute/get_attribute_values/${attribute_id}?` + query_data, (result) => callback(result))
  }

  updateAttribute(attributeId, body, callback) {
    let attribute_id = parseInt(attributeId);
    this.api_adapter.http_calling_put(`api/tenant/attribute/update_attribute/${attribute_id}`, body, (result: any[]) => callback(result))
  }

  uploadFilecheck(data, callback) {
    const api = 'api/tenant/attribute/upload_attribute_file';
    this.api_adapter.http_calling_file_post(api, data.body, callback);
  }

  getAttributeValuesList(data, callback) {
    const query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/attribute/attribute_details?' + query_data, (result) => callback(result));
  }

  update_attribute_value_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/attribute/update_attribute_value_status", data.body, (result) => callback(result));
  }

  get_query_data(data) {

    let query_data = '';
    let keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== '' && data[keys[i]] != null && data[keys[i]] != undefined) {
        query_data += (query_data === '' ? keys[i] + '=' + data[keys[i]] : '&' + keys[i] + '=' + data[keys[i]]);
      }
    }
    return query_data;
  }

}
