import { Component, OnInit,ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { Custom_api_calling} from "../../adapters/Custom_api_calling";
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../configuration.service';
@Component({
  selector: 'app-point-configuration',
  templateUrl: './point-configuration.component.html',
  styleUrls: ['./point-configuration.component.css']
})
export class PointConfigurationComponent implements OnInit {
    pointConfigForm:FormGroup;
    pointConfigForm_error:string = '';

    selling_point_rate:any;
    base_point_rate:any;
    aging_mechanism:any;
    roundOffLimit = Array(10).fill(1).map((x,i)=>i/10);
    roundOffTypes = ['markup','markdown'];

    @Output() processSuccess : EventEmitter<any> = new EventEmitter();

    constructor(private fb: FormBuilder,private api_adapter: Custom_api_calling,
                private userService:UserService,private configurationService:ConfigurationService) {
        this.pointConfigForm = fb.group({
            'base_point_rate':[null,  Validators.required],
            'selling_point_rate':[null,  Validators.required],
            'aging_mechanism':[null,  Validators.required],
            'round_off_threshold' : [ null, Validators.required],
            'round_off_type' : [ null, Validators.required],
        });
    }

    ngOnInit() {

        this.configurationService.loadPointsConfiguration();
        let pointsConfigSubsription = this.configurationService.castPointConfig.subscribe((data)=>{
            this.pointConfigForm.controls['selling_point_rate'].setValue(data['selling_point_rate']);
            this.pointConfigForm.controls['base_point_rate'].setValue(data['base_point_rate']);
            this.pointConfigForm.controls['aging_mechanism'].setValue(data['aging_mechanism']);
            this.pointConfigForm.controls['round_off_threshold'].setValue(data['round_off_threshold']);
            this.pointConfigForm.controls['round_off_type'].setValue(data['round_off_type']);

        });
        this.userService.addModuleSubscription('PointConfigurationComponent',pointsConfigSubsription);
    }

  // point Configuration form
  pointConfiguration() {
    let data = this.pointConfigForm.value;
    this.api_adapter.http_calling_json_put("api/tenant/user/editProfile", data,
        (result) =>
        {
          if(result['status']== true){
            this.processSuccess.emit();
          }else{
            this.pointConfigForm_error =this.userService.displayErrors({
              errors:result.errors,
              message:result.message
            });
          }
        }
    );
  }
  //point Configuration  form end
}
