import { Injectable } from '@angular/core';
import { Custom_api_calling } from "../../adapters/Custom_api_calling";
import { Constants } from "../../constants/constants";
import { query } from '@angular/animations';

@Injectable()
export class DeveloperService {

    constructor(private api_adapter: Custom_api_calling) {
    }


    get_user_types(data, callback) {
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/user_types/list?" + query_data, (result) => callback(result))
    }

    create_group(data, callback) {
        this.api_adapter.http_calling_file_post("api/tenant/developer_panel/api_group", data.body, (result) => callback(result))
    }

    modify_group(data, callback) {
        this.api_adapter.http_calling_file_put("api/tenant/developer_panel/api_group", data.body, (result) => callback(result))
    }

    update_status(data, callback) {
        this.api_adapter.http_calling_file_put("api/tenant/developer_panel/api_group/status", data.body, (result) => callback(result))
    }

    get_group_list(data, callback) {
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/developer_panel/api_group/list?" + query_data, (result) => callback(result))
    }

    get_assigne_api_group_list(data, callback) {
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/developer_panel/assign_api_group/get_group_list?" + query_data, (result) => callback(result))
    }

    get_assign_api_group_users(data, callback) {
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/developer_panel/assign_api_group/get_users?" + query_data, (result) => callback(result))
    }

    get_group_codes(data, callback) {
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/user/getGroupCodes?" + query_data, (result) => callback(result))
    }

    assignRoutesToGroup(data, callback) {
        this.api_adapter.http_calling_file_post("api/tenant/developer_panel/api_groups/assign_routes", data.body, (result) => callback(result));
    }
    getAssignedRoutes(data,callback){
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/developer_panel/api_groups/assign_routes/list?" + query_data, (result) => callback(result))
    }

    assignAPIToGroup(data, callback) {
        this.api_adapter.http_calling_file_post("api/tenant/developer_panel/api_groups/assign_apis", data.body, (result) => callback(result));
    }
    getAssignedAPIS(data,callback){
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/developer_panel/api_groups/assign_apis/list?" + query_data, (result) => callback(result))
    }

    remove_route(data,callback){
        this.api_adapter.http_calling_file_put("api/tenant/developer_panel/api_groups/assign_routes/remove",data.body,(result)=>callback(result));
    }
    remvoe_api(data,callback){
        this.api_adapter.http_calling_file_put("api/tenant/developer_panel/api_groups/assign_apis/remove",data.body,(result)=>callback(result));
    }
    get_apis(data,callback){
        let query_data = this.get_query_data(data);
        this.api_adapter.http_calling_get("api/tenant/developer_panel/api/list?"+query_data,(result)=>callback(result));
    }
    /*---------------------------------------*/
    /*----- Activity Master API's Start -----*/
    /*---------------------------------------*/

    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }


    /*---------------------------------------*/
    /*------ Activity Master API's End ------*/
    /*---------------------------------------*/




}

