import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { FlashMessageService } from '../../libraries/flash-message/services/flash-message.service';
import { ApiService } from '../../services/ApiService/api-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from '../../constants/constants';
import { CustomValidators } from '../../constants/CustomValidators';
import { ConfigurationService } from '../configuration.service';
import { CustomRoutes } from '../../custom-routes';

@Component({
  selector: 'app-manage-sub-categories-form',
  templateUrl: './manage-sub-categories-form.component.html',
  styleUrls: ['./manage-sub-categories-form.component.css']
})
export class ManageSubCategoriesFormComponent implements OnInit {
  manageSubCategoryForm: FormGroup;
  manageSubCategoryForm_error: string = '';
  manageSubCategoryFormErrorClass: string = '';
  sub_category_id: number;
  dropdown_status: any;
  dropdown_setting: any;
  loader_status: boolean = true;
  category_id: number;
  routes = CustomRoutes.routes;

  sub_category_data: any;
  private_cats: any = [];
  @ViewChild('FileImage', {static: true}) FileImage;
  @Input() formType: any;

  constructor(private fb: FormBuilder, private userService: UserService, private flashMessageService: FlashMessageService,
    private apiService: ApiService, private router: Router, private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService) {
    this.manageSubCategoryForm = this.fb.group({
      'sub_category_label': [null, Validators.compose([Validators.required, CustomValidators.nameField])],
      'sub_category_image': [null],
      'private_category_id': [null],
      'status': [null, Validators.required],
    });

    this.dropdown_status = [
      { id: "1", itemName: "Active" },
      { id: "0", itemName: "Inactive" }
    ];
    this.dropdown_setting = {
      singleSelection: true
    };
  }

  ngOnInit() {
    this.dropdown_setting = {
      singleSelection: true
    };
    if (this.formType == 'edit') {
      let routeSubscription = this.activatedRoute.params.subscribe((params) => {
        this.sub_category_id = params.id;
      });
      this.userService.addModuleSubscription('ChannelCodeFormComponent', routeSubscription);
      this.get_sub_category_data();
    } else {
      this.manageSubCategoryForm.controls['private_category_id'].setValidators([Validators.required])
      this.get_private_categories();
    }
  }

  get_private_categories() {
    this.configurationService.get_all_categories({ is_private: 1 }, (result) => {
      this.loader_status = false;
      if (result.status) {
        this.private_cats = result['values']['category_list'].map((cat) => {
          return { id: cat.id, itemName: cat.name };
        });
      }
      else {
        this.flashMessageService.showMessage(result.message, 'alert alert-danger');
      }
    })

  }

  get_sub_category_data() {
    this.apiService.get_sub_category_id({ sub_category_id: this.sub_category_id }, (result) => {
      this.loader_status = false;
      if (result.status) {
        this.category_id = result.values[0].category_id
        this.sub_category_data = result.values[0];
        console.log("result.values[0]",result.values[0]);
        this.manageSubCategoryForm.patchValue({
          sub_category_label: this.sub_category_data.sub_category_label,
          status: Constants.setAngularMultiSelectData(this.dropdown_status, this.sub_category_data.status)
        });
        
        // status: Constants.setAngularMultiSelectData(this.dropdown_status, this.sub_category_data.status)

      }
      else {
        this.flashMessageService.showMessage(result.message, 'alert alert-danger');
      }
    })
  }



  onChange(evt: any) {
    if (Constants.imageChecker(evt.target.files[0].name)) {
      console.log("image is valid.");
    }
    else {
      this.manageSubCategoryForm.controls['sub_category_image'].patchValue(null);
    }

  }

  saveSubCategories() {
    if (this.userService.isFormValid(this.manageSubCategoryForm)) {
      this.loader_status = true;
      let image_path = this.FileImage.nativeElement;
      let body = this.manageSubCategoryForm.value;
      let sub_category_data = {};

      sub_category_data['name'] = body['sub_category_label'];
      sub_category_data['type'] = 'sub_category';
      sub_category_data['status'] = body['status'][0].id;

      if (this.formType == 'add') {
        this.category_id = body['private_category_id'][0].id;
        sub_category_data['cat_sub_cat_id'] = body['private_category_id'][0].id;
      }else{
        sub_category_data['cat_sub_cat_id'] = this.sub_category_id;
      }

      if (image_path.files && image_path.files[0]) {
        sub_category_data['image'] = image_path.files[0];
      }

      var form_data = new FormData();
      for (let key in sub_category_data) {
        form_data.append(key, sub_category_data[key]);
      }

      if (this.formType == 'edit') {
        this.apiService.save_categories(form_data,{}, (result) => {
          this.api_result(result);
        })
      } else {
        console.log("all clear...");
        this.loader_status = false;
        this.apiService.create_category_or_sub_category(form_data, (result) => {
          this.api_result(result);
        })
      }
    }
  }


  api_result(result) {
    this.loader_status = false;
    if (result.status) {
      this.flashMessageService.showMessage(result.message, 'alert alert-success');
      CustomRoutes.navigate([CustomRoutes.routes.tenant_base_configuration.manage_sub_categories , this.category_id]);
    } else {
      this.flashMessageService.showMessage(result.message, 'alert alert-danger');
    }
  }

}
