import { Component, OnInit,ViewChild, Input } from '@angular/core';
import { CcRulesUiComponent } from './cc-rules-ui/cc-rules-ui.component';

@Component({
  selector: 'app-cc-rules-module',
  templateUrl: './cc-rules-module.component.html',
  styleUrls: ['./cc-rules-module.component.css']
})
export class CcRulesModuleComponent implements OnInit {
    @ViewChild('ccRuleComponent', {static: true}) quillEditorComponentInstance; ccRuleComponent : CcRulesUiComponent;

    @Input() rules : any;
    constructor() { }
    passToRuleUI(value){
        this.ccRuleComponent.addRuleSet(value)
    }
    ngOnInit() {
    }
}
