import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { TierService } from '../tier.service';
import { RuleQueryComponent } from '../../rule-query/rule-query.component';
import { FlashMessageService } from '../../libraries/flash-message/services/flash-message.service';
import { CustomValidators } from '../../constants/CustomValidators';
import { CustomRoutes } from '../../custom-routes';
import { LanguageService } from '../../language-master/language.service';
import { TierLanguage } from '../models/tier-languages';
declare let $: any;

@Component({
    selector: 'app-tier-form',
    templateUrl: './tier-form.component.html',
    styleUrls: ['./tier-form.component.css']
})

export class TierFormComponent implements OnInit {
    tierForm: FormGroup;
    tierForm_error: string = "";
    tierId: number;
    pointTypes: any = [];
    customerCountrySubscription: any;
    agingMechanism: any;
    timePeriod = ['select', 365];
    lapsePeriod = ['select', 10, 20, 30, 40, 50];
    _formType: string = "add";
    tierRuleData;
    tierNotificationRuleData;
    languages: TierLanguage[] = [];
    customerTierLanguage: any[];
    error_tier_name_message: any = {};
    error_description_message: any = {};
    errorFlag: boolean = false;
    routes = CustomRoutes.routes;
    btn_disable:any = false;
    @ViewChild('ruleBuilder', {static: true}) ruleBuilder: RuleQueryComponent; 
    @ViewChild('ruleBuilder2', {static: true}) ruleBuilder2: RuleQueryComponent; 
    @Output() processSuccess: EventEmitter<any> = new EventEmitter();

    @Input() set formType(type: string) {
        if (['add', 'edit'].indexOf(type) === -1)
            throw 'Invalid Form Type';
        this._formType = type;
    }

    // loader_status: boolean = false;
    tier_retention: any = "tier-retention";
    tier_upgrade: any = "tier-upgrade";

    submitClick = false;
    notifTabSelectedIndex: number = 0;

    templateData = { email: { selectedIndex: null, data: [] }, push: { selectedIndex: null, data: [], linking: null, external_link: null, internal_link: null }, inapp: { selectedIndex: null, data: [] } };
    internalLinkingDD: any = [{
        id: "my_profile",
        itemName: "my_profile",
    }, {
        id: "home",
        itemName: "home",
    }, {
        id: "my_transactions",
        itemName: "my_transactions"
    }, {
        id: "loyalty",
        itemName: "loyalty"
    }];
    linkingDD: any = [{
        id: 'internal',
        itemName: "Internal"
    }, {
        id: 'external',
        itemName: "External"
    }];
    push_linking: any = null;
    inapp_linking: any = null;
    constructor(private languageService: LanguageService, private fb: FormBuilder, private customApiCalling: Custom_api_calling, private router: Router,
        private userService: UserService, private tierService: TierService, private activatedRoute: ActivatedRoute,
        private flashMessageService: FlashMessageService) {

        this.activatedRoute.params.subscribe((params) => {
            this.tierId = params.id;
        });
    }

    async ngOnInit() {
        this.getLanguages();
        this.tierForm = this.fb.group({
            'name': ([null, Validators.compose([Validators.required, CustomValidators.nameField])]),
            'code': [null, Validators.required],
            'description': [null, Validators.required],
            'time_period': [null, Validators.required],
            'daily_transaction': [null, Validators.required],
            'weekly_transaction': [null, Validators.required],
            'monthly_transaction': [null, Validators.required],
            'yearly_transaction': [null, Validators.required],
            'daily_amount': [null, Validators.required],
            'weekly_amount': [null, Validators.required],
            'monthly_amount': [null, Validators.required],
            'yearly_amount': [null, Validators.required],

            'push_linking': [null, Validators.required],
            'push_external_link': [null],
            'push_internal_link': [null],
            'inapp_linking': [null, Validators.required],
            'inapp_external_link': [null],
            'inapp_internal_link': [null]
        })

        //load rule component with script   [rule]="tierRuleData" [data_id]="tier_retention"
        await new Promise<void>(resolve => {
            this.ruleBuilder.loadScriptFromParent('tier_rule', '', () => {
                this.ruleBuilder2.initQueryBuilder('tier_notification_rule', '');
                resolve();
            })
        })



        this.tierService.loadPointTypeData((data) => {
            if (data.status && data['values']['point_type_list']) {
                this.pointTypes = [];

                for (let index in data['values']['point_type_list']) {
                    this.pointTypes.push(data['values']['point_type_list'][index])
                }

                this.processDynamicFormControls();
                this.loadCustomer();
            }
        });

        /* Load Tenant Points Aging Mechanism */
        this.userService.loadLoggedInTenant(() => { });
        let tenantSubscription = this.userService.castTenant.subscribe((tenant) => {
            this.agingMechanism = tenant.aging_mechanism;
        })

        this.userService.addModuleSubscription('TierFormComponent', tenantSubscription);
    }
    getLanguages() {
        this.languageService.get_all_languages((result) => {
            this.customerTierLanguage = result['values'];
            if (this._formType == 'add') {
                this.tierForm.reset();
                result['values'].forEach(element => {
                    this.languages.push(new TierLanguage({ language_name: element['language_name'], language_code: element['language_code'] }));
                });
            }

            for (const lang of result['values']) {
                Object.keys(this.templateData).forEach(e => {
                    this.templateData[e].data.push({ language_name: lang.language_name, language_code: lang.language_code })
                });
            }
            this.templateData.email.selectedIndex = 0;
        });
    }

    loadCustomer() {
        if (this._formType == 'edit' && typeof this.tierId !== 'undefined' && this.pointTypes.length > 0) {
            this.tierService.fetchCustomerTier(this.tierId, (customerTier) => {
                // this.loader_status = false;
                if (customerTier.status) customerTier = customerTier.values[0];

                for (let tier in customerTier) {
                    if (typeof this.tierForm.controls[tier] != 'undefined') {
                        this.tierForm.controls[tier].setValue(customerTier[tier]);
                    }
                }
                if (this._formType == 'edit') {
                    this.languages = customerTier['languages'];
                    this.tierForm.controls['code'].disable();
                    this.tierForm.get('code').setValidators([]);
                    this.tierForm.get('code').updateValueAndValidity();
                }


                if ((customerTier['tier_rule'] != "null") && (customerTier['tier_rule'] != null) && (customerTier['tier_rule'] != undefined) && (customerTier['tier_rule'] != 'undefined')) {
                    this.tierRuleData = customerTier['tier_rule'];
                    this.ruleBuilder.loadQueryRule('tier_rule', this.tierRuleData);

                }
                if ((customerTier['tier_notification_rule'] != "null") && (customerTier['tier_notification_rule'] != null) && (customerTier['tier_notification_rule'] != undefined) && (customerTier['tier_notification_rule'] != 'undefined')) {
                    this.tierNotificationRuleData = customerTier['tier_notification_rule'];
                    this.ruleBuilder2.loadQueryRule('tier_notification_rule', this.tierNotificationRuleData);

                }

                // this.ruleBuilder.loadQueryRule('tier_retention',this.tierRuleData);
                // this.ruleBuilder2.loadQueryRule('tier_upgrade',this.tierNotificationRuleData);


                for (let index in customerTier['point_configuration']) {
                    let pointTypeName = customerTier['point_configuration'][index].point_type_name;
                    if (typeof this.tierForm.controls[pointTypeName + '_point_transfer_rate'] != 'undefined') {
                        this.tierForm.controls[pointTypeName + '_point_transfer_rate'].setValue(customerTier['point_configuration'][index].point_transfer_rate);
                        this.tierForm.controls[pointTypeName + '_daily_point_cap'].setValue(customerTier['point_configuration'][index].daily_point_cap);
                        this.tierForm.controls[pointTypeName + '_monthly_point_cap'].setValue(customerTier['point_configuration'][index].monthly_point_cap);
                        this.tierForm.controls[pointTypeName + '_yearly_point_cap'].setValue(customerTier['point_configuration'][index].yearly_point_cap);
                        this.tierForm.controls[pointTypeName + '_point_expiry'].setValue(customerTier['point_configuration'][index].point_expiry);

                    }
                }

                if (customerTier['templateData']) {
                    Object.keys(customerTier['templateData']).forEach(key => {
                        this.templateData[key].data.forEach((e, i) => {
                            customerTier['templateData'][key].forEach(d => {
                                if (e.language_code == d.language_code)
                                    Object.assign(this.templateData[key].data[i], d);
                            });
                        });
                    });
                }

                this.push_linking = customerTier.push_linking;
                this.inapp_linking = customerTier.inapp_linking;
                this.tierForm.controls.push_linking.setValue(this.linkingDD.filter(e => e.id == this.push_linking));
                this.tierForm.controls.inapp_linking.setValue(this.linkingDD.filter(e => e.id == this.inapp_linking));
                if (customerTier.push_internal_link) {
                    this.tierForm.controls.push_internal_link.setValue(this.internalLinkingDD.filter(e => e.id == customerTier.push_internal_link));
                    this.linkingChange(this.tierForm.controls.push_linking.value[0], 'push');
                }
                if (customerTier.inapp_internal_link) {
                    this.tierForm.controls.inapp_internal_link.setValue(this.internalLinkingDD.filter(e => e.id == customerTier.inapp_internal_link));
                    this.linkingChange(this.tierForm.controls.inapp_linking.value[0], 'inapp');
                }

            });
        }
    }

    processTier(post) {
        if (this._formType == 'add') {
            this.addTier(post);
        } else if (this._formType == 'edit') {
            this.editTier(post);
        }
    }
    addTier(post) {

        this.field_validation();
        if (this.userService.isFormValid(this.tierForm)) {
            if (this.isTemplateValid()) {


                let form_data = {};

                form_data['name'] = post.name;
                form_data['code'] = post.code;
                form_data['languages'] = this.languages;
                form_data['description'] = post.description;
                form_data['time_period'] = post.time_period;
                form_data['convenience_fee'] = post.convenience_fee;
                form_data['tier_price'] = post.tier_price;
                form_data['convenience_fee'] = post.convenience_fee;
                form_data['purchase_point_expiry'] = post.purchase_point_expiry;
                form_data['daily_transaction'] = post.daily_transaction;
                form_data['weekly_transaction'] = post.weekly_transaction;
                form_data['monthly_transaction'] = post.monthly_transaction;
                form_data['yearly_transaction'] = post.yearly_transaction;
                form_data['daily_amount'] = post.daily_amount;
                form_data['weekly_amount'] = post.weekly_amount;
                form_data['monthly_amount'] = post.monthly_amount;
                form_data['yearly_amount'] = post.yearly_amount;
                form_data['tier_rule'] = JSON.stringify(this.ruleBuilder.getRuleData());
                form_data['tier_notification_rule'] = JSON.stringify(this.ruleBuilder2.getRuleData());
                form_data['notification_templates'] = this.templateData;

                form_data['push_linking'] = this.push_linking;
                form_data['push_external_link'] = post.push_external_link;
                form_data['push_internal_link'] = (post.push_internal_link && post.push_internal_link.length > 0) ? post.push_internal_link[0].id : null;
                form_data['inapp_linking'] = this.inapp_linking;
                form_data['inapp_internal_link'] = (post.inapp_internal_link && post.inapp_internal_link.length > 0) ? post.inapp_internal_link[0].id : null;
                form_data['inapp_external_link'] = post.inapp_external_link;

                form_data['points'] = {};
                for (let point in this.pointTypes) {
                    let pointTypeName = this.pointTypes[point].point_type_name;
                    form_data['points'][pointTypeName] = {};
                    form_data['points'][pointTypeName]['point_type_id'] = this.pointTypes[point].id;
                    form_data['points'][pointTypeName]['daily_point_cap'] = post[this.pointTypes[point].point_type_name + '_daily_point_cap'];
                    form_data['points'][pointTypeName]['monthly_point_cap'] = post[this.pointTypes[point].point_type_name + '_monthly_point_cap'];
                    form_data['points'][pointTypeName]['yearly_point_cap'] = post[this.pointTypes[point].point_type_name + '_yearly_point_cap'];
                    form_data['points'][pointTypeName]['point_transfer_rate'] = post[this.pointTypes[point].point_type_name + '_point_transfer_rate'];
                    form_data['points'][pointTypeName]['point_expiry'] = post[this.pointTypes[point].point_type_name + '_point_expiry'];
                }

                this.customApiCalling.http_calling_file_post('api/tenant/customer_tiers/add_customer_tier', form_data, (data) => {
                    if (data['status'] == true) {
                        this.flashMessageService.showMessage(data.message, "alert alert-success");
                        setTimeout(() => {
                            this.processSuccess.emit();
                        }, 2000);
                    } else {
                        this.tierForm_error = this.userService.displayErrors({
                            errors: data.errors,
                            message: data.message
                        });
                    }
                })
            } else {
                this.flashMessageService.showMessage('Please fill all the notification templates', "alert alert-danger");
            }
        }
    }

    editTier(post) {
        this.field_validation();
        if (this.userService.isFormValid(this.tierForm)) {
            if (this.isTemplateValid()) {

                let form_data = {};

                form_data['name'] = post.name;
                form_data['code'] = post.code;
                form_data['languages'] = this.languages;
                form_data['description'] = post.description;
                form_data['time_period'] = post.time_period;
                form_data['convenience_fee'] = post.convenience_fee;
                form_data['tier_price'] = post.tier_price;
                form_data['customer_tier_id'] = this.tierId;
                form_data['daily_transaction'] = post.daily_transaction;
                form_data['weekly_transaction'] = post.weekly_transaction;
                form_data['monthly_transaction'] = post.monthly_transaction;
                form_data['yearly_transaction'] = post.yearly_transaction;
                form_data['daily_amount'] = post.daily_amount;
                form_data['weekly_amount'] = post.weekly_amount;
                form_data['monthly_amount'] = post.monthly_amount;
                form_data['yearly_amount'] = post.yearly_amount;
                form_data['tier_rule'] = JSON.stringify(this.ruleBuilder.getRuleData());
                form_data['tier_notification_rule'] = JSON.stringify(this.ruleBuilder2.getRuleData());
                form_data['notification_templates'] = this.templateData;

                form_data['push_linking'] = this.push_linking;
                form_data['push_external_link'] = post.push_external_link;
                form_data['push_internal_link'] = (post.push_internal_link && post.push_internal_link.length > 0) ? post.push_internal_link[0].id : null;
                form_data['inapp_linking'] = this.inapp_linking;
                form_data['inapp_internal_link'] = (post.inapp_internal_link && post.inapp_internal_link.length > 0) ? post.inapp_internal_link[0].id : null;
                form_data['inapp_external_link'] = post.inapp_external_link;

                form_data['points'] = {};
                for (let point in this.pointTypes) {
                    let pointTypeName = this.pointTypes[point].point_type_name;
                    form_data['points'][pointTypeName] = {};
                    form_data['points'][pointTypeName]['point_type_id'] = this.pointTypes[point].id;
                    form_data['points'][pointTypeName]['daily_point_cap'] = post[this.pointTypes[point].point_type_name + '_daily_point_cap'];
                    form_data['points'][pointTypeName]['monthly_point_cap'] = post[this.pointTypes[point].point_type_name + '_monthly_point_cap'];
                    form_data['points'][pointTypeName]['yearly_point_cap'] = post[this.pointTypes[point].point_type_name + '_yearly_point_cap'];
                    form_data['points'][pointTypeName]['point_transfer_rate'] = post[this.pointTypes[point].point_type_name + '_point_transfer_rate'];
                    form_data['points'][pointTypeName]['point_expiry'] = post[this.pointTypes[point].point_type_name + '_point_expiry'];
                }


                this.customApiCalling.http_calling_json_put('api/tenant/customer_tiers/edit_customer_tier/' + this.tierId, form_data, (data) => {
                    if (data['status'] == true) {
                        this.flashMessageService.showMessage(data.message, "alert alert-success");
                        setTimeout(() => {
                            this.processSuccess.emit();
                        }, 2000);
                    } else {
                        this.tierForm_error = this.userService.displayErrors({
                            errors: data.errors,
                            message: data.message
                        });
                    }
                })
            } else {
                this.flashMessageService.showMessage('Please fill all the notification templates', "alert alert-danger");
            }
        }
    }

    processDynamicFormControls() {
        for (let point in this.pointTypes) {
            this.tierForm.addControl(this.pointTypes[point]['point_type_name'] + '_point_transfer_rate', new FormControl(null, Validators.compose([Validators.required, CustomValidators.priceField])));
            this.tierForm.addControl(this.pointTypes[point]['point_type_name'] + '_daily_point_cap', new FormControl(null, Validators.compose([Validators.required, CustomValidators.priceField])));
            this.tierForm.addControl(this.pointTypes[point]['point_type_name'] + '_monthly_point_cap', new FormControl(null, Validators.compose([Validators.required, CustomValidators.priceField])));
            this.tierForm.addControl(this.pointTypes[point]['point_type_name'] + '_yearly_point_cap', new FormControl(null, Validators.compose([Validators.required, CustomValidators.priceField])));
            this.tierForm.addControl(this.pointTypes[point]['point_type_name'] + '_point_expiry', new FormControl(null, Validators.required));
        }
    }

    ngOnDestroy() {
        this.userService.destroyModuleSubscriptions('TierFormComponent');
    }

    field_validation() {
        this.error_tier_name_message = {};
        this.error_description_message = {};
        for (let i = 0; i < this.languages.length; i++) {
            this.error_tier_name_message['err' + i] = null;
            this.error_description_message['err' + i] = null;
            this.errorFlag = false;

            if (this.languages[i]['tier_name'] == undefined || this.languages[i]['tier_name'] == '' || this.languages[i]['tier_name'] == null) {
                this.error_tier_name_message['err' + i] = 'This field is Required';
                this.errorFlag = true;
            }
            if (this.languages[i]['description'] == undefined || this.languages[i]['description'] == '' || this.languages[i]['description'] == null) {
                this.error_description_message['err' + i] = 'This field is Required';
                this.errorFlag = true;
            }
        }
    }

    field_required(val) {
        if (val == undefined || val == null || val.trim() == '') {
            this.errorFlag = true;
            return true;
        } else return false;
    }

    changeTemplateCode(index) {
        let iframe = $('iframe')[0];
        iframe.contentWindow.document.open();
        iframe.contentDocument.write(this.templateData.email.data[index]["body"]);
        iframe.contentWindow.document.close();
    }

    isTemplateValid() {

        for (const template in this.templateData) {
            const field = [(template == 'email' ? 'subject' : 'title'), 'body'];

            for (let index = 0; index < this.templateData[template].data.length; index++) {
                for (const val of field) {
                    const lang = this.templateData[template].data[index];
                    if (lang[val] == undefined || lang[val] == null || lang[val].trim() == '') {
                        this.notifTabSelectedIndex = template == 'email' ? 0 : template == 'push' ? 1 : 2;
                        this.templateData[template].selectedIndex = index;
                        return false;
                    }

                }

            }
        }

        return true;
    }

    linkingChange(event, type) {
        if (type == 'push') {
            this.push_linking = event.id;
            if (event.id == 'internal') {
                this.tierForm.controls.push_internal_link.setValidators(Validators.required);
                this.tierForm.controls.push_external_link.setValidators(null)
            } else {
                this.tierForm.controls.push_external_link.setValidators(Validators.required);
                this.tierForm.controls.push_internal_link.setValidators(null)
            }
        } else if (type == 'inapp') {
            this.inapp_linking = event.id;
            if (event.id == 'internal') {
                this.tierForm.controls.inapp_internal_link.setValidators(Validators.required);
                this.tierForm.controls.inapp_external_link.setValidators(null)
            } else {
                this.tierForm.controls.inapp_external_link.setValidators(Validators.required);
                this.tierForm.controls.inapp_internal_link.setValidators(null)
            }
        }
    }
}
