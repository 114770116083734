import { Injectable } from '@angular/core';
import {
	Custom_api_calling
} from '../adapters/Custom_api_calling';
import {
	BehaviorSubject
} from 'rxjs/BehaviorSubject';

@Injectable()
export class EmailTemplateService {
	emailTemplate = new BehaviorSubject([]);
	emailTemplates = new BehaviorSubject([]);
	activityList = new BehaviorSubject([]);
	tagLists = new BehaviorSubject([]);
	castTagLists = this.tagLists.asObservable();
	castActivityList = this.activityList.asObservable();
	castEmailTemplates = this.emailTemplates.asObservable();
	castEmailtemplate = this.emailTemplate.asObservable();
	constructor(private customCallingApi: Custom_api_calling) { }

	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}
	getEmailTemplates(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get('api/tenant/email_template/get_email_template_list?' + query_data, (result) => {
			callback(result);
		});
	}
	getEmailTemplateById(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/email_template/get_email_template_by_id/' + data.email_template_id, callback);
	}
	addEmailTemplate(data, callback) {
		const api = 'api/tenant/email_template/add_email_template';
		this.customCallingApi.http_calling_file_post(api, data, callback);
	}

	editEmailTemplate(data, callback) {
		const api = 'api/tenant/email_template/edit_email_template/' + data.email_template_id;
		this.customCallingApi.http_calling_file_put(api, data, callback);
	}

	getActivityList(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/activities/list?type=' + data, callback);
	}
	getTagsList(data, callback) {
		const api = 'api/tenant/email_template/get_tag_list';
		this.customCallingApi.http_calling_file_post(api, data, callback);
	}

	getSelectedActivityList(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/email_template/get_selected_activity_list/' + data.email_template_id, callback);
	}

	getSelectedEmailLanguages(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/email_template/get_selected_email_languages/' + data.email_template_id, callback);
	}

	getLanguages(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get('api/tenant/rule_activity/getLanguages?' + query_data, callback);
	}
}
