import { ActivatedRoute, Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomRoutes {

	static router: Router
	static current_url = "";
	static routes: any = {
		activities: {
			add_activity: 'activities/add_activity',
			edit_activity: 'activities/edit_activity/:id',
			all_activities: 'activities/all_activities',
		},

		download_manager: 'download_manager', 
		cohort: 'cohort',
		// base_configuration: 'base_configuration',
		login: 'login',
		customer: {
			bankCardForm: 'customer/bankCardForm',
			// customer: 'customer/customer_list',
			customer_list: 'customer/customer_list',
			customer_profile: 'customer/customer_profile/:id',
			customer_edit: 'customer/customer_edit/:id',
			add_customer: 'customer/add_customer',
			emailmessage: 'customer/emailmessage',
			refund_point: 'customer/add_point',
			add_point: 'customer/add_point',
			redeem_point: 'customer/redeem_point',
			manage_customer_bulk_uploads: 'customer/manage_customer_bulk_uploads',
			view_bulk_upload_file_data: 'customer/view_bulk_upload_file_data/:id',
			customer_card: 'customer/customer_card/:id',
			manage_customer_card_bulk_uploads: 'customer/manage_customer_card_bulk_uploads',
			view_customer_card_bulk_uploads: 'customer/view_customer_card_bulk_uploads/:id',
		},
		
		customer_segments: {
			create_customer_segments: 'customer_segments/create_customer_segments',
			customer_segments_list: 'customer_segments/customer_segments_list',
			modify_customer_segments: 'customer_segments/modify_customer_segments/:segment_id',
			create_customer_rule_segments: 'customer_segments/create_customer_rule_segments',
			customer_rule_segments_list: 'customer_segments/customer_rule_segments_list',
			modify_customer_rule_segments: 'customer_segments/modify_customer_rule_segments/:segment_id',
		},
		customers: {
			add_customer: 'customers/add_customer',
			customer_list: 'customers/customer_list',
			edit_customer: 'customers/customer_edit/:id',
		},
		cron: {
			
			add_cron: 'cron/add_cron',
			modify_cron: 'cron/modify_cron/:cron_id',
			cron_list: 'cron/cron_list',
		},
		customer_tiers: {

			add_tier: 'customer_tiers/add_tier',
			edit_tier: 'customer_tiers/edit_tier/:id',
			manage_tier: 'customer_tiers/manage_tier',
			add_tier_upgrade: 'customer_tiers/add_tier_upgrade',
			edit_tier_upgrade: 'customer_tiers/edit_tier_upgrade/:id',
			manage_tier_upgrade: 'customer_tiers/manage_tier_upgrade',
		},
		customer_heatmap: 'customer_heatmap/heatmap',
		cc_rules: {
			ruleui: 'cc_rules/ruleui'
		},
		configuration: {
			add_email_config: 'configuration/add_email_config',
			email_config_list: 'configuration/email_config_list',
			edit_email_config: 'configuration/edit_email_config/:email_provider_id',
		},
		dashboard: {
			dashboard: 'dashboard',
			dashboard_program_calendar:'dashboard/dashboard_program_calendar'
		},
		developer_panel: {
			routes: 'developer_panel/routes',
			api_validator: 'developer_panel/api_validator',
			assign_api_groups: 'developer_panel/assign_api_groups',
			assigned_route_api_list: 'developer_panel/assigned_route_api_list/:group_id',
		},
		email_campaign: {
			create_email_campaign: 'email_campaign/create_email_campaign',
			modify_email_campaign: 'email_campaign/modify_email_campaign/:email_campaign_id',
			email_campaign_list: 'email_campaign/email_campaign_list',
			email_campaign_log: 'email_campaign/email_campaign_log/:email_campaign_id',
			email_campaign_email_list: 'email_campaign/email_campaign_email_list/:email_campaign_id/:running_id',
		},
		flat_file_model: {
			add_flat_file_model: 'flat_file_model/add_flat_file_model',
			list_flat_file_model: 'flat_file_model/list_flat_file_model',
			edit_flat_file_model: 'flat_file_model/edit_flat_file_model/:id',
			upload_accrual_file: 'flat_file_model/upload_accrual_file',
			manage_accrual_files: 'flat_file_model/manage_accrual_files',
			accrual_file_data: 'flat_file_model/accrual_file_data/:id',
		},
		html_templates: {
			add_html_template: 'html_templates/add_html_template',
			html_templates_list: 'html_templates/html_templates_list',
		},
		point_definition: {
			add_point_definition: 'point_definition/add_point_definition',
			list_point_definition: 'point_definition/list_point_definition',
			edit_point_definition: 'point_definition/edit_point_definition/:id',
		},
		point_type: {
			add_point_type: 'point_type/add_point_type',
			point_type_list: 'point_type/list_point_type',
			edit_point_type: 'point_type/edit_point_type/:id',
		},
		city: {
			add_city: 'city/add_city',
			city_list: 'city/city_list',
			edit_city: 'city/edit_city/:id',
		},
		product: {
			add_product: 'product/add_product',
			product_list: 'product/product_list',
			edit_product: 'product/edit_product/:id',
		},

		redemption_rules: {
			add_redemption_rule: 'redemption_rules/add_redemption_rule',
			edit_redemption_rule: 'redemption_rules/edit_redemption_rule/:id',
			manage_redemption_rule: 'redemption_rules/manage_redemption_rule',
			redemption_rule_details: 'redemption_rules/redemption_rule_details/:id',
		},
		settings: {
			customise_customer: 'settings/customise_customer',
			add_dynamic_customer: 'settings/add_dynamic_customer',
			modify_dynamic_customer: 'settings/modify_dynamic_customer',
			customise_member: 'settings/customise_member',
			add_dynamic_member: 'settings/add_dynamic_member',
			modify_dynamic_member: 'settings/modify_dynamic_member/:id',
			dynamic_members_list: 'settings/dynamic_members_list',
			add_tp_registration: 'settings/add_tp_registration',
			edit_tp_registration: 'settings/edit_tp_registration/:id',
			tp_registration_list: 'settings/tp_registration_list',
			add_lock_point: 'settings/add_lock_point',
			general_settings: 'settings/general_settings',
			velocity_check: 'settings/velocity_check',
		},
		tags: {
			add_tags: 'tags/add_tags',
			tags_list: 'tags/tags_list',
			edit_tag: 'tags/edit_tag/:id',
		},
		tenant_base_configuration: {
			categories: 'tenant_base_configuration/categories',
			modify_category: 'tenant_base_configuration/modify_category/:id',
			create_category: 'tenant_base_configuration/create_category',
			manage_sub_categories: 'tenant_base_configuration/manage_sub_categories/:id',
			modify_sub_category: 'tenant_base_configuration/modify_sub_category/:id',
			create_sub_category: 'tenant_base_configuration/create_sub_category',
			edit_point_configuration: 'tenant_base_configuration/edit_point_configuration',
			program_details: 'tenant_base_configuration/program_details',
		},
		tenant_branch: {
			add_tenant_branch_management: 'tenant_branch/add_tenant_branch_management',
			list_tenant_branch_management: 'tenant_branch/list_tenant_branch_management',
			edit_tenant_branch_management: 'tenant_branch/edit_tenant_branch_management/:id',
		},
		tenant_coupon_code: {
			coupon_code: 'tenant_coupon_code/coupon_code',
			add_coupon_code: 'tenant_coupon_code/add_coupon_code',
			edit_coupon_code: 'tenant_coupon_code/edit_coupon_code/:id',
		},
		tenant_email_template: {
			add_email_template: 'tenant_email_template/add_email_template',
			list_email_template: 'tenant_email_template/list_email_template',
			edit_email_template: 'tenant_email_template/edit_email_template/:id',
		},
		tenant_merchant: {
			merchant_list: 'tenant_merchant/merchant_list',
			merchant_profile: 'tenant_merchant/merchant_profile/:id',
			merchant_tree_information: 'tenant_merchant/merchant_tree_information',
			add_merchant: 'tenant_merchant/add_merchant',
			modify_merchant: 'tenant_merchant/modify_merchant/:id',
			recommended_merchants: 'tenant_merchant/recommended_merchants',
		},
		tenant_payables: {
			payables: 'tenant_payables/payables',
		},
		tenant_rule: {
			add_earning_point_rule: 'tenant_rule/add_earning_point_rule',
			edit_earning_point_rule: 'tenant_rule/edit_earning_point_rule/:id',
			manage_earning_point_rule: 'tenant_rule/manage_earning_point_rule',
			earning_point_rule_details: 'tenant_rule/earning_point_rule_details/:id',
		},
		tenant_receivables: {
			receivables: 'tenant_receivables/receivables',
		},
		point_request:{
			point_request: 'point_request/point_request',
			redemption_point_request: 'point_request/redemption_point_request'
		},
		tenant_report: {
			dynamic_reports: 'tenant_report/dynamic_reports',
			dynamic_report_setting: 'tenant_report/dynamic_report_setting',
			accrual_reports: 'tenant_report/accrual_reports',
			redemption_reports: 'tenant_report/redemption_reports',
			offer_redemption_reports: 'tenant_report/offer_redemption_reports',
			reward_redemption_reports: 'tenant_report/reward_redemption_reports',
			fraud_reports: 'tenant_report/fraud_reports',
			tier_reports: 'tenant_report/tier_reports',
			scan_reports: 'tenant_report/scan_reports',
			recon_view_report: 'tenant_report/recon_view_report',
			friends_family_reports: 'tenant_report/friends_family_reports',
			customer_reports: 'tenant_report/customer_reports',
			custom_reports: 'tenant_report/custom_reports',
			point_accrual_reports: 'tenant_report/point_accrual_reports',
			offer_available_reports: 'tenant_report/offer_available_reports',
			point_redemption_reports: 'tenant_report/point_redemption_reports',
			customer_account_reports: 'tenant_report/customer_account_reports',
			member_statement_reports: 'tenant_report/member_statement_reports',
			overall_points_report: 'tenant_report/overall_points_report',
			login_reports: 'tenant_report/login_reports',
			referral_app_report: 'tenant_report/referral_app_report',
			referral_product_report: 'tenant_report/referral_product_report',
			member_report: 'tenant_report/member_report',
			data_reports: 'tenant_report/data_reports',
			customer_reports_all: 'tenant_report/customer_reports_all',
			accrual_reports_all: 'tenant_report/accrual_reports_all',
			redemption_reports_all: 'tenant_report/redemption_reports_all',
			customer_reports_calendar: 'tenant_report/customer_reports_calendar',
			accrual_reports_calendar: 'tenant_report/accrual_reports_calendar',
			redemption_reports_calendar: 'tenant_report/redemption_reports_calendar',
			invoice_report: 'tenant_report/invoice_report',
			consecutive_days_reports: 'tenant_report/consecutive_days_reports',
			offer_issuance_reports: 'tenant_report/offer_issuance_reports',
			points_expiry_reports: 'tenant_report/points_expiry_reports',
			campaign_reports: 'tenant_report/campaign_reports',
			member_upgrade_downgrade_reports: 'tenant_report/member_upgrade_downgrade_reports',
			current_upgrade_downgrade_reports: 'tenant_report/current_upgrade_downgrade_reports',
			logout_reports: 'tenant_report/logout_reports',
			offer_expiry_reports: 'tenant_report/offer_expiry_reports',
			points_expired_reports: 'tenant_report/points_expired_reports',
			tier_downgrade: 'tenant_report/tier_downgrade',
			deleted_members: 'tenant_report/deleted_members',
			merchant_payable_report: 'tenant_report/merchant_payable_report',

		},
		tenant_sms_template: {
			add_sms_template: 'tenant_sms_template/add_sms_template',
			list_sms_template: 'tenant_sms_template/list_sms_template',
			edit_sms_template: 'tenant_sms_template/edit_sms_template/:id',
			sms_template: 'tenant_sms_template/sms_template',
		},
		tenant_user_role: {
			manage_tenant_role_privileges: 'tenant_user_role/manage_tenant_role_privileges/:role_id',
			tenant_user_role_list: 'tenant_user_role/tenant_user_role_list',
			add_tenant_user_role: 'tenant_user_role/add_tenant_user_role',
			edit_tenant_user_role: 'tenant_user_role/edit_tenant_user_role/:id',
		},
		tenant_program: {
			program_info_tree: 'tenant_program/program_info_tree',
		},
		tenant_user: {
			manage_tenant_user_privileges: 'tenant_user/manage_tenant_user_privileges/:tenant_user_id',
			tenant_user_list: 'tenant_user/tenant_user_list',
			add_tenant_user: 'tenant_user/add_tenant_user',
			edit_tenant_user: 'tenant_user/edit_tenant_user/:id',
		},

		school_master: {
			add_school: 'school/add_school',
			edit_school: 'school/edit_school/:id',
			list_school: 'school/list_school'
		},

		wallet: {
			add_wallet: 'wallet/add_wallet',
			edit_wallet: 'wallet/edit_wallet/:id',
			manage_wallet: 'wallet/manage_wallet',
		},

		user_permissions: {

		},
		user_profile: {
			user_profile: 'user_profile/user_profile',
		},
		user_type: {
			add_user_type: 'user_type/add_user_type',
			modify_user_type: 'user_type/modify_user_type/:user_type_id',
			user_type_list: 'user_type/user_type_list',
		},
		push_notification: {
			push_notification: 'push_notification/push_notification',
			list_push_notification: 'push_notification/list_push_notification',
			edit_push_notification: 'push_notification/edit_push_notification/:id',
		},
		brand: {
			add_brand: 'brand/add_brand',
			brand_edit: 'brand/brand_edit/:id',
			brand_list: 'brand/brand_list',
		},

		activity_log: {
			email_activity_log_list: 'activity_log/email_activity_log_list',
			notification_activity_log_list: 'activity_log/notification_activity_log_list',
			sms_activity_log_list: 'activity_log/sms_activity_log_list',
		},

		country: {
			add_country: 'country/add_country',
			edit_country: 'country/country_edit/:id',
			country_list: 'country/country_list',
			country_bulk_update: 'country/country_bulk_update',
			country_file_data: 'country/country_file_data/:id'
		},
		capping: {
			add_capping: 'capping/add_capping',
			edit_capping: 'capping/capping_edit/:id',
			capping_list: 'capping/capping_list',
		},
		watchlist: {
			add_watchlist_members: 'watchlist/add_watchlist_members',
			list_watchlist_members: 'watchlist/list_watchlist_members',
			delete_watchlist_members: 'watchlist/delete_watchlist_members',
		},
		offerAssignment: {
			// offerAssignment:'offerAssignment',
			bulk_upload_offers: 'offerAssignment/bulk_upload_offers'
		},

		affiliate_report: {
			affiliate_report: 'affiliate_report/affiliate_report',
			affiliate_report_errors: 'affiliate_report/affiliate_report_errors',
		},
		partner: {
			add_partner: 'partner/add_partner',
			edit_partner: 'partner/edit_partner/:id',
			partner_list: 'partner/partner_list',
		},
		commission: {
			add_commission: 'commission/add_commission',
			edit_commission: 'commission/edit_commission/:id',
			commission_list: 'commission/commission_list',
		},
		referral_campaign: {
			add_campaign: 'referral_campaign/add_campaign',
			modify_campaign: 'referral_campaign/modify_campaign/:id',
			campaign_list: 'referral_campaign/campaign_list'
		},
		merchant: {
			add_merchant: 'merchant/add_merchant',
			edit_merchant: 'merchant/edit_merchant/:id',
			merchant_list: 'merchant/merchant_list',
			add_merchant_type: 'merchant/add_merchant_type',
			edit_merchant_type: 'merchant/edit_merchant_type/:id',
			merchant_type_list: 'merchant/merchant_type_list',
		},
		member_types: {
			add_member_type: 'member_types/add_member_type',
			member_type_edit: 'member_types/member_type_edit/:id',
			member_type_list: 'member_types/member_type_list',
		},
		language: {
			add_language: 'language/add_language',
			edit_language: 'language/language_edit/:id',
			language_list: 'language/language_list',
		},
		referral_program_type: {
			add_referral_program_type: 'referral_program_type/add_referral_program_type',
			edit_referral_program_type: 'referral_program_type/edit_referral_program_type/:id',
			list_referral_program_type: 'referral_program_type/list_referral_program_type',
		},
		product_type: {
			add_product_type: 'product_type/add_product_type',
			edit_product_type: 'product_type/edit_product_type/:id',
			list_product_type: 'product_type/list_product_type',
		},
		product_sub_type: {
			add_product_sub_type: 'product_sub_type/add_product_sub_type',
			edit_product_sub_type: 'product_sub_type/edit_product_sub_type/:id',
			list_product_sub_type: 'product_sub_type/list_product_sub_type',
		},
		referral: {
			referral_list: 'referral/referral_list',
			product_referral_list: 'referral/product_referral_list',
		},
		notification: {
			notification: 'notification/notification',
			list_notification: 'notification/list_notification',
			edit_notification: 'notification/edit_notification/:id',
		},
		segment: {
			add_segment: 'segment/add_segment',
			edit_segment: 'segment/edit_segment/:id',
			list_segment: 'segment/list_segment',
		},
		api_permission_module: {
			add_api_permission_module: 'api_permission_module/add_api_permission_module',
			edit_api_permission_module: 'api_permission_module/edit_api_permission_module/:id',
			list_api_permission_module: 'api_permission_module/list_api_permission_module',
		},
		status_name: {
			add_status_name: 'status_name/add_status_name',
			edit_status_name: 'status_name/edit_status_name/:id',
			status_name_list: 'status_name/status_name_list',
		},
		fraud_management: {
			suspicious_transaction: 'fraud_management/suspicious_transaction',
		},
		promocodes: {
			promocode_form: 'promocodes/promocode_form',
			promocode_list: 'promocodes/promocode_list',
			add_promocode: 'promocodes/add_promocode',
			edit_promocode: 'promocodes/edit_promocode/:id'
		},
		attributes: {
			add_attributes: "attributes/add_attributes",
			edit_attributes: "attributes/edit_attributes/:edit/:id",
			attributes_list: "attributes/attributes_list",
			add_bulkupload: "attributes/add_bulkupload",
			view_attributes: "attributes/view_attributes/:edit/:id"
		  },
		  reward_engine: {
			add_reward_engine: "reward_engine/add_reward_engine/:lob_id",
			edit_reward_engine: "reward_engine/edit_reward_engine/:lob_id/:edit/:id",
			reward_engine_list: "reward_engine/reward_engine_list/:lob_id",
			view_reward_engine: "reward_engine/view_reward_engine/:lob_id/:edit/:id",
		  },
		  reward_engine_superseded: {
			add_reward_engine: "reward_engine_superseded/add_reward_engine_superseded",
			edit_reward_engine: "reward_engine_superseded/edit_reward_engine_superseded/:edit/:id",
			reward_engine_list: "reward_engine_superseded/reward_engine_list_superseded"
		  },
	  
		  coupon_engine: {
			add_coupon_engine: "coupon_engine/add_coupon_engine/:lob_id",
			edit_coupon_engine: "coupon_engine/edit_coupon_engine/:lob_id/:edit/:id",
			coupon_engine_list: "coupon_engine/coupon_engine_list/:lob_id",
			view_coupon_engine: "coupon_engine/view_coupon_engine/:lob_id/:edit/:id",
		  },
		  co_brand_coupon: {
			add: "co_brand_coupon/add/:lob_id",
			edit: "co_brand_coupon/edit/:lob_id/:edit/:id",
			list: "co_brand_coupon/list/:lob_id",
			view: "co_brand_coupon/view/:lob_id/:edit/:id",
		  },
		  coupon_burn_engine: {
			add_coupon_burn_engine: "coupon_burn_engine/add_coupon_burn_engine/:lob_id",
			edit_coupon_burn_engine: "coupon_burn_engine/edit_coupon_burn_engine/:lob_id/:edit/:id",
			coupon_burn_engine_list: "coupon_burn_engine/coupon_burn_engine_list/:lob_id",
			view_coupon_burn_engine: "coupon_burn_engine/view_coupon_burn_engine/:lob_id/:edit/:id",
		  },
	  
		  groupset: {
			add_groupset: "groupset/add_groupset",
			groupset_detail: "groupset/groupset_detail/:type/:id",
			groupset_list: "groupset/groupset_list",
			view_groupset: "groupset/view_groupset/:type/:id",
		  },
	  
		  rewards: {
			add_rewards: "rewards/add_rewards",
			edit_rewards: "rewards/edit_rewards/:id",
			rewards_list: "rewards/rewards_list"
		  },
		  lob: {
			add_lob: "lob/add_lob",
			edit_lob: "lob/edit_lob/:edit/:id",
			lob_list: "lob/lob_list",
			view_lob: "lob/view_lob/:edit/:id"
		  },
		  customer_segment: {
			add_segment: "customer_segment/add_segment",
			segment_details: "customer_segment/segment_details/:type/:id",
			segment_list: "customer_segment/segment_list",
			view_segment: "customer_segment/view_segment/:type/:id",
		  },
		  base_configuration: {
			add_configuration: "base_configuration/add_configuration",
			base_configuration_detail:
			  "base_configuration/base_configuration_detail/:type/:id",
			configuration_list: "base_configuration/configuration_list",
	  
			add_redemption_configuration: "base_configuration/add_redemption_configuration",
			redemption_configuration_detail: "base_configuration/redemption_configuration_detail/:type/:id",
			redemption_configuration_list: "base_configuration/redemption_configuration_list",
	  
			add_miles_for_cash: "base_configuration/add_miles_for_cash",
			edit_miles_for_cash: "base_configuration/edit_miles_for_cash/:type/:id",
			list_miles_for_cash: "base_configuration/list_miles_for_cash"
		  },
	  
		  base_config_cash_miles: {
			add_cash_miles_rules: "base_config_cash_miles/add_cash_miles_rules/:lob_id",
			edit_cash_miles_rules: "base_config_cash_miles/edit_cash_miles_rules/:lob_id/:edit/:id",
			cash_miles_rules_list: "base_config_cash_miles/cash_miles_rules_list/:lob_id"
		  },
	  
		  funding_partner: {
			add_funding_partner: "funding_partner/add_funding_partner",
			funding_partner_details:
			  "funding_partner/funding_partner_details/:type/:id",
			funding_partner_list: "funding_partner/funding_partner_list",
			view_funding_partner:
			  "funding_partner/view_funding_partner/:type/:id",
		  },
		  funding_attributes: {
			add_funding_attributes: "funding_attributes/add_funding_attributes",
			funding_attributes_details:
			  "funding_attributes/funding_attributes_details/:type/:id",
			funding_attributes_list: "funding_attributes/funding_attributes_list",
			view_funding_attributes:
			  "funding_attributes/view_funding_attributes/:type/:id",
		  },
		  coupon_funding_partner: {
			add_coupon_funding_partner: "coupon_funding_partner/add_coupon_funding_partner",
			edit_coupon_funding_partner: "coupon_funding_partner/edit_coupon_funding_partner/:type/:id",
			coupon_funding_partner_list: "coupon_funding_partner/coupon_funding_partner_list",
			view_coupon_funding_partner: "coupon_funding_partner/view_coupon_funding_partner/:type/:id",
		  },
		  burn_engine: {
			add_burn_engine: "burn_engine/add_burn_engine/:lob_id",
			edit_burn_engine: "burn_engine/edit_burn_engine/:lob_id/:edit/:id",
			burn_engine_list: "burn_engine/burn_engine_list/:lob_id",
			view_burn_engine: "burn_engine/view_burn_engine/:lob_id/:edit/:id",
		  },
		  round_off_accrual_rule: {
			edit_round_off: "round_off_accrual_rule/:engine_type/:transaction_type/:lob_id"
		  },
		  round_off_redemption_rule: {
			edit_round_off: "round_off_redemption_rule/:engine_type/:transaction_type/:lob_id"
		  },
		  round_off_cash_miles_rule: {
			edit_round_off: "round_off_cash_miles_rule/:engine_type/:transaction_type/:lob_id"
		  },
		  round_off_accrual_coupon: {
			edit_round_off: "round_off_accrual_coupon/:engine_type/:transaction_type/:lob_id"
		  },
		  round_off_redemption_coupon: {
			edit_round_off: "round_off_redemption_coupon/:engine_type/:transaction_type/:lob_id"
		  },
		  round_off_miles_for_cash_rule: {
			edit_round_off: "round_off_miles_for_cash_rule/:engine_type/:transaction_type/:lob_id"
		  },
		  cash_miles_rules: {
			add_cash_miles_rules: "cash_miles_rules/add_cash_miles_rules/:lob_id",
			edit_cash_miles_rules: "cash_miles_rules/edit_cash_miles_rules/:lob_id/:edit/:id",
			cash_miles_rules_list: "cash_miles_rules/cash_miles_rules_list/:lob_id",
			view_cash_miles_rules: "cash_miles_rules/view_cash_miles_rules/:lob_id/:edit/:id",
		  },
		  miles_for_cash: {
			add: "miles_for_cash/add/:lob_id",
			edit: "miles_for_cash/edit/:lob_id/:edit/:id",
			list: "miles_for_cash/list/:lob_id",
			view: "miles_for_cash/view/:lob_id/:edit/:id",
		  },
	  
	}

	static navigate(UrlLink: any) {

console.log('==UrlLink',UrlLink);


		CustomRoutes.current_url = UrlLink[0];
		if (Array.isArray(UrlLink)) {
			let url = UrlLink[0];
			console.log('==UrlLink[0]',UrlLink);
			
			let values = [];
			for (let i = 1; i < UrlLink.length; i++) {
				values.push(UrlLink[i]);
			}
			console.log('===+values',values);
						
console.log('00000000url',url);
			let params = url.split("/:");
			let url_ = params[0];
			console.log('==params',params);
			console.log('==url',url_);
			
			for (let i = 1; i < params.length; i++) {
				url_ += "/" + values[i - 1];
			}
			console.log('==url_',url_);
			this.router.navigate([url_])
		}
		else if (typeof (UrlLink) == "string") {
			console.log('==if string',UrlLink);
			
			this.router.navigate([UrlLink]);
		}
	}

	static getUrl(UrlLink: any) {

		console.log('==UrlLink01',UrlLink);
		
		if (Array.isArray(UrlLink)) {
			let url = UrlLink[0];
			let values = [];
			for (let i = 1; i < UrlLink.length; i++) {
				values.push(UrlLink[i]);
			}
			let params = url.split("/:");
			let url_ = params[0];
			for (let i = 1; i < params.length; i++) {
				url_ += "/" + values[i - 1];
			}
			return url_;
		
    } else if (typeof UrlLink == "string") {
		return UrlLink;
		}
	}
	static get_all_routes(obj: any = this.routes, count = 0) {
		let keys = Object.keys(obj);
		let list = [];
		for (let i = 0; i < keys.length; i++) {
			if (typeof obj[keys[i]] == "object") {
				list = list.concat(this.get_all_routes(obj[keys[i]], count + 1));
			} else {
				list.push(obj[keys[i]]);
			}
		}
		return list;
	}
}
