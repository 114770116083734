import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class ReferralService {

	constructor(private api_adapter: Custom_api_calling) { }

	get_all_referrals(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/cc/user/get_customer_referral_list?" + query_data, (result) => callback(result));

	}

	get_all_referral(data, callback) {
		console.log("dhhhchchchycyhcyh", data);
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_referral_list?" + query_data, (result) => callback(result));
	}
	get_all_product_referral(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_product_referral_list?" + query_data, (result) => callback(result));
	}
	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}
	get_search_list(data, callback) {
		console.log("dhhhchchchycyhcyh", data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_search_list?" + data, (result) => callback(result));
	}
}
