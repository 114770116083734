import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class BaseConfigurationService {



  constructor(private api_adapter: Custom_api_calling) { }

  create_config(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/base_configuration/add_base_configuration", data.body, (result) => callback(result));
  }

  update_config_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/base_configuration/update_status", data.body, (result) => callback(result));
  }

  update_config_data(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/base_configuration/update_config_data", data.body, (result) => callback(result));
  }

  update_rules_engine(api, callback) {
    this.api_adapter.re_http_get(api, callback);
  }

  create_redemption_config(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/base_configuration/add_redemption_base_configuration", data.body, (result) => callback(result));
  }

  update_redemption_config_data(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/base_configuration/update_redemption_config_data", data.body, (result) => callback(result));
  }

  get_config_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/base_configuration/get_config_list?" + query_data, (result) => callback(result));
  }

  get_reward_type_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/base_configuration/get_reward_type_list?" + query_data, (result) => callback(result));
  }

  get_reward_mechanism_type_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/base_configuration/get_reward_mechanism_type_list?" + query_data, (result) => callback(result));
  }


  config_by_id(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/base_configuration/get_config_by_id?' + query_data, (result) => callback(result));
  }

  redemption_config_by_id(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/base_configuration/get_redemption_config_by_id?' + query_data, (result) => callback(result));
  }

  fetchPointData(callback) {
    this.api_adapter.http_calling_get("api/tenant/point_type/get_point_type_list",
      result => callback(result)
    );
  }

  fetchPointLabel(callback) {
    this.api_adapter.http_calling_get("api/tenant/point_type/get_point_label",
      result => callback(result)
    );
  }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }
}
