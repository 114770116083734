import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormComponentStruct } from '../../libraries/form-component-struct';
import { PointTypeMasterService } from '../../point_type_master/point-type-master.service';
import { CustomValidators } from '../../constants/CustomValidators';
import { FlashMessageService } from '../../libraries/flash-message/services/flash-message.service';
import { CustomRoutes } from '../../custom-routes';
import { LanguageService } from '../../language-master/language.service';
import { PointTypeLanguage } from '../models/point-type-languages';

@Component({
	selector: 'app-point-type-form',
	templateUrl: './point-type-form.component.html',
	styleUrls: ['./point-type-form.component.css']
})
export class PointTypeFormComponent extends FormComponentStruct implements OnInit {
	pointTypeForm: FormGroup;
	pointTypeFormError: string = '';
	pointTypeFormErrorClass: string = '';
	pointTypeId: number;
	yesNoDropdown = [
		{ id: 1, itemName: "Yes" },
		{ id: 0, itemName: "No" },
	]
	statusDropdown = [
		{ id: 1, itemName: "Active" },
		{ id: 0, itemName: "Inactive" },
	];
	loader_status: boolean = true;
	routes = CustomRoutes.routes;
	pointTypeLanguages: PointTypeLanguage[] = [];
	point_name_valid: any = {};
	point_label_valid: any = {};
	errorFlag: boolean = false;
	@Output() processSuccess: EventEmitter<any> = new EventEmitter();
	constructor(private languageService: LanguageService, private flashMessageService: FlashMessageService, private fb: FormBuilder, private api_adapter: Custom_api_calling, private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private pointTypeService: PointTypeMasterService) {
		super({
			add: 'addPointType',
			edit: 'editPointType'
		});

		this.pointTypeForm = this.fb.group({
			'point_type_name': ([null, Validators.compose([Validators.required, CustomValidators.nameField])]),
			'point_type_code': ([null, Validators.compose([Validators.required, CustomValidators.nameField])]),
			'point_type_label': ([null, Validators.compose([Validators.required, CustomValidators.nameField])]),
			// 'is_transferable': [null,Validators.required],
			'point_expiry': ([null, Validators.compose([Validators.required, CustomValidators.number_field])]),
			'status': [null, Validators.required]
		});
	}

	ngOnInit() {
		if (this._formType == 'edit') {
		} else {
			this.loader_status = false;
			this.pointTypeForm.reset();
		}
		let routeSubscription = this.activatedRoute.params.subscribe((params) => {
			this.pointTypeId = params.id;
		});
		if (this._formType == 'edit')
			this.loader_status = true;
		if (this._formType == 'edit' && this.pointTypeId != undefined && this.pointTypeId != null && this.pointTypeId != 0) {
			this.loader_status = true;
			this.api_adapter.http_calling_file_get('api/tenant/point_type/get_point_type_id/' + this.pointTypeId,
				(result) => {
					this.loader_status = false;
					if (result) {
						this.pointTypeForm.patchValue({
							point_type_name: result['values']['data'][0].point_type_name,
							point_type_code: result['values']['data'][0].point_type_code,
							point_type_label: result['values']['data'][0].point_type_label,
							point_expiry: result['values']['data'][0].point_expiry,
							status: result['values']['data'][0].status,
						});
						this.pointTypeForm.controls['point_type_code'].disable();
						this.pointTypeLanguages = result['values'].point_type_languages;
						this.loader_status = false;
					}
				});
		} else {
			this.languageService.get_all_languages((result) => {
				let data = result['values'];
				data.forEach(element => {
					this.pointTypeLanguages.push(new PointTypeLanguage({ language_name: element['language_name'], language_code: element['language_code'] }));
				});
			})
		}
	}

	point_name_validation() {
		this.point_name_valid = {};
		this.point_label_valid = {};

		this.errorFlag
		for (let i = 0; i < this.pointTypeLanguages.length; i++) {
			this.point_name_valid['err' + i] = null;
			this.errorFlag = false;

			if (this.pointTypeLanguages[i]['point_type_name'] == undefined || this.pointTypeLanguages[i]['point_type_name'] == '' || this.pointTypeLanguages[i]['point_type_name'] == null) {
				this.point_name_valid['err' + i] = 'This field is Required'
				this.errorFlag = true;
			}
			if (this.pointTypeLanguages[i]['point_type_label'] == undefined || this.pointTypeLanguages[i]['point_type_label'] == '' || this.pointTypeLanguages[i]['point_type_label'] == null) {
				this.point_label_valid['err' + i] = 'This field is Required'
				this.errorFlag = true;
			}
		}
	}
	addPointType(post) {
		this.loader_status = true;
		this.point_name_validation();
		if (this.userService.isFormValid(this.pointTypeForm) && !this.errorFlag) {
			let data;
			data = {};
			data['point_type_name'] = post.point_type_name;
			data['point_type_code'] = post.point_type_code;
			data['point_type_label'] = post.point_type_label;
			data['point_expiry'] = post.point_expiry;
			data['status'] = post.status;
			data['point_type_languages'] = this.pointTypeLanguages;
			if (post.point_expiry == 0) {
				this.flashMessageService.showMessage('0 is not valid', "alert alert-danger");
			}

			else {

				this.api_adapter.http_calling_file_post('api/tenant/point_type/add_point_type', data,
					(result) => {
						this.loader_status = false;
						if (result['status'] === true) {
							this.processSuccess.emit();
							this.flashMessageService.showMessage(result.message, "alert alert-success");
						} else {
							this.pointTypeFormError = this.userService.displayErrors({
								errors: result.errors,
								message: result.message
							});
							this.flashMessageService.showMessage(result.message, "alert alert-danger");
						}
					}
				);
			}

		}
	}

	editPointType(post) {
		this.loader_status = true;
		this.point_name_validation();
		if (this.userService.isFormValid(this.pointTypeForm)) {
			let form_data = {};
			form_data['id'] = this.pointTypeId;
			form_data['point_type_name'] = post.point_type_name;
			form_data['point_type_code'] = post.point_type_code;
			form_data['point_type_label'] = post.point_type_label;
			form_data['point_expiry'] = post.point_expiry;
			form_data['status'] = post.status;
			form_data['point_type_languages'] = this.pointTypeLanguages;
			if (post.point_expiry == 0) {
				this.flashMessageService.showMessage('0 is not valid', "alert alert-danger");
			}

			else {


				this.api_adapter.http_calling_json_put('api/tenant/point_type/edit_point_type/' + this.pointTypeId, form_data, (data) => {
					this.loader_status = false;
					if (data['status'] == true) {
						this.pointTypeFormErrorClass = 'error_success';
						this.processSuccess.emit();
						this.flashMessageService.showMessage(data.message, "alert alert-success");
					} else {
						this.pointTypeFormError = this.userService.displayErrors({
							errors: data.errors,
							message: data.message
						});
						this.flashMessageService.showMessage(data.message, "alert alert-danger");
						this.pointTypeFormErrorClass = 'error_fail';
					}
				})
			}
		}
	}

	ngOnDestroy() {
		//   this.userService.destroyModuleSubscriptions('PointTypeFormComponent');
	}

}
