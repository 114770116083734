import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { ApiService } from './../services/ApiService/api-service.service';
import {
	BehaviorSubject
} from 'rxjs';

@Injectable()
export class PushNotificationService {

	constructor(private customCallingApi: Custom_api_calling, private apiService: ApiService) { }
	emailTemplate = new BehaviorSubject([]);
	emailTemplates = new BehaviorSubject([]);
	activityList = new BehaviorSubject([]);
	tagLists = new BehaviorSubject([]);
	castTagLists = this.tagLists.asObservable();
	castActivityList = this.activityList.asObservable();
	castEmailTemplates = this.emailTemplates.asObservable();
	castEmailtemplate = this.emailTemplate.asObservable();
	sendPushNotification(data, callback) {
		this.customCallingApi.http_calling_file_post('api/tenant/notification/fire_push_notification', data, (result) => {
			callback(result);
		})

	}
	savePushNotification(data, callback) {
		this.customCallingApi.http_calling_json_put('api/tenant/notification/add_notification_template', data, (result) => {
			callback(result);
		})

	}

	addPushNotification(data, callback) {
		this.customCallingApi.http_calling_file_post('api/tenant/notification/add_notification_template', data, (result) => {
			callback(result);
		})
	}
	// loadPushNotification(data, callback) {

	//   let query_data = this.get_query_data(data);

	//   this.customCallingApi.http_calling_file_post('api/tenant/notification/get_notification_template',+ query_data, (result) => {
	//     callback(result);       
	// })
	// }
	loadPushNotification(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get('api/tenant/notification/get_notification_template?' + query_data, (result) => {
			callback(result);
		});
	}
	// fetchPushTemplate(emailTemplateId) {
	// 	let pushTemplaApi = 'api/tenant/notification/get_notification_template?id=' + emailTemplateId;
	// 	this.customCallingApi.http_calling_get(pushTemplaApi, (data) => {
	// 		console.log("fffffffffffff",data);
	// 		this.emailTemplate.next(data['values']["notification_template"][0]);
	// 	})
	// }
	
	fetchPushTemplate(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/notification/get_notification_template_by_id/' + data.push_id, callback);
	}

	fetchActivityList(data, callback) {
		let activityApi = 'api/tenant/activities/list?type=notification';
		this.customCallingApi.http_calling_get(activityApi, (result) => {
			//   this.activityList.next(data.values)
			callback(result);
		})
	}

	fetchTagList(activity_id: any) {
		let query_data = {};
		query_data["activity_id"] = activity_id;
		query_data["type"] = "notification";
		let activityApi = 'api/tenant/activities/get_all_tags';
		this.customCallingApi.http_calling_file_post(activityApi, query_data, (data) => {
			this.tagLists.next(data.values)
		})
	}
	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}

	change_push_template_status(data, callback) {
		this.customCallingApi.http_calling_file_put("api/tenant/notification_template/change_push_template_status", data.body, (result) => callback(result));
	}

	get_notification_configuration(data, callback) {
		this.customCallingApi.http_calling_get("api/tenant/notification_template/get_notification_configuration", (result) => callback(result));
	}
	add_update_notification_config(data, callback) {
		this.customCallingApi.http_calling_file_post("api/tenant/notification_template/add_update_notification_config", data.body, (result) => callback(result))
	}

	getSelectedActivityList(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/notification_template/get_selected_activity_list/' + data.notification_template_id, callback);
	}

	getLanguages(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get('api/tenant/rule_activity/getLanguages?' + query_data, callback);
	}
	getSelectedNotificationLanguages(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/notification_template/get_selected_notification_languages/' + data.push_notification_id, callback);
	}

}
