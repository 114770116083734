import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-popuplist',
    templateUrl: './popup-list.component.html',
    styleUrls: ['./popup-list.component.css']
})
export class PopupListComponent {
    
    commonMultiselectInfo: object = {
      list: true,
      list_arr: []
    };
    tagModal="";
    tagModal2="";
    constructor() {

    }
    @Input() data;
    @Input() title;
    @Input() idTag;

    input_text='';
    exclusionList;

    ngOnInit() {
      this.tagModal='#'+this.idTag+this.idTag;
      this.tagModal2=this.idTag+this.idTag;
    }

    commonMultiselectDetailsView(event: any) {
      this.commonMultiselectInfo['list_arr'] = event.map(item => {return {itemName:item.itemName}});
      this.popup_search()
      let btnElement: HTMLElement = document.getElementById(this.idTag) as HTMLElement;
      btnElement.click();
    }

    popup_search(){
      this.exclusionList = JSON.parse(JSON.stringify(this.commonMultiselectInfo['list_arr']));
      if(this.input_text)
      this.exclusionList = this.exclusionList.filter(({itemName}) => itemName.toLowerCase().includes(this.input_text.toLowerCase()));
    }
}


