import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
 import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnInit {
  toggleClockContent: boolean = false;

  @Input() preDateTimeData: any;
  @Output() dateTimeRes: EventEmitter<any> = new EventEmitter<any>();

  ngTimeObject = {
    hours: '',
    minutes: '',
    date: 'null',
    time: '',
    date_time: ''
  }

  minDateValidation: object = {
    isSelectedDtSame: false
  };
  constructor() { }

  ngOnInit() {
    if (this.preDateTimeData.hasOwnProperty('min')) {
      this.preDateTimeData['min'] = new Date(this.preDateTimeData['min']);
      this.ngTimeObject['date_time'] = '';
    }
    if (this.preDateTimeData.hasOwnProperty('max')) {
      this.preDateTimeData['max'] = new Date(this.preDateTimeData['max']);
    }
    if (this.preDateTimeData.hasOwnProperty('formType') && this.preDateTimeData['formType'] !== 'add') {
      let datePipe = new DatePipe("en-US");
      this.ngTimeObject['date_time'] = datePipe.transform(this.preDateTimeData['value'], 'yyyy-MM-dd');
      let time = datePipe.transform(this.preDateTimeData['value'], 'HH:mm');

      if (time.length > 1) {
        this.ngTimeObject['date'] = this.ngTimeObject['date_time'];
        this.ngTimeObject['time'] = time;
        this.ngTimeObject['date_time'] = this.ngTimeObject['date'] + ' ' + time;
        this.ngTimeObject['hours'] = time.split(':')[0];
        this.ngTimeObject['minutes'] = time.split(':')[1];
        this.timeChange();
      }
    }
  }

  dateChange(event) {
    let datePipe = new DatePipe("en-US");
    let selectedDate = datePipe.transform(event.value, 'yyyy-MM-dd');
    this.ngTimeObject['date'] = selectedDate;
    this.ngTimeObject['hours'] = '00'
    this.ngTimeObject['minutes'] = '00';
    this.ngTimeObject['time'] = '00:00';
    this.ngTimeObject['date_time'] = selectedDate.toString() + ' ' + '00:00';
    let min_date = datePipe.transform(this.preDateTimeData['min'], 'yyyy-MM-dd');

    if (min_date == selectedDate) {
      this.minDateValidation['isSelectedDtSame'] = true;
      let min_date_val = datePipe.transform(this.preDateTimeData['min'], 'yyyy-MM-dd HH:mm');

      let min_date_time = min_date_val.split(' ');
      this.minDateValidation['hours'] = parseInt(min_date_time[1].split(':')[0]);
      this.minDateValidation['minutes'] = parseInt(min_date_time[1].split(':')[1]);
      this.ngTimeObject['hours'] = this.minDateValidation['hours'] > 9 ? this.minDateValidation['hours'] : '0' + this.minDateValidation['hours'];
      this.ngTimeObject['minutes'] = parseInt(this.minDateValidation['minutes']) > 9 ? this.minDateValidation['minutes'] : '0' + parseInt(this.minDateValidation['minutes']);
      this.ngTimeObject['date_time'] = selectedDate.toString() + ' ' + this.ngTimeObject['hours'] + ':' + this.ngTimeObject['minutes'];

    } else {
      this.minDateValidation['isSelectedDtSame'] = false;
    }

    if (this.ngTimeObject['time'] !== '') {
      this.dateTimeRes.emit({ result: new Date(this.ngTimeObject['date_time']), status: true });
    }

  }

  timeChange() {
    setTimeout(() => {
      if (this.ngTimeObject['date'] && this.ngTimeObject['date'] !== 'null' && this.ngTimeObject['date'] !== '') {
        this.ngTimeObject['hours'] = this.ngTimeObject['hours'] !== '' ? this.ngTimeObject['hours'] : '00';
        this.ngTimeObject['minutes'] = this.ngTimeObject['minutes'] !== '' ? this.ngTimeObject['minutes'] : '00';
        this.ngTimeObject['time'] = this.ngTimeObject['hours'] + ':' + this.ngTimeObject['minutes'];
        this.ngTimeObject['date_time'] = this.ngTimeObject['date'] + ' ' + this.ngTimeObject['time'];
        this.dateTimeRes.emit({ result: new Date(this.ngTimeObject['date_time']), status: true });
      } else {
        this.ngTimeObject['date'] = '';
        this.ngTimeObject['time'] = '';
        this.ngTimeObject['hours'] = '';
        this.ngTimeObject['minutes'] = '';
      }
      this.toggleClockContent = false;
    }, 250);
  }

  timeValChange(event, format, keyType = 'NULL') {
    let eventValue = (keyType == 'NULL') ? event.target.value : event == '' ? 0 : event;

    if (format == 'HH') {
      if (keyType == 'NULL' && eventValue > 23) {
        this.ngTimeObject['hours'] = '0';
      } else if (keyType == 'UP') {
        this.ngTimeObject['hours'] = parseInt(eventValue) > 22 ? '00' : (parseInt(eventValue) + 10).toString();
      } else if (keyType == 'DOWN') {
        if (this.minDateValidation['isSelectedDtSame'] && parseInt(this.ngTimeObject['hours']) > this.minDateValidation['hours']) {
          this.ngTimeObject['hours'] = parseInt(eventValue) > 1 ? (parseInt(eventValue) - 1).toString() : '00';
        } else if (!this.minDateValidation['isSelectedDtSame']) {
          this.ngTimeObject['hours'] = parseInt(eventValue) > 1 ? (parseInt(eventValue) - 1).toString() : '00';
        }
      } else {
        this.ngTimeObject['hours'] = eventValue;
      }

      this.ngTimeObject['hours'] = parseInt(this.ngTimeObject['hours']) < 10 ? '0' + parseInt(this.ngTimeObject['hours']) : this.ngTimeObject['hours'];
    } else if (format == 'MM') {
      if (eventValue > 59 && keyType == 'NULL') {
        this.ngTimeObject['minutes'] = '0';
      } else if (keyType == 'UP') {
        this.ngTimeObject['minutes'] = parseInt(eventValue) > 58 ? '00' : (parseInt(eventValue) + 1).toString();
      } else if (keyType == 'DOWN') {
        if (this.minDateValidation['isSelectedDtSame']) {
          if (parseInt(this.ngTimeObject['hours']) > this.minDateValidation['hours']) {
            this.ngTimeObject['minutes'] = parseInt(eventValue) > 1 ? (parseInt(eventValue) - 1).toString() : '00';
          }
          else if (parseInt(this.ngTimeObject['hours']) == this.minDateValidation['hours'] && parseInt(this.ngTimeObject['minutes']) - 1 > this.minDateValidation['minutes']) {
            this.ngTimeObject['minutes'] = parseInt(eventValue) > 1 ? (parseInt(eventValue) - 1).toString() : '00';
          }
          else if (!this.minDateValidation['isSelectedDtSame']) {
            this.ngTimeObject['minutes'] = parseInt(eventValue) > 1 ? (parseInt(eventValue) - 1).toString() : '00';
          }
        } else {
          this.ngTimeObject['minutes'] = eventValue;
        }
      }

      this.ngTimeObject['minutes'] = parseInt(this.ngTimeObject['minutes']) < 10 ? '0' + parseInt(this.ngTimeObject['minutes']) : this.ngTimeObject['minutes'];
    }
  }

  onBlur(){}
  onFocus(){}
}
