import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class SettingsService {

  constructor(private customApiCalling: Custom_api_calling) { }

  get_customer_table_columns(callback) {
    this.customApiCalling.http_multi_url_get({
      'custom_fields': 'api/tenant/setting/get_setting?settings_name=dynamic_customer_fields',
      'customer_table_fields': 'api/tenant/customers/get_customer_table_columns',
      'customer_data': 'api/tenant/customers/get_customer_form_primary_data'
    }, callback);
  }

  add_customer_custom_fields(data, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/setting/add_setting", data, (result) => callback(result));
  }

  add_dynamic_customer(data, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/customers/add_customer", data, (result) => callback(result));
  }

  get_dynamic_customer_data(customer_id: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/setting/get_dynamic_customer_data?id=' + customer_id, (result) => callback(result));
  }

  // public get_customer_form_primary_data(){
  //   let customerAssignConsentApi: string = 'api/tenant/customers/get_customer_form_primary_data';
  //   this.customCallingApi.http_calling_get(customerAssignConsentApi, (result) => {
  //       this.customerPrimaryDataConsent.next(result);
  //   })
  // }
  add_tp_application(data, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/setting/add_tp_application", data, (result) => callback(result));
  }
  edit_tp_application(data, callback) {
    console.log("dffgdfdf", data);
    this.customApiCalling.http_calling_file_put("api/tenant/setting/edit_tp_application", data, (result) => callback(result));
  }
  get_tp_application_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/setting/get_tp_application_list?" + query_data, (result) => callback(result));
  }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }
  get_tp_application(tpRegistration_id: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/setting/get_tp_application?id=' + tpRegistration_id, (result) => callback(result));
  }
  tp_application_status_change(data, callback) {
    this.customApiCalling.http_calling_json_put('api/tenant/setting/tp_application_status_change', data, (result) => callback(result));
  }

  get_lock_point_list(data, callback) {
    this.customApiCalling.http_calling_get("api/tenant/setting/get_lock_point_list?" + data, (result) => callback(result));
  }

  get_setting_list(data, callback) {
    this.customApiCalling.http_calling_get("api/tenant/setting/get_setting_list?" + data, (result) => callback(result));
  }
  // lock_point_status_change(data, callback){
  //   this.customApiCalling.http_calling_json_put('api/tenant/setting/tp_application_status_change',data, (result) => callback(result));
  //  }
};
