import { Injectable } from '@angular/core';

@Injectable()
export class Common_methods{
    constructor(){

    }

    public static return_dropdown_values(source, destination, key,readKey="name") {
        return source.filter(function (obj) {
            return (destination.indexOf(obj[key]) > -1)
        }).map((ob) => {
            return {id: ob.id, itemName: ob[readKey]}
        });
    }

    public static get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if(data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]]!= undefined)
            query_data += (query_data === ""  ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }
}
