import { Injectable } from "@angular/core";
// import xlsx from 'xlsx';
import { Custom_api_calling } from "../adapters/Custom_api_calling";
import { Common_methods } from "../libraries/Common_methods";

import { ApiService } from '../../app/services/ApiService/api-service.service';

@Injectable()
export class RewardEngineService {
  constructor(private api_adapter: Custom_api_calling,private apiService: ApiService) {}

  addRule(body, callback) {
    this.api_adapter.http_calling_post(
      "api/tenant/behaviour_rules/add_rule",
      body,
      (result: any[]) => {
        callback(result);
      }
    );
  }

  updateRule(rule_id, body, callback) {
    this.api_adapter.http_calling_put(
      `api/tenant/behaviour_rules/update_rule/${rule_id}`,
      body,
      (result: any[]) => callback(result)
    );
  }

  uploadFilecheck(data, callback) {
    const api = "api/tenant/behaviour_rules/upload_excluded_customer_file";
    this.api_adapter.http_calling_file_post(api, data.body, callback);
  }

  uploadFilecheckAttribute(data, callback) {
    const api = "api/tenant/behaviour_rules/upload_excluded_attribute_file";
    this.api_adapter.http_calling_file_post(api, data.body, callback);
  }

  get_rule_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get(
      "api/tenant/behaviour_rules/rule_list?" + query_data,
      result => callback(result)
    );
  }

  getRule(rule_id, callback) {
    this.api_adapter.http_calling_get(
      `api/tenant/behaviour_rules/get_rule/${rule_id}`,
      result => callback(result)
    );
  }

  update_rule_status(data, callback) {
    this.api_adapter.http_calling_file_put(
      "api/tenant/behaviour_rules/update_status",
      data.body,
      result => callback(result)
    );
  }

  getRoundOffRule(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get(
      "api/tenant/behaviour_rules/get_roundoff_rule?" + query_data,
      result => callback(result)
    );
  }

  updateRoundOffRule(rule_id, body, callback) {
    this.api_adapter.http_calling_put(
      `api/tenant/behaviour_rules/update_roundoff_rule/${rule_id}`,
      body,
      (result: any[]) => callback(result)
    );
  }

  addRoundOffRule( body, callback) {
    this.api_adapter.http_calling_post(
      `api/tenant/behaviour_rules/add_roundoff_rule`,
      body,
      (result: any[]) => callback(result)
    );
  }

  update_rules_engine(api, callback) {
    this.api_adapter.re_http_get(api, callback);
  }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (
        data[keys[i]] !== "" &&
        data[keys[i]] != null &&
        data[keys[i]] != undefined
      )
        query_data +=
          query_data === ""
            ? keys[i] + "=" + data[keys[i]]
            : "&" + keys[i] + "=" + data[keys[i]];
    }
    return query_data;
  }

  fetchPointData(callback) {
    this.api_adapter.http_calling_get("api/tenant/point_type/get_point_type_list",
      result => callback(result)
    );
  }

  fetchPointLabel(callback) {
    this.api_adapter.http_calling_get("api/tenant/point_type/get_point_label",
      result => callback(result)
    );
  }


  getActivity(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/rule_activity/getActivity?' + query_data, callback);
  }

  getSubActivity(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/rule_activity/getSubActivity/?' + query_data, callback);
  }
}
