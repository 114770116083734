import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';


@Injectable()
export class CampaignService {

    constructor(private apiAdapter: Custom_api_calling) { }

    /*** 
     * Description: this function is sending get request for campaigns list with query parameter 
     * Params: Objects which need to pass as query parameter in api
     * Return: Object containing all campaigns records.
     * Author: Sharadchandra
    */
    get_campaign_list(data, callback) {
        let queryData = this.get_query_data(data);
        this.apiAdapter.http_calling_get("api/tenant/referralCampaign/getReferralCampaignList?" + queryData, (result) => {
            callback(result);
        });
    }

    get_query_data(data) {
        let queryData = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
            queryData += (queryData === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return queryData;
    }

    /*** 
     * Description: this function is sending get request for get specific campaign on the basis of campaign id.
     * Params: Objects which need to pass as query parameter in api.
     * Returns: Object containing campaign record.
     * Auther: Sharadchandra
    ***/
    fetch_campaign(params,callback){
        this.apiAdapter.http_calling_get('api/tenant/referralCampaign/getReferralCampaign/' + params,(result) => {
            callback(result);
        });
    }

}
