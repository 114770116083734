import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-pop-up',
  templateUrl: './confirmation-pop-up.component.html',
  styleUrls: ['./confirmation-pop-up.component.css']
})
export class ConfirmationPopUpComponent implements OnInit {
  @ViewChild('confirmationModal', {static: true}) confirmationModal: ElementRef;

  @Input() preModalData: any;
  @Output() confirmationModalRes: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (this.preModalData['click']) {
      this.confirmationModal.nativeElement.click();
    }
  }

  confirm(event) {
    this.confirmationModalRes.emit(event);
  }
}
