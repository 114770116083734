import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormComponentStruct } from '../../libraries/form-component-struct';
import { ProgramService } from '../program.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-tenant-program-form',
  templateUrl: './tenant-program-form.component.html',
  styleUrls: ['./tenant-program-form.component.css']
})
export class TenantProgramFormComponent extends FormComponentStruct implements OnInit {
    programForm:FormGroup;
    programFormErrorClass:string = '';
    programFormError:string = '';
    programId:number = 0;
    countries:any[] = [];
    currencies:any[] = [];
    groupCodes:any[] = [];
    @Output() processSuccess : EventEmitter<any> = new EventEmitter();
    programImage:any;
    constructor(private router:Router,private programService : ProgramService,private userService:UserService) {
        super({
            add : 'addProgram',
            edit : 'editProgram',
        })
    }

    ngOnInit() {
        this.programForm = new FormGroup(
        {
            program_name: new FormControl("", Validators.compose([
            // Validators.minLength(6),
            Validators.required
            ])),
            program_description: new FormControl("",Validators.required),
            start_date:new FormControl("",Validators.required),
            end_date:new FormControl("",Validators.required),
            program_image : new FormControl("",Validators.required),
        });
        console.log(this.formType);
        this.programService.getProgramFormData((data) =>{
            let program = (data['program']['status']) ? data['program']['values'][0] : [];
            this.groupCodes = (data['group_codes']) ? data['group_codes']['values'] : [];
            this.countries.push(program.country_name);
            this.groupCodes.map((code) => {
                let groupCode = code;
                groupCode['assigned'] = program.group_codes.split(',').indexOf(code.name) > -1;
                return groupCode;
            })
            this.programForm.patchValue({
                program_name: program.program_name,
                program_description: program.program_description,
                start_date: program.start_date.split('T')[0] || null,
                end_date:program.end_date.split('T')[0] || null,
            });

            if(program['program_id'] != null){
                this.programId = program['program_id'];
                this.formType = 'edit';
            }

        });
    }

    onFileChange(files) {
        let file = files[0];
        this.programForm.controls['program_image'].setValue(file ? file.name : '');
        this.programImage = file;
   }

    addProgram(form_data) {
        form_data['program_image'] = this.programImage;
        this.programService.addProgram( form_data,
            (result) => {
                if (result['status'] === true) {
                    this.processSuccess.emit();
                } else {
                    this.programFormError = this.userService.displayErrors({
                        errors: result.errors,
                        message: result.message
                    });
                }
            }
        );
    }

    editProgram(form_data) {
        form_data['program_id'] = this.programId.toString();
        form_data['program_image'] = this.programImage;
        this.programService.editProgram(form_data, (result) => {
            if (result['status'] == true) {
                this.programFormErrorClass = 'error_success';
                this.processSuccess.emit();
            } else {
                this.programFormError = this.userService.displayErrors({
                    errors: result.errors,
                    message: result.message
                });
                this.programFormErrorClass = 'error_fail';
            }
        })
    }
}
