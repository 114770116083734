import { Component, OnInit, Output, EventEmitter, Input, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';

declare let $: any;

@Component({
  selector: 'app-rule-query',
  templateUrl: './rule-query.component.html',
  styleUrls: ['./rule-query.component.css']
})
export class RuleQueryComponent implements OnInit {

  html_id:any
  rule_filter = {
    'tier_retention_filter' : [
      /* {
      id: 'voucher_purchased',
      label: 'Voucher Purchased',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater']
    }, {
      id: 'voucher_redeemed',
      label: 'Voucher Redeemed',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    }, {
      id: 'product_purchased',
      label: 'Product Purchased',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    }, {
      id: 'offer_redeemed',
      label: 'Offers Redeemed',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    }, {
      id: 'time_period',
      label: 'Time Period',
      type: 'string',
      input: 'select',
      values : ['1_month','3_months','6_months','1_year','all_time'],
      operators: ['greater_or_equal', 'less','less_or_equal','greater'] ,

    }
    , {
      id: 'point_redeemed',
      label: 'Points Redeemed',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    }
    , {
      id: 'point_accrued',
      label: 'Points Accrued',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    }
    ,  */{
      id: 'transaction_value',
      label: 'Transaction Value',
      type: 'double',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    }/* 
    , {
      id: 'transaction_frequency',
      label: 'Transaction Frequency',
      type: 'integer',
      operators: ['equal', 'greater_or_equal', 'less','not_equal','less_or_equal','greater'] ,
    } */
  ]
  }

  @Output() processForm:EventEmitter<any> = new EventEmitter();

  getRuleData(){
    this.processForm.emit($('#'+this.html_id).queryBuilder('getRules'))
    return $('#'+this.html_id).queryBuilder('getRules');
  };

  //create dom ele tag
  @ViewChild('html_tag_id', {static: true})  private html_tag_id: ElementRef;

  constructor(private userService:UserService, private renderer:Renderer2) {

  }

  ngOnInit() {

   }

  loadScriptFromParent(tag_id:string,rules:any,callback){
    this.userService.loadScripts(['./assets/build/js/query-builder.standalone.min.js'],() => {
      callback()
      this.initQueryBuilder(tag_id,rules);
    });
  }


  initQueryBuilder(tag_id:string,rules?:any){
      //create element
        const li = this.renderer.createElement('div');
        this.renderer.setAttribute(li, "id", tag_id);
        this.renderer.appendChild(this.html_tag_id.nativeElement, li);
        this.html_id = tag_id;
      
        if(typeof $('#'+tag_id).queryBuilder == 'undefined')
            return;
        
        $('#'+tag_id).queryBuilder({
        // plugins: ['bt-tooltip-errors'],
          filters: this.rule_filter['tier_retention_filter'],
        });
    
       
  }

  loadQueryRule(tag_id,rules){
    // console.log("rulesrulesrulesrulesrulesrules : ",rules,tag_id);
    if(typeof rules != 'undefined' && rules != null && rules != '') 
      $('#'+tag_id).queryBuilder('setRules', JSON.parse(rules));
  }

}
