import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { Constants } from '../constants/constants';


@Injectable()
export class ReportsService {

  constructor(private customApiCalling: Custom_api_calling) { }

  add_dynamic_table_for_reports(data, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/reports/add_report_setting", data, (result) => callback(result));
  }

  fetch_dynamic_report_tables_settings(data: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/reports/get_setting?settings_name=' + data.settings, (result) => callback(result));
  }

  fetch_table_columns(data: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/reports/get_dynamic_report_columns_by_table?table_name=' + data.table, (result) => callback(result));
  }


  //extra code remove after report
  get_dynamic_report_data_by_tables(data: any, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/reports/get_dynamic_report_data_by_tables", data, (result) => callback(result));
  }

  save_custom_columns_info(data: any, callback) {
    this.customApiCalling.http_calling_file_put("api/tenant/reports/save_custom_columns_info", data, (result) => callback(result));
  }



  /* new report code */


  fetchAllTables(data: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/reports/get_all_tables_from_db', (result) => callback(result));
  }

  save_dynamic_report_settings(data, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/reports/create_dynamic_report_settings", data, (result) => callback(result));
  }

  fetch_dynamic_report_tables(data: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/reports/get_all_report_by_user_type?user_type=tenant', (result) => callback(result));
  }

  fetch_dynamic_report_setting_by_id(data: any, callback) {
    this.customApiCalling.http_calling_get('api/tenant/reports/fetch_dynamic_report_setting_by_id?id=' + data.id + "&&user_type=" + data.user_type, (result) => callback(result));
  }

  create_dynamic_report(data: any, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/reports/create_dynamic_report_by_user", data, (result) => callback(result));
  }

  exportToExcelReport(data: any, callback) {
    this.customApiCalling.http_calling_file_post("api/tenant/reports/export_report_in_excel", data, (result) => callback(result));
  }


  get_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_report_list?" + query_data, (result) => callback(result));
  }


  // get_reports(data, callback) {
  //   console.log("data",data);
  //   let query_data = this.get_query_data(data);
  //   var xhttp = new XMLHttpRequest();
  //   if (data.export_excel == 1) {
  //     xhttp.open("GET", Constants.API_URL + "api/tenant/point/get_accrued_point?" + query_data, true);
  //     xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  //     xhttp.setRequestHeader("Access-Control-Allow-Headers", "Content-Type");
  //     xhttp.setRequestHeader("API_TOKEN", Constants.API_TOKEN);
  //     xhttp.setRequestHeader("USER_TOKEN", Constants.USER_TOKEN);
  //     xhttp.setRequestHeader("USER_AGENT", "Website");
  //     xhttp.responseType = 'blob'
  //     xhttp.send();
  //     xhttp.onreadystatechange = function (data) {
  //       if (this.readyState == 4 && this.status == 200) {
  //         callback(this.response);
  //       }
  //     };
  //   }
  //   else {
  //     this.customApiCalling.http_calling_get('api/tenant/reports/get_accrual_report?' + query_data, (result) => callback(result));

  //   }
  // }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }

  exportReport(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get('api/tenant/reports/get_report_list?' + query_data, (result) => {
      callback(result)
    });
  }

  get_member_type(callback) {
    // let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get('api/tenant/reports/get_member_type?', (result) => {
      callback(result)
    });
  }

  /* *****************new report code start******************** */

  // all_number_from_tier(data,callback) {
  //   let query_data = this.get_query_data(data);
  //   console.log('tierrrrworkhorhahaiii2' )
  //   this.customApiCalling.http_calling_get('api/tenant/reports/get_all_number_from_tier?', (result) => {
  //     callback(result)
  //   });
  // }


  all_number_from_tier(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_all_number_from_tier?" + query_data,  callback);
      //  callback(result);
  }



  /* *****************new report code en******************** */

  get_friend_family_report(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_friend_family_report?" + query_data, (result) => callback(result));
  }

  exportFriendFamilyReport(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get('api/tenant/reports/get_friend_family_report?' + query_data, (result) => {
      callback(result)
    });
  }

  getCustomReportsList(callback) {
    this.customApiCalling.http_calling_get('api/tenant/reports/get_custom_reports', result => callback(result));
  }

  generateReport(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get(data.url + '?' + query_data, result => callback(result));
  }

  get_accrual_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_accrual_report_list?" + query_data, (result) => callback(result));
  }

  get_merchant_payable_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_merchant_payable_report_list?" + query_data, (result) => callback(result));
  }

  get_accrual_report_details(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_accrual_report_details?" + query_data, (result) => callback(result));
  }
  get_accrual_report_details_calendar(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_accrual_report_details_by_date?" + query_data, (result) => callback(result));
  }

  get_accrual_report_details_by_months(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_accrual_report_details_by_months?" + query_data, (result) => callback(result));
  }

  get_accrual_report_merchant_details(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_accrual_report_merchant_details?" + query_data, (result) => callback(result));
  }

  get_accrual_report_merchant_details_by_volume(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_accrual_report_merchant_details_by_volume?" + query_data, (result) => callback(result));
  }

	get_customer_report_details(data, callback) {
		let query_data = this.get_query_data(data);
		this.customApiCalling.http_calling_get("api/tenant/reports/get_customer_report_details?" + query_data, (result) => callback(result));
	 }
	 
	 get_customer_report_details_calendar(data, callback) {
		let query_data = this.get_query_data(data);
		this.customApiCalling.http_calling_get("api/tenant/reports/get_customer_report_details_by_date?" + query_data, (result) => callback(result));
 	}

  get_customer_report_monthly_details(data, callback) {
		let query_data = this.get_query_data(data);
		this.customApiCalling.http_calling_get("api/tenant/reports/get_customer_report_monthly_details?" + query_data, (result) => callback(result));
  }

  get_customer_report_sms_details(data, callback) {
		let query_data = this.get_query_data(data);
		this.customApiCalling.http_calling_get("api/tenant/reports/get_customer_report_sms_details?" + query_data, (result) => callback(result));
  }

  get_customer_report_email_details(data, callback) {
		let query_data = this.get_query_data(data);
		this.customApiCalling.http_calling_get("api/tenant/reports/get_customer_report_email_details?" + query_data, (result) => callback(result));
  }
  
  get_redemption_report_details(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_redemption_report_details?" + query_data, (result) => callback(result));
  }

  get_redemption_report_details_calendar(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_redemption_report_details_by_date?" + query_data, (result) => callback(result));
  }
  get_monthly_redemption_report_details(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_monthly_redemption_report_details?" + query_data, (result) => callback(result));
  }

  get_redemption_report_top_merchant(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_redemption_report_top_merchant?" + query_data, (result) => callback(result));
  }

  get_data_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_customers_reports_data?" + query_data, (result) => callback(result));
  }

  get_referral_app_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_referral_app_list?" + query_data, (result) => callback(result));
  }
  get_referral_product_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_referral_product_list?" + query_data, (result) => callback(result));
  }

  get_member_statment_data(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/fetch_or_export_member_statement_report?" + query_data, (result) => callback(result));
  }

  get_overall_points_data(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/overall_points_report?" + query_data, (result) => callback(result));

  }

  get_redemption_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_redemption_report_list?" + query_data, (result) => callback(result));
  }

  get_offer_redemption_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_offer_redemption_report_list?" + query_data, (result) => callback(result));
  }

  get_reward_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_reward_report_list?" + query_data, (result) => callback(result));
  }

  get_account_info_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_account_info_reports?" + query_data, (result) => callback(result));
  }

  get_all_activity(data, callback) {
	let query_data = this.get_query_data(data);
	this.customApiCalling.http_calling_get("api/tenant/reports/get_all_activity?" + query_data, (result) => callback(result));
  }

  get_all_campaigns(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_all_campaigns?" + query_data, (result) => callback(result));
  }

  get_point_expiry_reports(data, callback) {
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/tenant/reports/get_point_expiry_reports?" + query_data, (result) => callback(result));
  }

  get_invoice_reports(data,callback){
    let query_data= this.get_query_data(data)
    this.customApiCalling.http_calling_get("api/tenant/reports/get_invoice_report_list?" + query_data, (result) => callback(result))
  }
  get_consecutive_days_reports(data,callback){
    let query_data= this.get_query_data(data)
    this.customApiCalling.http_calling_get("api/tenant/reports/get_consecutive_days_report_list?" + query_data, (result) => callback(result))
  }
  get_offer_type_list(data,callback){
    let query_data = this.get_query_data(data);
    this.customApiCalling.http_calling_get("api/admin/offer_type/list?" + query_data, (result) => callback(result));
  }
  get_offer_issurance_reports(data,callback){
    let query_data= this.get_query_data(data)
    this.customApiCalling.http_calling_get("api/tenant/reports/get_offer_issurance_report_list?" + query_data, (result) => callback(result))
  }
  get_logout_reports(data, callback) {
	let query_data = this.get_query_data(data);
	this.customApiCalling.http_calling_get("api/tenant/reports/get_logout_report_list?" + query_data, (result) => callback(result));
  }
  get_point_expiry_list(data, callback) {
 	let query_data = this.get_query_data(data);
 	this.customApiCalling.http_calling_get("api/tenant/reports/get_point_expiry_list?" + query_data, (result) => callback(result));
  }
  get_upgrade_downgrade_list(data, callback) {
 	let query_data = this.get_query_data(data);
 	this.customApiCalling.http_calling_get("api/tenant/reports/get_upgrade_downgrade_list?" + query_data, (result) => callback(result));
  }
  get_current_upgrade_downgrade_list(data, callback) {
	let query_data = this.get_query_data(data);
	this.customApiCalling.http_calling_get("api/tenant/reports/get_current_upgrade_downgrade_list?" + query_data, (result) => callback(result));
 }

 get_tier_downgrade_list(data, callback) {
	let query_data = this.get_query_data(data);
	this.customApiCalling.http_calling_get("api/tenant/reports/get_tier_downgrade_list?" + query_data, (result) => callback(result));
 }

 get_deleted_members_list(data, callback) {
  // console.log('000get_deleted_members_list',data);
	let query_data = this.get_query_data(data);
	this.customApiCalling.http_calling_get("api/tenant/reports/get_deleted_members_list?" + query_data, (result) => callback(result));
 }

 get_monthly_rewards_reports(data, callback) {
  let query_data = this.get_query_data(data);
  this.customApiCalling.http_calling_get("api/tenant/reports/get_monthly_rewards_list?" + query_data, (result) => callback(result));
}
get_scratch_win_reports(data, callback) {
  let query_data = this.get_query_data(data);
  this.customApiCalling.http_calling_get("api/tenant/reports/get_scratch_win_campaign_list?" + query_data, (result) => callback(result));
}
get_evoucher_expiry_reports(data, callback) {
  let query_data = this.get_query_data(data);
  this.customApiCalling.http_calling_get("api/tenant/reports/get_voucher_campaign_list?" + query_data, (result) => callback(result));
}
get_promotional_reports(data, callback) {
  let query_data = this.get_query_data(data);
  this.customApiCalling.http_calling_get("api/tenant/reports/get_promotional_list?" + query_data, (result) => callback(result));
}

get_promotional_reports_email_data(data, callback) {
  let query_data = this.get_query_data(data);
  this.customApiCalling.http_calling_get("api/tenant/reports/get_promotional_reports_email_data?" + query_data, (result) => callback(result));
}
get_offer_expiry_reports(data,callback){
    let query_data= this.get_query_data(data)
    this.customApiCalling.http_calling_get("api/tenant/reports/get_offer_expiry_report_list?" + query_data, (result) => callback(result))
  }
get_point_expired_list(data, callback) {
	let query_data = this.get_query_data(data);
	this.customApiCalling.http_calling_get("api/tenant/reports/get_point_expired_list?" + query_data, (result) => callback(result));
 }
}
