import {
    Component,
    AfterViewInit,
    EventEmitter,
    OnDestroy,
    Input,
    Output
} from '@angular/core';

//import 'tinymce';
// import 'tinymce/themes/modern';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/image';
import 'tinymce/plugins/colorpicker';
import { Constants } from '../constants/constants';

declare var tinymce: any;

@Component({
    selector: 'app-tinyeditor',
    template: `<textarea id="{{elementId}}"></textarea>`
})
export class TinyEditorcomponent implements AfterViewInit, OnDestroy {
    @Input() elementId: String;
    @Output() onEditorContentChange = new EventEmitter();

    editor;

    ngAfterViewInit() {
        tinymce.init({
            selector: '#' + this.elementId,
            plugins: ['link', 'table', 'paste', 'code', 'textcolor', 'colorpicker', 'image'],
            toolbar: 'code | bold italic strikethrough forecolor backcolor | link | image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | fontsizeselect | fontselect | removeformat',
            skin_url: Constants.BASE_URL+'assets/skins/lightgray',
            height: "200",
            branding: false,
            // entity_encoding : "named",
            // readonly : true,
            setup: editor => {

                this.editor = editor;
                editor.on('init', () => {
                    editor.execCommand("fontName", false, "Arial");
                    editor.execCommand("fontSize", false, '12pt');
                });
                editor.on('keyup change', () => {
                    const content = editor.getContent();
                    this.onEditorContentChange.emit(content);
                });
            }
        });
    }

    setcontent(data) {
        tinymce.activeEditor.setContent(data);
    }

    getPlainText() {
        return tinymce.activeEditor.getBody().textContent;
    }

    setMenu(menuItems) {
        console.log(menuItems);
        let setMenu = [];
        tinymce.remove();
        // var editer = this;
        // tinymce.activeEditor.insertContent('&nbsp;<b>It\'s my first button!</b>&nbsp;');
        if (menuItems.length == 0) {
            tinymce.init({
                selector: '#' + 'myoffereditor',
                plugins: ['link', 'table', 'paste', 'code', 'textcolor', 'colorpicker', 'image'],
                toolbar: 'code | bold italic strikethrough forecolor backcolor | link | image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | fontsizeselect | fontselect | removeformat',
                skin_url: Constants.BASE_URL+'assets/skins/lightgray',
                height: "200",
                // entity_encoding : "named",
                // readonly : true,
                setup: editor => {

                    this.editor = editor;
                    editor.on('init', () => {
                        editor.execCommand("fontName", false, "Arial");
                        editor.execCommand("fontSize", false, '12pt');
                    });
                    editor.on('keyup change', () => {
                        const content = editor.getContent();
                        this.onEditorContentChange.emit(content);
                    });
                }
            });
        } else {
            menuItems.forEach(item => {
                console.log(item.tag_name);
                var text = "&nbsp;<span> {{ " + item.tag_name + " }} <span>&nbsp;";
                setMenu.push({
                    text: item.tag_name,
                    value: text
                });
            });
            console.log(setMenu);
            tinymce.init({
                selector: '#' + 'myoffereditor',
                plugins: ['link', 'table', 'paste', 'code', 'textcolor', 'colorpicker', 'image'],
                toolbar: 'code | bold italic strikethrough forecolor backcolor | link | image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | fontsizeselect | fontselect | removeformat',
                skin_url: Constants.BASE_URL+'assets/skins/lightgray',
                height: "200",
                // entity_encoding : "named",
                // readonly : true,
                setup: editor => {

                    this.editor = editor;
                    editor.on('init', () => {
                        editor.execCommand("fontName", false, "Arial");
                        editor.execCommand("fontSize", false, '12pt');
                    });
                    editor.on('keyup change', () => {
                        const content = editor.getContent();
                        this.onEditorContentChange.emit(content);
                    });
                }
            });
            tinymce.activeEditor.addButton('tags', {
                text: 'Tags',
                type: 'listbox',
                icon: false,
                onselect: function (e) {
                    tinymce.activeEditor.insertContent(this.value());
                },
                values: setMenu
            });
            var button = tinymce.activeEditor.buttons['tags'];

            // //find the buttongroup in the toolbar found in the panel of the theme
            var bg = tinymce.activeEditor.theme.panel.find('toolbar buttongroup')[0];
            // console.log(bg);
            // //without this, the buttons look weird after that
            bg._lastRepaintRect = bg._layoutRect;

            // //append the button to the group
            bg.append(button);
        }
        return false;
    }


    ngOnDestroy() {
        tinymce.remove(this.editor);
    }
}
