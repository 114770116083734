import {Component, Injectable, Input, OnChanges, OnInit} from '@angular/core';
import {FlashMessageService} from "./services/flash-message.service";
import {TimerObservable} from "rxjs/observable/TimerObservable";
import {Subscription} from "rxjs/Subscription";
import {trigger, state, style} from '@angular/animations';

@Component({
    selector: 'app-flash-message',
    templateUrl: './flash-message.component.html',
    styleUrls: ['./flash-message.component.css'],
    animations: [
        trigger('myAwesomeAnimation', [
            state('small', style({
                transform: 'scale(1)',
            })),
            state('large', style({
                transform: 'scale(1.2)',
            })),
        ]),
    ]
})

@Injectable()
export class FlashMessageComponent implements OnInit, OnChanges {

    showMessage: boolean = false;
    flashMessage: any = "";
    classes: any = "";
    state = 'small';

    intervalId:any;
    constructor(private flashMessageService: FlashMessageService) {
    }

    ngOnInit() {
        let current_class = this;
        this.flashMessageService.passMessage.subscribe(object => {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.hideFlashMessage();
            }
            this.flashMessage = object.message;
           this.classes = object.classes;
            this.showMessage = true;
            this.intervalId = setInterval(() => {
                this.hideFlashMessage();
            }, 100*100);
        });
    }

    ngOnChanges() {

    }

    @Input()
    showFlashMessage(message) {

        console.log(message);
        this.flashMessage = message;
        this.showMessage = true;
    }

    hideFlashMessage() {
        this.showMessage = false;
        // this.subscription.unsubscribe();
    }

}
