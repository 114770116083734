import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from '../services/ApiService/api-service.service';
import { result } from 'lodash';

@Injectable()
export class CustomerService {
	customer = new BehaviorSubject([]);
	castCustomer = this.customer.asObservable();

	customerTierNameList = new BehaviorSubject([]);
	castCustomerTireNameList = this.customerTierNameList.asObservable();

	allBankData = new BehaviorSubject({});
	castAllBankData = this.allBankData.asObservable();

	commentListApi: any;

	constructor(private customCallingApi: Custom_api_calling, private apiService: ApiService) { }

	public loadCustomerSettingInfo(params, callback) {
		this.customCallingApi.http_calling_get('api/tenant/setting/get_setting?settings_name=cc_token_timeout_setting', (result) => {
			callback(result);
		});
	}

	public fetchCustomer(params, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/getCustomer/' + params, (result) => {
			callback(result);
		});
	}

	public get_customer_primary_data(params, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/get_customer_primary_data', (result) => {
			callback(result);
		});
	}


	public getMemberType(params, callback) {
		this.customCallingApi.http_calling_get('api/tenant/member_type/getMemberType', (result) => {
			callback(result);
		});
	}

	public get_history_list(customer_id, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/getHistoryList?customer_id=' + customer_id, (result) => {
			callback(result);
		});
	}

	public fetchComment(customer_id: any, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/commentList?customer_id=' + customer_id, (result) => {
			callback(result);
		});
	}

	public fetchCommentByTag(customer_id: any, searchParam, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/commentListByTag/' + customer_id + '/' + searchParam, (result) => {
			callback(result);
		});
	}

	public saveComment(data, callback) {
		this.customCallingApi.http_calling_file_post('api/tenant/customers/comment', data, (data) => {
			callback(data);
		});
	}

	public get_customer_graph_value(customer_id, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/getCustomerGraphValue?customer_id=' + customer_id, (result) => {
			callback(result);
		});
	}

	public get_activity_list(customer_id, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/getCustomerActivity?customer_id=' + customer_id, (result) => {
			callback(result);
		});
	}

	public customer_change_status(data, callback) {
		this.customCallingApi.http_calling_file_put('api/tenant/customers/customerProfileStatusChange', data, (result) => {
			callback(result);
		});
	}

	public updateStarred(query_data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/customers/updateCommentStarred?comment_id=' + query_data.comment_id + '&&starred=' + query_data.starred, (result) => {
			callback(result);
		});
	}

	public loadCustomerPoints(query_data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/point/fetch_customer_points?customer_id=' + query_data.customer_id + '&point_type_id=' + query_data.point_type_id, (result) => {
			callback(result);
		});
	}

	public fetch_customer_points(query_data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/point/fetch_customer_points?customer_id=' + query_data.customer_id, (result) => {
			callback(result);
		});
	}

	public fetchDataFromAddPointRedeemPoint(callback: Function) {
		this.customCallingApi.http_multi_url_get({
			'rule_activities': 'api/tenant/earning_point_rules/fetchAllRuleActivities',
			'point_types': 'api/tenant/point_type/get_point_type_list',
			'point_definitions': 'api/tenant/point_definition/getPointDefinitionList',
		}, callback);
	}

	public get_extend_validity(data, callback) {
		let query_data = this.apiService.get_query_data(data);
		this.customCallingApi.http_calling_get('api/tenant/point/get_point_about_to_expire?' + query_data, (result) => {
			callback(result);
		});
	}

	fetchProfileTagList(data: any, callback) {
		this.customCallingApi.http_multi_url_get({
			'all_source_tags': 'api/tenant/tags/get_tenant_tag_list?tag_type=' + data.type,
			'customer_tags': 'api/tenant/customers/getCustomerAssignTags?customer_id=' + data.customer_id,
		}, callback);
	}

	public loadCustomerCardInfo(cust_id: number, callback) {
		this.customCallingApi.http_multi_url_get({
			'bank_data': 'api/tenant/bank/get_banks_list',
			'card_network_data': 'api/tenant/card_network/get_card_networks_list',
			'tenant_branches': 'api/tenant/user/getProfile_branch_list',
		}, callback);
	}

	public customerBulkUpload(data, callback) {
		const uploadApi = 'api/tenant/customers/customerBulkUpload';
		this.customCallingApi.http_calling_file_post(uploadApi, data, (result) => {
			callback(result);
		});
	}

	public fetch_bulk_upload_files(query_data, callback) {
		const filesApi = 'api/tenant/customers/fetchBulkUploadFiles?process_type=' + query_data['process_type'];
		this.customCallingApi.http_calling_get(filesApi, (result) => {
			callback(result);
		});
	}

	public fetch_bulk_upload_file_data(query_data, callback) {
		const fileDataApi = 'api/tenant/customers/fetchBulkUploadFileData?process_id=' + query_data.process_id;
		this.customCallingApi.http_calling_get(fileDataApi, (result) => {
			callback(result);
		});
	}

	public importCustomersCards(data, callback) {
		const uploadApi = 'api/tenant/customers/import_customers_cards';
		this.customCallingApi.http_calling_file_post(uploadApi, data, (result) => {
			callback(result);
		});
	}

	public get_code_information(data, callback) {
		this.customCallingApi.http_calling_file_post('api/tenant/get_info_be_off_code', data, (result) => {
			callback(result);
		});
	}

	public point_transfer(data, callback) {
		this.customCallingApi.http_calling_file_post('api/tenant/point/point_transfer', data, (result) => {
			callback(result);
		});
	}

	public get_customer_point_transfer_list(customer_id, callback: Function) {
		this.customCallingApi.http_multi_url_get({
			'point_transfer_list': 'api/tenant/customers/getCustomerPointTransferList?customer_id=' + customer_id,
			'tenant_customers_list': 'api/tenant/customers/getTenantCustomersList?customer_id=' + customer_id,

		}, callback);
	}

	get_customer_purchase_voucher(data, callback) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		this.customCallingApi.http_calling_get("api/tenant/voucher/get_customer_purchase_voucher?" + query_data, (result) => callback(result));
	}

	get_customer_gift_voucher(data, callback) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		this.customCallingApi.http_calling_get("api/tenant/voucher/get_customer_gift_voucher?" + query_data, (result) => callback(result));
	}

	get_customer_received_voucher(data, callback) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		this.customCallingApi.http_calling_get("api/tenant/voucher/get_customer_received_voucher?" + query_data, (result) => callback(result));
	}

	get_customer_all_voucher(data, callback) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		this.customCallingApi.http_calling_get("api/tenant/voucher/get_customer_all_voucher?" + query_data, (result) => callback(result));
	}

	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}

	get_report_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get("api/tenant/reports/get_accrual_report_list?" + query_data, (result) => callback(result));
	}

	get_customers_report_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get("api/tenant/reports/get_customers_report_list?" + query_data, (result) => callback(result));
	}

	get_all_customer(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get("api/tenant/customers/getCustomersList?" + query_data, (result) => callback(result));

	}

	create_customer(data, callback) {
		this.customCallingApi.http_calling_file_post("api/tenant/customers/addCustomer", data, (result) => callback(result));
	}

	modify_customer(data, callback) {
		this.customCallingApi.http_calling_file_put("api/tenant/customers/editCustomer/", data, (result) => callback(result));
	}

	get_customer(data, callback) {
		this.customCallingApi.http_calling_get("api/tenant/customers/getCustomer/" + data, (result) => callback(result));
	}

	updateCustomerStatus(data, callback) {
		this.customCallingApi.http_calling_file_put("api/tenant/customers/updateCustomerStatus/", data, (result) => callback(result));
	}

	get_debit_report_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get("api/tenant/reports/get_redemption_report_list?" + query_data, (result) => callback(result));
	}

	loadComment(data, callback) {
		let query_data = this.get_query_data(data);
		this.commentListApi = 'api/tenant/comments/get_comment_list?' + query_data;
		this.customCallingApi.http_calling_get(this.commentListApi, (result) => {
			callback(result);
		});
	}
}
