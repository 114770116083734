import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
// import { utils, write, WorkBook } from 'xlsx';

import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelFileExportService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    console.log("excelBuffer==>",excelBuffer);

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public exportAsExcelFile2(json: any[], excelFileName: string){
    const ws_name = 'Sample file for asset';
    const wb: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = XLSX.utils.json_to_sheet(json);
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
      };
      return buf;
    }
    FileSaver.saveAs(new Blob([s2ab(wbout)], {type: 'application/octet-stream'}), excelFileName + '_' + new Date().getTime() + '.xlsx');
  }


  /**
   * Download sample csv file
   * @param json
   * @param excelFileName
   * @param worksheetName
   */
   public bulk_download_sample_csv_file(json: any[], excelFileName: string, worksheetName: string) {

    const wb: XLSX.WorkBook = {SheetNames: [], Sheets: {}};
    const ws: any = XLSX.utils.json_to_sheet(json);
    wb.SheetNames.push(worksheetName);
    wb.Sheets[worksheetName] = ws;

    const wbout = XLSX.write(wb, {bookType: 'csv', bookSST: true, type: 'binary'});
   

    FileSaver.saveAs(new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}), excelFileName + '.csv');
  }
/*Description: This method downloads the sample file customer/asset bulk upload
  Params: json object, fileName and sheetName.
  Returns : download csv file
  Auther:  ~ Mohd Islam
*/
  public bulk_download_sample_cvs_file(json: any[], excelFileName: string, worksheetName: string ){

    const wb: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = XLSX.utils.json_to_sheet(json);
    wb.SheetNames.push(worksheetName);
    wb.Sheets[worksheetName] = ws;

    const wbout = XLSX.write(wb, { bookType: 'csv', bookSST: true, type: 'binary' });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
      };
      return buf;
    }
    FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), excelFileName+'.csv');
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  /*Description: This method downloads the sample file customer/asset bulk upload
  Params: json object, fileName and sheetName.
  Returns : download csv file
  Auther:  ~ Mohd Islam
*/
// public bulk_download_sample_cvs_file(json: any[], excelFileName: string, worksheetName: string ){

//   const wb: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
//   const ws: any = XLSX.utils.json_to_sheet(json);
//   wb.SheetNames.push(worksheetName);
//   wb.Sheets[worksheetName] = ws;

//   const wbout = XLSX.write(wb, { bookType: 'csv', bookSST: true, type: 'binary' });

//   function s2ab(s) {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) {
//       view[i] = s.charCodeAt(i) & 0xFF;
//     };
//     return buf;
//   }
//   FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), excelFileName+'.csv');
// }

}




// import { Injectable } from '@angular/core';
// import * as FileSaver from 'file-saver';
// import { utils, write, WorkBook } from 'xlsx';

// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const EXCEL_EXTENSION = '.xlsx';
// @Injectable()
// export class ExcelFileExportService {

//   constructor() { }

//   private saveAsExcelFile(buffer: any, fileName: string): void {
//     const data: Blob = new Blob([buffer], {
//       type: EXCEL_TYPE
//     });

//     FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

//   }

//   public exportAsExcelFile(json: any[], excelFileName: string){

//     const ws_name = 'CloReportExcel';
//     const wb: WorkBook = { SheetNames: [], Sheets: {} };
//     const ws: any = utils.json_to_sheet(json);
//     wb.SheetNames.push(ws_name);
//     wb.Sheets[ws_name] = ws;
//     const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

//     function s2ab(s) {
//       const buf = new ArrayBuffer(s.length);
//       const view = new Uint8Array(buf);
//       for (let i = 0; i !== s.length; ++i) {
//         view[i] = s.charCodeAt(i) & 0xFF;
//       };
//       return buf;
//     }
//     FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), excelFileName+'.xlsx');
//   }

// }
