import {Injectable} from '@angular/core';
import {Custom_api_calling} from "../../adapters/Custom_api_calling";
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {FormGroup, FormControl} from '@angular/forms';
import {ApiService} from "../ApiService/api-service.service";

@Injectable()
export class FormDataProviderService {
    private componentFormData = new BehaviorSubject([]);
    private merchantsData = new BehaviorSubject([]);
    private tenants = new BehaviorSubject([]);
    private voucherTypesData = new BehaviorSubject([]);

    castComponentFormData = this.componentFormData.asObservable();
    castMerchantsData = this.merchantsData.asObservable();
    castTenants = this.tenants.asObservable();
    castVoucherTypes = this.voucherTypesData.asObservable();


    constructor(private custom_api_adapter: Custom_api_calling, private apiService: ApiService) {
    }

    public loadIntialData() {
        this.apiService.get_merchant_init_form_information({}, (result) => {
            if (result["status"] == true) {
                this.componentFormData.next(result['values']);
            }
        })
    }

    public loadVoucherTypes() {
        this.custom_api_adapter.http_calling_get('api/tenant/voucher/get_voucher_types', (result) => {
            if (result["status"] == true) {
                this.voucherTypesData.next(result['values']);
            }
        })
    }       

    public loadRemoteMerchants() {
        this.custom_api_adapter.http_calling_get('api/tenant/merchant/get_all_merchants_name_id', (result) => {
            if (result["status"] == true) {
                this.merchantsData.next(result['values']['merchants']);
            }
        });
    }

    public loadTenants() {
        this.custom_api_adapter.http_calling_get('api/tenant/tenants/fetch_tenants_names', (result) => {
            if (result['status'] == true) {
                this.tenants.next(result['values']);
            }
        })
    }

    public fetchCheckedData(formData) {
        return Object.keys(formData).filter((data) => {
            return formData[data].isChecked;
        }).map((data) => {
            return formData[data].id;
        });
    }

    /* Returns Array of ids of data binded with checkbox that are set true */
    public fetchSetCheckboxValues(key, form: FormGroup): Array<any> {
        let result = [];
        for (let control in form.controls) {
            if (control.indexOf(key) == 0 && form.controls[control].value) {
                result.push(parseInt(control.split('_')[1]));

            }
        }
        return result;
    }

    public setRelatedSubCategories(categoryKey: string, subCategoryKey: string, sub_categories: any, form: FormGroup): Array<any> {
        let selected_categories = [];
        let related_sub_categories = [];

        for (let control in form.controls) {
            if (control.indexOf(categoryKey + '_') == 0 && form.controls[control].value)
                selected_categories.push(parseInt(control.split('_')[1]));

            if (control.indexOf(subCategoryKey + '_') == 0) {
                form.removeControl(control);
            }
        }

        related_sub_categories = sub_categories.filter((sub_category) => {
            return selected_categories.indexOf(sub_category.category_id) !== -1;
        });

        //Add All related controls
        related_sub_categories.forEach((sub_category) => {
            form.addControl(subCategoryKey + '_' + sub_category.id, new FormControl(false));
        });

        return related_sub_categories;
    }
}