import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling'


@Injectable()
export class CohortService {
    getFromLocalStorage(arg0: string) {
      throw new Error('Method not implemented.');
    }

    constructor(private customCallingApi: Custom_api_calling) { }

    getCohortList(data, callback) {
        console.log('++++===service data', data);

        let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get("api/tenant/cohorts/get_cohorts?" + query_data, (result) => callback(result));
    }

    export_cohort_Report(data, callback) {
		let query_data = this.get_query_data(data);
		this.customCallingApi.http_calling_get("api/tenant/cohorts/get_cohorts?" + query_data, (result) => callback(result));
	}


    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

}
