import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { Constants } from '../../constants/constants';
import { CustomRoutes } from '../../custom-routes';
import { Router } from '@angular/router';

@Component({
	selector: 'app-language-list',
	templateUrl: './language-list.component.html',
	styleUrls: ['./language-list.component.css']
})
export class LanguageListComponent implements OnInit {

	language_data: any = [];
	modifyCategoryId: any = "";
	loader_image = Constants.LOADER_IMAGE_CONFIGURATION;
	edit_icon = Constants.EDIT_RECORD_IMAGE_CONFIGURATION;
	total_records: number = 1;
	current_page: number;
	limit: number = 10;
	loader_status: boolean;
	searchParam: any = {};
	language_name: any;
	language_code: any;
	routes = CustomRoutes.routes;
	isEdit: boolean;
	is_edit_hide: boolean = true;
	is_hidden: boolean = false;

	constructor(private languageService: LanguageService, private router: Router) {

	}

	ngOnInit() {
		this.is_hidden = (this.router.url.indexOf("edit_point_configuration") != -1) ? false : true;
		this.get_language_list({ page: 1 })
	}


	get_language_list(data) {
		this.loader_status = true;
		this.current_page = data.page;
		let body = {};
		body['limit'] = this.limit;
		body['offset'] = (this.current_page - 1) * this.limit;
		body['language_name'] = this.language_name;
		body['language_code'] = this.language_code;
		this.loader_status = true;


		let current_class = this;

		this.languageService.get_all_language((body),
			(result) => {
				if (result["status"] == true) {
					this.isEdit = false;
					this.is_edit_hide = true;
					current_class.language_data = result["values"]['language_master_data_list'];
					this.total_records = result["values"]['total_records'];
					this.loader_status = false;
				} else {
					current_class.language_data = [];
					this.loader_status = false;
				}
			});

	}

	setfieldReset() {
		// this.searchParam = '';
		// this.get_language_list({ page: 1 })
		this.language_name = null;
		this.language_code = null;
		this.get_language_list({ page: this.current_page });
	}
}
