import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CustomRoutes } from './custom-routes';

@Directive({
    selector: '[urlLink]'
})
export class UrlLinkDirective implements OnChanges,OnInit {
    constructor(private router:Router,private elRef: ElementRef,private _renderer: Renderer2){
        elRef.nativeElement.urlLink = this.UrlLink;
        // console.log("Router1:",this.UrlLink);
    }
    @Input('urlLink') UrlLink: string = "";

    ngOnChanges() {
        this.elRef.nativeElement.urlLink = this.UrlLink;
        // console.log("Router3:",this.elRef.nativeElement.urlLink);
        __ngRendererSetElementAttributeHelper(
// console.log("Router3:",this.elRef.nativeElement.urlLink);
this._renderer, this.elRef.nativeElement, 'urlLink', this.UrlLink);
  
    }
    public ngOnInit() {
        this.elRef.nativeElement.urlLink = this.UrlLink;
        // console.log("Router2:",this.UrlLink);
    }

    @HostListener('click')
    click() {
        
        console.log("Link is",this.UrlLink);
        if(Array.isArray(this.UrlLink)){
            CustomRoutes.navigate(this.UrlLink);
        }
        else if(typeof(this.UrlLink) == 'string'){
            // console.log("String: ",this.UrlLink)
            CustomRoutes.navigate([this.UrlLink]);
            
        }
    }

}
type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}