import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class TenantRuleService {

    constructor(private customCallingApi: Custom_api_calling) { }

    public loadEarningPointRules(query_data: any, callback) {
        let earningPointRulesListApi: string = 'api/tenant/earning_point_rules/getEarningPointRulesList?search=' + query_data['search'] + '&&point_definition_id=' + query_data['point_definition_id'];
        this.customCallingApi.http_calling_get(earningPointRulesListApi, (data) => {
            callback(data);
        });
    }

    public getAllTierInfoWithPoints(query_data: any, callback) {
        this.customCallingApi.http_calling_get('api/tenant/earning_point_rules/getRuleTierInfo?id=' + query_data['id'], (data) => {
            callback(data);
        });
    }

    public loadRuleTypeMaster(searchParam: string, callback) {
        let rulesTypeMasterListApi: string = 'api/tenant/tenants/getRuleTypes';
        this.customCallingApi.http_calling_get(rulesTypeMasterListApi, (data) => {
            callback(data['values']);
        });
    }

    public fetchEarningPointRule(earning_point_rule_id: number, callback) {
        let earningPointRuleApi: string = 'api/tenant/earning_point_rules/getEarningPointRule/' + earning_point_rule_id;
        this.customCallingApi.http_calling_get(earningPointRuleApi, (data) => {
            callback(data['values'][0]);
        });
    }

    public fetchRuleFormData(callback: Function) {
        this.customCallingApi.http_multi_url_get({
            'rule_types': 'api/tenant/user/getRuleTypes',
            'rule_activities': 'api/tenant/earning_point_rules/fetchAllRuleActivities?type=accrual',
            'point_types': 'api/tenant/point_type/get_point_type_list',
            'point_definitions': 'api/tenant/point_definition/getPointDefinitionList',
            // 'categories': 'api/tenant/category/get_category_list',
            'customer_tiers': 'api/tenant/customer_tiers/get_customer_tier_name_list',
            // 'programs': 'api/tenant/card_linked_program/get_card_linked_programs_list',
            // 'merchants': 'api/tenant/get_merchant_list',
            // 'deal_codes': 'api/tenant/deal_code/get_deal_codes_list',
			// 'flat_file_models': 'api/tenant/flat_file/get_flat_file_model_list',
			'referral_activities_data': 'api/tenant/earning_point_rules/fetch_referral_activities',
			'ref_program_type_data': 'api/tenant/earning_point_rules/fetch_program_type',
            'product_sub_type_data': 'api/tenant/earning_point_rules/fetch_product_subtype',
			
			
        }, callback)
    }
    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

    public fetch_point_definitions_list(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/point_definition/getPointDefinitionList?' + query_data, (result) => {
            callback(result);
        });
	}
	
	public fetch_selected_product_subtype(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/earning_point_rules/fetch_selected_products/' + data.rule_id, callback);
	}
	
	public fetch_selected_ref_program_type(data, callback) {
		this.customCallingApi.http_calling_get('api/tenant/earning_point_rules/fetch_selected_ref_program_type/' + data.rule_id, callback);
    }
}
