import { Injectable } from "@angular/core";
import { Custom_api_calling } from "../../adapters/Custom_api_calling";

@Injectable()
export class GroupsetService {
  constructor(private api_adapter: Custom_api_calling) {}

  create_group_set(data, callback) {
    this.api_adapter.http_calling_file_post(
      "api/tenant/groupset/add_groupset",
      data.body,
      result => callback(result)
    );
  }

  get_groupset_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get(
      "api/tenant/groupset/get_groupset_list?" + query_data,
      result => callback(result)
    );
  }

  update_groupset_status(data, callback) {
    this.api_adapter.http_calling_file_put(
      "api/tenant/groupset/update_status",
      data.body,
      result => callback(result)
    );
  }

  groupset_by_id(groupset_id: number, callback) {
    this.api_adapter.http_calling_get(
      "api/tenant/groupset/get_groupset_by_id?id=" + groupset_id,
      result => callback(result)
    );
  }

  modify_group_set(data, callback) {
    this.api_adapter.http_calling_file_put(
      "api/tenant/groupset/edit_group_set",
      data.body,
      result => callback(result)
    );
  }

  groupset_has_attribute(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get(
      "api/tenant/groupset/get_groupset_attributes?" + query_data,
      result => callback(result)
    );
  }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (
        data[keys[i]] !== "" &&
        data[keys[i]] != null &&
        data[keys[i]] != undefined
      )
        query_data +=
          query_data === ""
            ? keys[i] + "=" + data[keys[i]]
            : "&" + keys[i] + "=" + data[keys[i]];
    }
    return query_data;
  }
}
