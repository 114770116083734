import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import {HttpClient} from '@angular/common/http';

@Injectable()

export class CommissionService {

    constructor(private api_adapter: Custom_api_calling, private http: HttpClient) {

    }

    getCommissionList(data: any, callback: any) {
        let queryData = this.queryData(data);
        this.api_adapter.http_calling_get("api/tenant/commission/getCommissionList?" + queryData, (result) => callback(result));
    }

    getCommissionById(data: any, callback: any) {
        this.api_adapter.http_calling_get("api/tenant/commission/getCommissionById/" + data, (result) => callback(result));
    }

    addCommission(data: any, callback: any) {
        this.api_adapter.http_calling_file_post("api/tenant/commission/addCommission", data, (result) => callback(result));
    }

    editCommission(data: any, callback: any) {
        this.api_adapter.http_calling_file_put("api/tenant/commission/editCommission", data, (result) => callback(result));
    }

    // common functions

    queryData(data: any) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

}
