import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log(value);
    var formatedDate = new Date(value);
        // console.log(formatedDate);
        var newDate = {};
        newDate['hours'] = formatedDate.getUTCHours() > 12 ? formatedDate.getUTCHours() - 12 : formatedDate.getUTCHours();
        newDate['am_pm'] = formatedDate.getUTCHours() >= 12 ? "PM" : "AM";
        newDate['minits'] = formatedDate.getUTCMinutes() < 10 ? "0" + formatedDate.getUTCMinutes() : formatedDate.getUTCMinutes();
        newDate['hours'] = newDate['hours'] < 10 ? "0" + newDate['hours'] : newDate['hours'];
        return formatedDate.getUTCDate() + '-' + formatedDate.getUTCMonth() + '-' + formatedDate.getUTCFullYear() + '  ' + newDate['hours'] + ':' + newDate['minits'] + ' ' +newDate['am_pm'];
  }

}
