import { Injectable, Inject } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { Constants } from "../constants/constants";
import { Custom_api_calling } from "../adapters/Custom_api_calling";
import { ISubscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';


@Injectable()
export class UserService {
    private isUserLoggedIn = false;
    private isBaseConfigurationComplete: boolean;
    private tenant: BehaviorSubject<any> = new BehaviorSubject({});
    private componentSubscriptions: any = {};
    private tenantGroupCodes = new BehaviorSubject([]);
    private ruleActivities = new BehaviorSubject([]);
    private categories = new BehaviorSubject([]);
    private countries = new BehaviorSubject([]);
    private _data = new BehaviorSubject([]);
    private userRoleAccess = new BehaviorSubject({
        read: [],
        write: []
    });

    currency_symbol: string;
    constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private cookieService: CookieService, private customApiCalling: Custom_api_calling) {
        this.isUserLoggedIn = false;
    }
    castTenantGroupCodes = this.tenantGroupCodes.asObservable();
    castRuleActivities = this.ruleActivities.asObservable();
    castTenant = this.tenant.asObservable();
    castCategories = this.categories.asObservable();
    castCountries = this.countries.asObservable();
    castUserRoleAccess = this.userRoleAccess.asObservable();
    logoStream: Subject<string> = new Subject();
    tenantCurrencyStream: ReplaySubject<string> = new ReplaySubject();
    tenantBasePointRateStream: ReplaySubject<any> = new ReplaySubject();
    getUserLoggedIn() {
        new Constants(this);
        return this.getFromLocalStorage('log_in_email') !== "" && this.getFromLocalStorage('log_in_email') !== null;
    }

    getIsBaseConfigurationComplete(): boolean {
        return this.isBaseConfigurationComplete;
    }

    setIsBaseConfigurationComplete(value: boolean) {
        this.isBaseConfigurationComplete = value;
    }

    getModuleAccess(callback) {
        this.customApiCalling.http_calling_get('api/tenant/user/getAccessData', (data) => {
            callback(data);
        });
    }

    loadLoggedInTenant(callback) {
        this.customApiCalling.http_calling_get('api/tenant/user/getProfile', (data) => {
            if (data['status'] == true) {
                this.tenant.next(data['values'][0]);
                this.setIsBaseConfigurationComplete(data['values'][0].is_base_configuration_complete);
                // Load Dashboard Logo using Observable
                if (data['values'][0].program_image != null) {
                    this.logoStream.next(Constants.API_URL + data['values'][0].program_image)
                } else {
                    this.logoStream.next('../../assets/images/cc-logo.png')
                }
                if (data['values'][0].group_codes != null)
                    this.tenantGroupCodes.next(data.values[0].group_codes.split(','));
                if (data['values'][0].currency_code != null) {
                    this.tenantCurrencyStream.next(data.values[0].currency_code)
                }
                if (data['values'][0].currency_symbol != null) {
                    this.setCurrencySymbol(data.values[0].currency_symbol);
                }
                if (data['values'][0].base_point_rate != null && data['values'][0].selling_point_rate != null)
                    this.tenantBasePointRateStream.next({
                        base_point_rate: data['values'][0].base_point_rate,
                        selling_point_rate: data['values'][0].selling_point_rate
                    });
                if (this.getFromLocalStorage('log_in_role') == '42') {
                    this.getModuleAccess((result) => {
                        this.userRoleAccess.next(result.values.modules);
                    })
                }
                callback();
            }
        })
    }

    //set currency
    setCurrencySymbol(val: string) {
        this.currency_symbol = val;
    }

    getCurrencySymbol() {
        return this.currency_symbol;
    }

    /* Load All Rule Activies Start */
    loadRuleActivities() {
        this.customApiCalling.http_calling_get('api/tenant/earning_point_rules/fetch_all_rule_activities', (data) => {
            this.ruleActivities.next([]);
            if (data['status'] == true) {
                let tempData = [];
                for (let index in data['values']) {
                    tempData.push(data['values'][index]);
                }
                this.ruleActivities.next(tempData);
            }
        })

    }
    /* Load All Rule Activies End */

    displayErrors(errorData) {
        let errorVariable = errorData.message;
        if (typeof errorData.errors !== "undefined" && typeof errorData.errors !== "string") {
            errorVariable = '';
            for (let error in errorData.errors) {
                errorVariable += "<div>" + errorData.errors[error] + "</div>";
            }
        }
        return errorVariable;
    }

    addModuleSubscription(moduleName: string, subscription: ISubscription) {
        if (typeof this.componentSubscriptions[moduleName] == 'undefined')
            this.componentSubscriptions[moduleName] = [];
        this.componentSubscriptions[moduleName].push(subscription);
    }

    destroyModuleSubscriptions(moduleName: string) {
        if (typeof this.componentSubscriptions[moduleName] == 'undefined')
            return false;

        this.componentSubscriptions[moduleName].forEach((subscription) => {
            subscription.unsubscribe();
        })
        this.componentSubscriptions[moduleName] = [];
    }

    addToLocalStorage(key: string, value: any) {
        this.storage.set(key, value);
    }

    getFromLocalStorage(key: string) {
        return this.storage.get(key);
    }

    removeFromLocalStorage(key: string) {
        this.storage.remove(key);
    }

    fetchCountries() {
        const customerCountryApi = 'api/tenant/customer_country/get_customer_country_list';
        this.customApiCalling.http_calling_get(customerCountryApi, (data) => {
            this.countries.next(data['values']);
        });
    }

    fetchTenantBranches(callback) {
        const branchApi = 'api/tenant/user/getTenantBranchList';
        this.customApiCalling.http_calling_get(branchApi, (data) => {
            callback(data);
        });
    }

    loadScripts(scriptsArr, callback) {
        let url = scriptsArr.shift();
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        let onLoadCallback = () => { };
        if (scriptsArr.length > 0) {
            onLoadCallback = () => { this.loadScripts(scriptsArr, callback) };
        } else {
            onLoadCallback = callback;
        }
        // Fire the loading
        if (!document.querySelector('script[src="' + url + '"]')) {
            script.onload = onLoadCallback;
            head.appendChild(script);
        } else {
            onLoadCallback();
        }
    }

    setAngularMultiSelectData(dropdownData, value) {
        return dropdownData.filter((data) => { return data.id == value });
    }

    setAngularMultiSelectDataArray(dropdownData, values) {
        return dropdownData.filter((data) => { return values.indexOf(data.id) > -1 });
    }

    setAngularMultiSelectDatas(dropdownData, value) {
        return dropdownData.filter((data) => { return data.id == value });
    }

    /* get sales office form pre data Start */
    private sales_office: BehaviorSubject<any> = new BehaviorSubject({});
    castSalesOffice = this.sales_office.asObservable();

    fetchSalesOfficePrimaryData() {
        const customerCountryApi = 'api/tenant/sales_office/get_primary_data';
        this.customApiCalling.http_calling_get(customerCountryApi, (data) => {
            this.sales_office.next(data);
        });
    }
    /* get sales office form pre data End */
   
    /* Mark as touch Service ends here          */

    getSelectedActivityList(data, callback) {
        this.customApiCalling.http_calling_get('api/tenant/sms/get_selected_activity_list/' + data.sms_template_id, callback);
    }


    getAtrributeList(data, callback) {
        // let query_data = this.get_query_data(data);
        this.customApiCalling.http_calling_get('api/tenant/segment/get_segments_list?' + data, (result) => {
            callback(result);
        });
    }
    isFormValid(formControl) {
        (<any>Object).keys(formControl.controls).forEach(field => {
            formControl.get(field).markAsTouched({ onlySelf: true });
        });
        if (formControl.valid)
            return true
        else return false
    }
    /* Mark as touch Service ends here          */

    /* check form is valid and set focus to first invalid control
        20 march 2020
        007
    */
    checkFormValid(reactiveForm, elementRef) {
        try {
            let i = 0;
            const getTopOffset = (controlEl: HTMLElement): number =>{
                const labelOffset = 100;
                return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
            }
            for (const key of Object.keys(reactiveForm.controls)) {
                reactiveForm.controls[key].markAsTouched({ onlySelf: true });
                console.log('==reactiveForm.controls',reactiveForm.controls[key].status,[key]);
                if (reactiveForm.controls[key].invalid && i == 0) {
                    // const invalidControl = elementRef.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    // invalidControl.focus();
                    const firstInvalidControl: HTMLElement = elementRef.nativeElement.querySelector(
                        "form .ng-invalid"
                    );

                    firstInvalidControl.focus();
                    window.scroll({
                        top: getTopOffset(firstInvalidControl),
                        left: 0,
                        behavior: "smooth"
                    });
                    i++;
                }
            }
            return reactiveForm.valid ? true : false;
        } catch (error) {
            return false
        }
    }
}
