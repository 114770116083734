import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class PointDefinitionMasterService {

    pointDefinitions = new BehaviorSubject([]);
    castPointDefinitions = this.pointDefinitions.asObservable();

    pointDefinition = new BehaviorSubject({});
    castPointDefinition = this.pointDefinition.asObservable();    

    constructor(private customCallingApi: Custom_api_calling) {}

    // loadPointDefinitions(searchParam ?: string) {
    //     let pointDefinitionsApi = 'api/tenant/point_definition/getPointDefinitionList';
    //     this.customCallingApi.http_calling_get(pointDefinitionsApi, (data) => {
    //         this.pointDefinitions.next([]);
    //         if(data['status'] == true){
    //             let tempArr = [];
    //             for(let index in data['values']){
    //                 tempArr.push(data['values'][index]);
    //             }
    //             this.pointDefinitions.next(tempArr);
    //         }
    //     });
    // }

    loadPointDefinitions(data,callback){
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/point_definition/getPointDefinitionList?'+query_data,(result)=>{
            callback(result);
        });
    }

    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if(data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]]!= undefined)
            query_data += (query_data === ""  ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }
    fetchPointDefinition(pointDefinitionId: number) {
        let pointDefinitionApi: string = 'api/tenant/point_definition/getPointDefinitionById/' + pointDefinitionId;
        this.customCallingApi.http_calling_get(pointDefinitionApi, (data) => {
            if (data['status'] === true) {
                this.pointDefinition.next(data['values'][0]);
            }
        });
    }

}
