import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UserService} from "../services/user.service";
import { Constants } from '../constants/constants';
import { FlashMessageService } from '../libraries/flash-message/services/flash-message.service';
import { CustomRoutes } from '../custom-routes';

@Injectable({
    providedIn: 'root' 
  })
export class AuthGuard implements CanActivate {
    constructor ( private user: UserService, private router:Router,private flashMessageService:FlashMessageService){}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot,): boolean {

        if("/"+next.routeConfig.path=='/developer_panel/assign_group'||
        "/"+next.routeConfig.path== '/developer_panel/groups' ||
        "/"+next.routeConfig.path == '/developer_panel/assigned_route_api_list/:group_id' ||
        "/" + next.routeConfig.path == '/developer_panel/assign_api_groups'){
            return true;
        }
        let i=0;
        for (i = 0; i < Constants.routes.length; i++) {
            try {
                // console.log((Constants.routes[i].route) ,CustomRoutes.current_url , Constants.routes[i].enabled )
                if (( Constants.routes[i].route) == CustomRoutes.current_url && Constants.routes[i].enabled === 1) {
                    // console.log("enabled");
                    break;
                }
            } catch (e) {
            }
        }
        if(i < Constants.routes.length){
            let group = Constants.routes[i].api_permission_group;
            let permission = Constants.routes[i].valid_permission_type;
            for(let i=0;i<Constants.role_privileges.length;i++){
                if(group == undefined){
                    // console.log("Group Undefined");
                    return true;
                }
                if(Constants.role_privileges[i].group === group){
                    if (Constants.role_privileges[i].permission == 'none') {
                        this.flashMessageService.showMessage("Not Authorized","alert alert-danger");
                        return false;
                    }
                    // console.log(Constants.role_privileges[i].permission,permission);
                    if (((Constants.role_privileges[i].permission == 'read' || Constants.role_privileges[i].permission=='write') && permission =='read') ||
                    Constants.role_privileges[i].permission == 'write' && permission=='write') {
                        console.log("Permission Not Matched");
                        return true;
                    } else {
                        // console.log("Permission Not Matched");
                        this.flashMessageService.showMessage("Not Authorized","alert alert-danger");
                        return false;
                    }
                }
            }
        }
        // console.log("1212");

        if(Constants.init !=0){
            this.flashMessageService.showMessage("Not Authorized","alert alert-danger");
            Constants.init++;
            return false;
        }
        Constants.init++;
        return true;
    }
}
