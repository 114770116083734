import {Injectable} from "@angular/core";

@Injectable()
export class Add_earning_point_rule {
    name: string ;
    select_wlp: string ;
    description: string ;
    status: string ;
    rule_type: string ;
    usage_limit: string ;
    credits: string ;
    valid_for: string ;
    target: string ;

    constructor() {
    }
}

