import {Injectable} from "@angular/core";

@Injectable()
export class Add_tier {
    tier_name: string ;
    tier_price: string ;
    offer_base_commission: string ;
    offer_receivable_commission: string ;

    constructor() {
    }
}

