import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class ProgramService {

    constructor(private api_adapter:Custom_api_calling) { }

    addProgram(programData:any,callback:Function){
		console.log("service",programData)
        let formData = new FormData();
        for(let index in programData){
            formData.append(index,programData[index]);
        }
        this.api_adapter.http_calling_file_post('api/tenant/tenants/add_tenant_program',programData,callback);
    }

    editProgram(programData:any,callback:Function){
        let formData = new FormData();
        for(let index in programData){
            formData.append(index,programData[index]);
        }
        this.api_adapter.http_calling_file_put('api/tenant/tenants/edit_tenant_program?program_id='+programData.program_id,programData,callback);
    }

    getProgram(callback:Function){
        this.api_adapter.http_calling_get('api/tenant/tenants/get_tenant_program?',callback);
    }

    getProgramFormData(callback:Function){
        this.api_adapter.http_multi_url_get({
            'group_codes': 'api/tenant/user/getGroupCodes',
            'program' : 'api/tenant/tenants/get_tenant_program?',
        },callback)
    }

    getProgramInformationTreeData(callback:Function){
        this.api_adapter.http_multi_url_get({
            'programs' : 'api/tenant/tenants/get_program_info_tree',
        },callback)
	}
	
	get_all_languages(callback) {
		this.api_adapter.http_calling_get("api/tenant/tenants/get_languages_list", (result) => callback(result));
	   
	  }
}
