import {Injectable} from '@angular/core';
import {Custom_api_calling} from '../adapters/Custom_api_calling';

@Injectable()
export class SegmentService {

  constructor(private api_adapter: Custom_api_calling) {
  }

  create_segment(data, callback) {
    this.api_adapter.http_calling_file_post('api/tenant/segment/add_segment', data, (result) => callback(result));
  }

  modify_segment(data, callback) {
    this.api_adapter.http_calling_file_put('api/tenant/segment/edit_segment', data, (result) => callback(result));
  }

 
  segment_by_id(segment_id: number, callback) {
    this.api_adapter.http_calling_get('api/tenant/segment/get_by_id?id=' + segment_id, (result) => callback(result));
  }

  update_segment_status(data, callback) {    
    this.api_adapter.http_calling_file_put("api/tenant/segment/update_status", data.body, (result) => callback(result));
  }

  get_all_segment(data, callback) {
    const query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/segment/get_segment?' + query_data, (result) => callback(result));
  }

  segment_details(data, callback) {
    const query_data = this.get_query_data(data);
    console.log("query_data",query_data)
    this.api_adapter.http_calling_get('api/tenant/segment/segment_details?' + query_data, (result) => callback(result));
  }
  update_customer_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/segment/update_customer_status", data.body, (result) => callback(result));
  }

  uploadFileCheck(data, callback) {
    const api = 'api/tenant/segment/upload_segment_file';
    this.api_adapter.http_calling_file_post(api, data.body, callback);
  }

  
  update_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/segment/update_customer_status", data.body, (result) => callback(result));
  }

  update_rules_engine(callback) {
    this.api_adapter.re_http_get("updateCohorts", callback);
  }

  get_query_data(data) {

    let query_data = '';
    let keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== '' && data[keys[i]] != null && data[keys[i]] != undefined) {
        query_data += (query_data === '' ? keys[i] + '=' + data[keys[i]] : '&' + keys[i] + '=' + data[keys[i]]);
      }
    }
    return query_data;
  }
}
