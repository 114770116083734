import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
    Total_page: any = [];
    total_pages = 1
    data_list:any = [];

    page:number = 1;
    pager: any = {};
    pagedItems: any[];
    totalPagesCount: number = 1;

 @Input()
    set total_records(t_page: number) {
        if (!(isNaN(t_page))) {
            this.total_pages = t_page;
            this.pager = this.getPager(this.total_pages, 1);
        }
    }

    @Input()
    set list(data) {
        if (!(isNaN(data))) {
            this.data_list = data;
        }
    }

    @Output() onPageClick = new EventEmitter();
    constructor() {

    }

    ngOnInit() {
    //    this.setPage(1);
    }

    setPage(page: number) {
        this.pager = this.getPager(this.total_pages, page);
        this.onPageClick.emit({
            page: page
        });
    }

    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
        let totalPages = Math.ceil(totalItems / pageSize);
        this.totalPagesCount = totalPages;
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}


