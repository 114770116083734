import { Injectable } from '@angular/core';
import {
	Custom_api_calling
} from '../adapters/Custom_api_calling';
import {
	BehaviorSubject
} from 'rxjs/BehaviorSubject';

@Injectable()
export class PromocodeService {
	sms_template = new BehaviorSubject([]);
	sms_templates = new BehaviorSubject([]);
	tagLists = new BehaviorSubject([]);

	activityList = new BehaviorSubject([]);
	castActivityList = this.activityList.asObservable();
	castSmsTemplate = this.sms_template.asObservable();
	castSmsTemplates = this.sms_templates.asObservable();
	castTagLists = this.tagLists.asObservable();


	constructor(private custom_api_calling: Custom_api_calling) {
	}


	// loadSmsTemplates(searchParam: string) {
	//     let smsTemplateListApi = 'api/tenant/sms/get_sms_template_list' + searchParam;
	//     this.custom_api_calling.http_calling_get(smsTemplateListApi, (data) => {
	//         this.sms_templates.next(data.values);
	//     });
	// }
	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}

	loadPromocodeList(data, callback) {
		let query_data = this.get_query_data(data);
		this.custom_api_calling.http_calling_get('api/tenant/promocode/get_promocode_list?' + query_data, (result) => {
			callback(result)
		});
	}
	fetchSmsTemplate(smsTemplateId) {
		let smsTemplateApi = 'api/tenant/sms/get_sms_template_by_id/' + smsTemplateId;
		this.custom_api_calling.http_calling_get(smsTemplateApi, (data) => {
			this.sms_template.next(data.values[0]);
		});
	}

	getAllCampaignList(data, callback) {
		let activityApi = 'api/tenant/promocode/getAllCampaign';
		this.custom_api_calling.http_calling_get(activityApi, (result) => {
			// console.log('called', data.values);
			// this.activityList.next(data.values)
			callback(result);
		})
	}
	exportPromocodeFile(data, callback) {
		let query_data = this.get_query_data(data);
		this.custom_api_calling.http_calling_get("api/tenant/promocode/exportPromocodeFile?" + query_data, (result) => callback(result));
	  }

	fetchTagList(data, callback) {
		const api = 'api/tenant/email_template/get_tag_list';
		this.custom_api_calling.http_calling_file_post(api, data, callback);
	}

	getLanguages(data, callback) {
		let query_data = this.get_query_data(data);
		this.custom_api_calling.http_calling_get('api/tenant/rule_activity/getLanguages?' + query_data, callback);
	}

	getSelectedSmsLanguages(data, callback) {
		this.custom_api_calling.http_calling_get('api/tenant/sms/get_selected_sms_languages/' + data.sms_template_id, callback);
	}


}
