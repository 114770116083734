import { Component, OnInit, ChangeDetectorRef, ElementRef, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { ApiService } from '../services/ApiService/api-service.service';
import { of } from 'rxjs/observable/of';
import { Constants } from '../constants/constants';
import { Router } from '@angular/router';
import { FlashMessageService } from '../libraries/flash-message/services/flash-message.service';
import { CustomRoutes } from '../custom-routes';
import { LobService } from '../masters/lob/lob.service';
import { ReturnStatement } from '@angular/compiler';
import { TenantUserService } from '../tenant-user-management/tenant-user.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";


@Component({
	selector: 'app-home',
	templateUrl: 'home.component.html',
	styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit {
	log_in_username = this.userService.getFromLocalStorage('log_in_name');
	role = this.userService.getFromLocalStorage("log_in_role");
	availableGroupCodes = [];
	dashboardLogo: string = '';
	permissions: any = [];
	permissions_getting: any = false;
	public customRoutes = CustomRoutes.routes;
	route_found = 0;
	api_permission_found = 0;
	routes = CustomRoutes.routes;
	access:any = {
		read:[],
		write:[]
	}
	now: any;
    timeZone: any;
	lobList: any = [];
	show_session_block: any = false;
    session_count_down: any = null;

	tenant_role_id = this.userService.getFromLocalStorage('log_in_role');
	constructor(private cookieService: CookieService, private userService: UserService, private apiService: ApiService,
		private cdRef: ChangeDetectorRef, private router: Router, private flasMessageService: FlashMessageService, 
        private lobService: LobService, private tenantUserService: TenantUserService, private elementRef: ElementRef,
        @Inject(LOCAL_STORAGE) private storage: WebStorageService) { 
			this.timeZone = /\((.*)\)/.exec(new Date().toString())[1].match(/\b(\w)/g).join('');
        setInterval(() => {
            this.now = Date.now();
        }, 1000);
		}

		isTabActive(group_name){
			let list = JSON.parse(localStorage.getItem('privileges_list'))
			// console.log('+++++group_name',group_name);
            if(!list){
				return false;
			}

			let group = list.find(obj=>obj.group == group_name)
			
			if(!group){
				return false;
			}
			if(group.permission == 'none'){
				return false;
			}
			return true;
		};

	ngOnInit() {
		this.navBar()
		this.log_in_username = this.userService.getFromLocalStorage('log_in_tenant_user_name');
		this.getLobList({ status: 1 });

		this.flasMessageService.passMessage.subscribe(object => {
			console.log("Flash message working", object);
		})
		this.userService.castUserRoleAccess.subscribe((result)=>{
			if(result['header']){
				this.access=result['header'];
			}
		})

		let token = this.userService.getFromLocalStorage('privileges_token');
		$("ul.side-menu").hide();
		$(".custome-right-div").hide();
		$(".full-loading").show();
		this.apiService.check_permission_token({ role_id: this.role, token: token }, (result) => {
			if (result.status == true) {
				this.set_privileges(result);
			} else {
				this.permissions = this.userService.getFromLocalStorage('privileges_list');
				
				if (this.permissions == undefined) {
					this.apiService.check_permission_token({ role_id: this.role }, (result) => {
						if (result.status == true) {
							this.set_privileges(result);
						}
					});
				} else {
					Constants.role_privileges = this.permissions;
				}
				this.api_permission_found = 1;
				this.check_permissions();
			}
		});
		let current_class = this;
		this.apiService.get_routes({}, (result) => {
			if (result.status === true) {
				Constants.routes = result.values;
				$("ul.side-menu li").each(function (index) {
					let i = 0;
					for (i = 0; i < Constants.routes.length; i++) {
						try {
							if ((Constants.routes[i].route) == $(this).children("a").attr("urlLink") && Constants.routes[i].enabled === 1) {
								$(this).children("a").attr("data-per", Constants.routes[i].valid_permission_type);
								$(this).children("a").attr("data-per-group", Constants.routes[i].api_permission_group);
								break;
							}
						} catch (e) {
						}
					}
					// if ($(this).children("a").attr("urlLink") !== undefined && i == Constants.routes.length) {
					// 	// console.log(1)
					// 	$(this).remove();
					// };
				});
				current_class.route_found = 1;
				$("ul.side-menu > li").each(function (index) {
					if ($(this).children("a").attr("urlLink") === undefined && $(this).children('ul').children("li").length <= 0) {
						// console.log(2)
						$(this).remove();
					}
				});
				current_class.check_permissions();
			} else {
				Constants.routes = [];
			}
		});
		this.userService.castTenantGroupCodes.subscribe((data) => {
			this.availableGroupCodes = data;
			// console.log(this.availableGroupCodes);
		})
		this.userService.logoStream.subscribe((logo) => {
			this.dashboardLogo = logo;
		});
	}
	check_permissions() {
		let current_class = this;
		if (this.route_found == 1 && this.api_permission_found == 1) {
			$("ul.side-menu li").each(function (index) {
				let per_type = $(this).children("a").attr("data-per");
				let per_group = $(this).children("a").attr("data-per-group");
				let route = $(this).children("a").attr("urlLink");
				let i = 0;
				// console.log('++++per_group', per_group,per_type)

				if (per_group !== undefined && per_type !== undefined) {
					for (i = 0; i < current_class.permissions.length; i++) {
						// console.log(per_group, current_class.permissions[i].group, per_type);
						if (per_group == current_class.permissions[i].group) {
							if (per_type === 'none') {
								// console.log(3)
								$(this).remove();
								break;
							}
							if (((per_type === 'read' && (current_class.permissions[i].permission === 'read' || current_class.permissions[i].permission === 'write'))
								|| (per_type === 'write' && current_class.permissions[i].permission === 'write'))) {
								break;
							}
							else {
							}
						} else {
						}
					}
					if (i === current_class.permissions.length) {
						// console.log(4)
						$(this).remove();
					}
				}
			});
			// console.log('++++vipin');
			// $("ul.side-menu li").each(function (index) {
			// 	if ($(this).children("a").attr("urlLink") == undefined && $(this).children('ul').children("li").length <= 0) {
			// 		$(this).remove();
			// 	}
			// });
			$("ul.side-menu > li").each(function (index) {
				if ($(this).children("a").attr("urlLink") === undefined && $(this).children('ul').children("li").length <= 0) {
					// console.log(5)
					$(this).remove();
				}
			});
			$("ul.side-menu").show();
			$(".custome-right-div").show();
			$(".full-loading").hide();
		}
	}
	set_privileges(result) {
		this.userService.addToLocalStorage('privileges_token', result.values.token);
		this.userService.addToLocalStorage('privileges_list', result.values.list);
		this.permissions = result.values.list;
		Constants.role_privileges = this.permissions;
		this.cdRef.detectChanges();
		this.api_permission_found = 1;
		this.check_permissions();
	}
	check_permission_exist(per_name, type = null) {
		let keys = Object.keys(Constants.role_privileges);
		for (let i = 0; i < Constants.role_privileges.length; i++) {
			if (Constants.role_privileges[i].group === per_name) {
				if (Constants.role_privileges[i].permission == 'none') {
					return 'none';
				}
				if (type !== null) {
					if (((Constants.role_privileges[i].permission == 'read' || Constants.role_privileges[i].permission == 'write') && type == 'read') ||
						Constants.role_privileges[i].permission == 'write' && type == 'write') {
						return 'block';
					} else {
						return 'none';
					}
				}
				return 'block';
			}
		}
		return 'none';
	}
	check_permission_type(per_name, type) {
		for (let i = 0; i < Constants.role_privileges.length; i++) {
			if (Constants.role_privileges[i].group === per_name) {
				if (Constants.role_privileges[i].permission == 'none') {
					return 'none';
				}
				if (((Constants.role_privileges[i].permission == 'read' || Constants.role_privileges[i].permission == 'write') && type == 'read') ||
					Constants.role_privileges[i].permission == 'write' && type == 'write') {
					return 'block';
				} else {
					return 'none';
				}
			}
		}
		return 'none';
	}
 
	

 


    dynamicNavBar() {
        let role = localStorage.getItem("role_id");
		console.log('===role',role);
		
        this.tenantUserService.get_routes_by_role(role, (result) => {
            if (result.status === true) {
                // result.values.push({ route: 'dashboard' });
                Constants.routes = result.values;
                this.userService.addToLocalStorage('routes', result.values);
                $("ul.sidebar-nav li").each(function (index) {
                    let i = 0;
                    for (i = 0; i < Constants.routes.length; i++) {
                        try {
                            let url = $(this).children("a").attr("urlLink");
                            let checkRoute = Constants.routes[i].route.split('|');
                            let value = checkRoute[1] ? checkRoute[1].split(',') : '';
                            let change_url = url.split(',');

                            if (checkRoute[0] == url) {
                                break;
                            }

                            if (value[0] && checkRoute[0] == change_url[0]) {
                                for (let j = 0; j < value.length; j++) {
                                    if (url == checkRoute[0] + ',' + value[j]) {
                                        return;
                                    }
                                }
                            }
                        } catch (e) {
                        }
                    }
                    if ($(this).children("a").attr("urlLink") !== undefined && i == Constants.routes.length) {
                        $(this).remove();
                    };

                });
                $("ul.sidebar-nav li").each(function (index) {
                    if ($(this).children("a").attr("urlLink") == undefined && $(this).children('ul').children("li").length <= 0) {
                        $(this).remove();
                    }
                });
                $("ul.sidebar-nav > li").each(function (index) {
                    if ($(this).children("a").attr("urlLink") === undefined && $(this).children('ul').children("li").length <= 0) {
                        $(this).remove();
                    }
                });


            } else {
                // Constants.routes.push({ route: 'dashboard' });

                // $("ul.sidebar-nav li").each(function (index) {
                //     if ($(this).children("a").attr("urlLink") !== 'dashboard')
                //         $(this).remove();

                // });
            }
        });
    }

	onActivate(event) {
        window.scroll(0, 0);
    }

    logOut() {
        if (localStorage.checkbox == "true") {
            var arr = [];
            for (var i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i) !== "checkbox" && localStorage.key(i) !== "username" && localStorage.key(i) !== "password") {
                    arr.push(localStorage.key(i));

                }

            }
            for (var i = 0; i < arr.length; i++) {
                localStorage.removeItem(arr[i]);
            }
            window.location.reload();
        }
        else {
			this.userService.removeFromLocalStorage('log_in_email');
            localStorage.clear();
            window.location.reload();
        }
    }
	

    submit() {
        document.getElementById("logOutModal").click();
    }
    changeOfRoutes() {
        this.flasMessageService.showMessage("Not Authorized", "alert alert-danger");
        return false;
    }
	
	getLobList(data) {
		console.log('===data',data);
        this.lobService.getLobList(data, result => {
            if (result["status"] == true) {
                this.lobList = result.values.lobList.map(lob => {
                    return {
                        id: lob.id,
                        itemName: lob.name
                    };
                });
                // this.dynamicNavBar();
            } else {
                // this.dynamicNavBar();
                this.lobList = [];
            }
        });
    }
		
	couponCollapseClass: any;
    checkCouponIndex: any;
    arrowCouponClass: any;
    showHideCoupon: any;
    couponEvent(index) {
        if (this.checkCouponIndex != undefined && this.checkCouponIndex == index && this.arrowCouponClass != undefined && this.arrowCouponClass == 'has-arrow waves-effect waves-dark active') {
            this.arrowCouponClass = 'has-arrow waves-effect waves-dark';
            this.couponCollapseClass = 'collapse';
            this.checkCouponIndex = index;
            this.showHideCoupon = 'none';
        } else {
            this.arrowCouponClass = 'has-arrow waves-effect waves-dark active';
            this.checkCouponIndex = index;
            this.couponCollapseClass = 'collapse in'
            this.showHideCoupon = 'block';
        }
    }

	ruleCollapseClass: any;
    checkRuleIndex: any;
    arrowRuleClass: any;
    showHideRule: any;
    ruleEvent(index) {
		console.log('+++++++index',index);
        if (this.checkRuleIndex != undefined && this.checkRuleIndex == index && this.arrowRuleClass != undefined && this.arrowRuleClass == 'has-arrow waves-effect waves-dark active') {

            this.arrowRuleClass = 'has-arrow waves-effect waves-dark';
            this.ruleCollapseClass = 'collapse';
            this.checkRuleIndex = index;
            this.showHideRule = 'none';
			console.log('none', this.showHideRule,this.ruleCollapseClass,this.arrowRuleClass,this.checkRuleIndex);

        } else {
            this.arrowRuleClass = 'has-arrow waves-effect waves-dark active';
            this.checkRuleIndex = index;
            this.ruleCollapseClass = 'collapse in'
            this.showHideRule = 'block';
			console.log('block', this.showHideRule,this.ruleCollapseClass,this.arrowRuleClass,this.checkRuleIndex);

        }

    }

	coBrandCollapseClass: any;
    checkCoBrandIndex: any;
    arrowCoBrandClass: any;
    showHideCoBrand: any;
    coBrandEvent(index) {
        if (this.checkCoBrandIndex != undefined && this.checkCoBrandIndex == index && this.arrowCoBrandClass != undefined && this.arrowCoBrandClass == 'has-arrow waves-effect waves-dark active') {
            this.arrowCoBrandClass = 'has-arrow waves-effect waves-dark';
            this.coBrandCollapseClass = 'collapse';
            this.checkCoBrandIndex = index;
            this.showHideCoBrand = 'none';
        } else {
            this.arrowCoBrandClass = 'has-arrow waves-effect waves-dark active';
            this.checkCoBrandIndex = index;
            this.coBrandCollapseClass = 'collapse in'
            this.showHideCoBrand = 'block';
        }
    }
	navBar(){
        $(function () {
            var url = window.location;
            var element = $('ul#sidebarnav a').filter(function () {
                return $(this).prop('href') == url;
            }).addClass('active').parent().addClass('active');
            while (true) {
                if (element.is('li')) {
                    element = element.parent().addClass('in').parent().addClass('active').children('a').addClass('active');

                }
                else {
                    break;
                }
            }
            $('#sidebarnav a').on('click', function (e) {

                if (!$(this).hasClass("active")) {
                    // hide any open menus and remove all other classes
                    $("ul", $(this).parents("ul:first")).removeClass("in");
                    $("a", $(this).parents("ul:first")).removeClass("active");

                    // open our new menu and add the open class
                    $(this).next("ul").addClass("in");
                    $(this).addClass("active");

                }
                else if ($(this).hasClass("active")) {
                    $(this).removeClass("active");
                    $(this).parents("ul:first").removeClass("active");
                    $(this).next("ul").removeClass("in");
                }
            })
            $('#sidebarnav >li >a.has-arrow').on('click', function (e) {
                e.preventDefault();
            });
        });

    }

}
