import { NgModule, enableProdMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GraphsComponent } from './libraries/graphs/graphs.component';
import { DownloadManagerComponent } from './download-manager/download-manager.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { TenantBaseConfigurationComponent } from './tenant_base_configuration/tenant-base-configuration/tenant-base-configuration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FlashMessageComponent } from './libraries/flash-message/flash-message.component';
import { FlashMessageService } from './libraries/flash-message/services/flash-message.service';
import { DatePipe } from '@angular/common';
import { RoundOffMechanismComponent } from './round-off-mechanism/round-off-mechanism.component';

// import { LinkDirective } from './link.directive';

//Custom Directive
enableProdMode();
const appRoutes: Routes = [
	{ path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
	{ path: 'base_configuration', component: TenantBaseConfigurationComponent, canActivate: [AuthGuard] },
	{ path: 'download_manager', component: DownloadManagerComponent },
	{ path: '', component: DashboardComponent },

	/********************************End Download Manager************************************/
	// {path: 'lock_point', component: TabPointsTransferComponent },
	// {path: 'lock_point', component: TabPointsTransferComponent },
	{ path: 'customer', loadChildren: () => import('./tenant_customer/tenant-customer.module').then(m => m.TenantCustomerModule) },
	{ path: 'cron', loadChildren: () => import('./cron-management/cron-management.module').then(m => m.CronManagementModule) },
	{ path: 'customer_tiers', loadChildren: () => import('./customer_tier_management/customer-tier-management.module').then(m => m.CustomerTierManagementModule) },
	{ path: 'customer_heatmap', loadChildren: () => import('./customer-heatmap/customer-heatmap.module').then(m => m.CustomerHeatmapModule) },
	{ path: 'cc_rules', loadChildren: () => import('./cc-rules-module/cc-rules-module.module').then(m => m.CcRulesModuleModule) },
	{ path: 'configuration', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule) },
	{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
	{ path: 'developer_panel', loadChildren: () => import('./developer_panel/developer-panel.module').then(m => m.DeveloperPanelModule) },
	{ path: 'email_campaign', loadChildren: () => import('./email_campaign/email-campaign.module').then(m => m.EmailCampaignModule) },
	{ path: 'flat_file_model', loadChildren: () => import('./flat-file-model/flat-file-model.module').then(m => m.FlatFileModelModule) },
	{ path: 'html_templates', loadChildren: () => import('./html-templates/html-templates.module').then(m => m.HtmlTemplatesModule) },
	{ path: 'point_definition', loadChildren: () => import('./point_definition_master/point-definition-master.module').then(m => m.PointDefinitionMasterModule) },
	{ path: 'point_type', loadChildren: () => import('./point_type_master/point-type-master.module').then(m => m.PointTypeMasterModule) },
	{ path: 'redemption_rules', loadChildren: () => import('./redemption-rules/redemption-rules.module').then(m => m.RedemptionRulesModule) },
	{ path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
	{ path: 'tags', loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule) },
	{ path: 'tenant_base_configuration', loadChildren: () => import('./tenant_base_configuration/tenant-base-configuration.module').then(m => m.TenantBaseConfigurationModule) },
	// { path: 'tenant_branch', loadChildren: './tenant_branch_management/tenant-branch-management.module#TenantBranchManagementModule' },
	{ path: 'tenant_email_template', loadChildren: () => import('./tenant_email_template/tenant-email-template.module').then(m => m.TenantEmailTemplateModule) },
	{ path: 'tenant_merchant', loadChildren: () => import('./tenant_merchant/tenant-merchant.module').then(m => m.TenantMerchantModule) },
	{ path: 'tenant_payables', loadChildren: () => import('./tenant_payables/tenant-payables.module').then(m => m.TenantPayablesModule) },
	{ path: 'tenant_rule', loadChildren: () => import('./tenant_rule/tenant-rule.module').then(m => m.TenantRuleModule) },
	{ path: 'tenant_receivables', loadChildren: () => import('./tenant_receivables/tenant-receivables.module').then(m => m.TenantReceivablesModule) },
	{ path: 'tenant_report', loadChildren: () => import('./tenant_report/tenant-report.module').then(m => m.TenantReportModule) },
	{ path: 'tenant_sms_template', loadChildren: () => import('./tenant_sms_template/tenant-sms-template.module').then(m => m.TenantSmsTemplateModule) },
	{ path: 'tenant_user_role', loadChildren: () => import('./tenant_user_role_management/tenant-user-role-management.module').then(m => m.TenantUserRoleManagementModule) },
	{ path: 'tenant_program', loadChildren: () => import('./tenant-program/tenant-program.module').then(m => m.TenantProgramModule) },
	{ path: 'tenant_user', loadChildren: () => import('./tenant-user-management/tenant-user-management.module').then(m => m.TenantUserManagementModule) },
	{ path: 'wallet', loadChildren: () => import('./tenant-wallet/tenant-wallet.module').then(m => m.TenantWalletModule) },
	{ path: 'user_permissions', loadChildren: () => import('./user_permissions/user-permissions.module').then(m => m.UserPermissionsModule) },
	{ path: 'user_profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) },
	{ path: 'activities', loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule) },
	{ path: 'push_notification', loadChildren: () => import('./push-notification/push-notification.module').then(m => m.PushNotificationModule) },
	{ path: 'activity_log', loadChildren: () => import('./activity_log/log.module').then(m => m.LogModule) },
	{ path: 'brand', loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule) },
	{ path: 'country', loadChildren: () => import('./country/country-routing-module.module').then(m => m.CountryRoutingModuleModule) },
	{ path: 'partner', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule) },
	{ path: 'affiliate_report', loadChildren: () => import('./affiliate-reports/affiliate-reports.module').then(m => m.AffiliateReportsModule) },
	{ path: 'commission', loadChildren: () => import('./commission/commission.module').then(m => m.CommissionModule) },
	{ path: 'referral_campaign', loadChildren: () => import('./referral_campaign/campaign.module').then(m => m.CampaignModule) },
	{ path: 'merchant', loadChildren: () => import('./merchant/merchant.module').then(m => m.MerchantModule) },
	{ path: 'member_types', loadChildren: () => import('./member_types/memberTypes.module').then(m => m.MemberTypesModule) },
	{ path: 'language', loadChildren: () => import('./language-master/language.module').then(m => m.LanguageModule) },
	{ path: 'city', loadChildren: () => import('./city/city.module').then(m => m.CityModule) },
	{ path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
	{ path: 'referral_program_type', loadChildren: () => import('./referral-program-type/referral-program-type.module').then(m => m.ReferralProgramTypeModule) },
	{ path: 'referral', loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule) },
	{ path: 'customers', loadChildren: () => import('./customer/customer-module.module').then(m => m.CustomerModule) },
	{ path: 'product_type', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
	{ path: 'product_sub_type', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
	{ path: 'notification', loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule) },
	{ path: 'segment', loadChildren: () => import('./segment/segment.module').then(m => m.SegmentModule) },
	{ path: 'api_permission_module', loadChildren: () => import('./api_permission_module/permission_module.module').then(m => m.ApiPermissionModule) },
	{ path: 'status_name', loadChildren: () => import('./status-name/status-name.module').then(m => m.StatusNameModule) },
	{ path: 'fraud_management', loadChildren: () => import('./fraud-management/fraud-management.module').then(m => m.FraudManagementModule) },
	{ path: 'point_request', loadChildren: () => import('./point-request/point-request.module').then(m => m.PointRequestModule) },
	{ path: 'capping', loadChildren: () => import('./admin-user-capping/admin-user-capping.module').then(m => m.AdminUserCappingModule) },
	{ path: 'promocodes', loadChildren: () => import('./promocodes/promocode.module').then(m => m.PromocodeModule)},
	{ path: 'cohort', loadChildren: () => import('./cohort/cohort.module').then(m => m.CohortModule)},
	{ path: 'watchlist', loadChildren: () => import('./watchlist-members/watchlist-members.module').then(m => m.WatchlistMembersModule) },
	{ path: 'offerAssignment', loadChildren: () => import('./offer-assign/offer-assign.module').then(m => m.OfferAssignModule) },



	
	
	
	{ path: 'attributes', loadChildren: () => import('./masters/attributes/attributes.module').then(m => m.AttributesModule) },
    { path: 'groupset', loadChildren: () => import('./masters/groupset/groupset.module').then(m => m.GroupsetModule)},
    { path: 'rewards', loadChildren: () => import('./masters/rewards/rewards.module').then(m => m.RewardsModule) },
    { path: 'lob', loadChildren: () => import('./masters/lob/lob.module').then(m => m.LobModule)},    
    { path: 'funding_partner', loadChildren: () => import('./masters/funding_partner/funding-partner.module').then(m => m.FundingPartnerModule)},
    { path: 'funding_attributes', loadChildren: () => import('./masters/funding_attributes/funding-attributes.module').then(m => m.FundingAttributesModule)},
    { path: 'reward_engine', loadChildren: () => import('./reward_engine/reward-engine.module').then(m => m.RewardEngineModule)},
    { path: 'reward_engine_superseded', loadChildren: () => import('./reward_engine/reward-engine.module').then(m => m.RewardEngineModule) },
    { path: 'coupon_engine', loadChildren: () => import('./coupon_engine/coupon-engine.module').then(m => m.CouponEngineModule)},
    { path: 'coupon_burn_engine', loadChildren: () => import('./coupon_burn_engine/coupon-burn-engine.module').then(m => m.CouponBurnEngineModule) },
    { path: 'burn_engine', loadChildren: () => import('./burn_engine/burn-engine.module').then(m => m.BurnEngineModule) },
    { path: 'customer_segment', loadChildren: () => import('./customer_segment/segment.module').then(m => m.SegmentModule)},
    { path: 'base_configuration', loadChildren: () => import('./base_configuration/configuration.module').then(m => m.ConfigurationModule) },
    { path: 'base_config_cash_miles', loadChildren: () => import('./base_configuration/cash-miles-rules/base-cash-miles-rules.module').then(m => m.base_cash_miles_rules_module) },
    { path: 'coupon_funding_partner', loadChildren: () => import('./masters/coupon_funding_partner/coupon_funding_partner.module').then(m => m.CouponFundingPartnerModule) },
    { path: 'cash_miles_rules', loadChildren: () => import('./cash-miles-rules/cash-miles-rules.module').then(m => m.cash_miles_rules_module)},
    { path: 'enhancement_code', loadChildren: () => import('./masters/status_enhancement_code/enhancement-code.module').then(m => m.EnhancementCodeModule) },
    { path: 'voucher_type', loadChildren: () => import('./masters/voucher_type/voucher-type.module').then(m => m.VoucherTypeModule) },
    { path: 'config', loadChildren: () => import('./masters/config_id/config-id.module').then(m => m.ConfigIdModule) },
    { path: 'miles_for_cash', loadChildren: () => import('./miles_for_cash/miles-for-cash.module').then(m => m.MilesforcashModule) },
    { path: "round_off_accrual_rule/:engine_type/:transaction_type/:lob_id", component: RoundOffMechanismComponent, canActivate: [AuthGuard] },
	{ path: "round_off_redemption_rule/:engine_type/:transaction_type/:lob_id", component: RoundOffMechanismComponent, canActivate: [AuthGuard] },
    { path: "round_off_cash_miles_rule/:engine_type/:transaction_type/:lob_id", component: RoundOffMechanismComponent, canActivate: [AuthGuard] },
    { path: "round_off_accrual_coupon/:engine_type/:transaction_type/:lob_id", component: RoundOffMechanismComponent, canActivate: [AuthGuard] },
    { path: "round_off_redemption_coupon/:engine_type/:transaction_type/:lob_id", component: RoundOffMechanismComponent, canActivate: [AuthGuard] },
    { path: "round_off_miles_for_cash_rule/:engine_type/:transaction_type/:lob_id", component: RoundOffMechanismComponent, canActivate: [AuthGuard] },


];
@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		LoginComponent,
		TenantBaseConfigurationComponent,
		GraphsComponent,
		DownloadManagerComponent,
		FlashMessageComponent,
	],
	imports: [
		RouterModule.forRoot(appRoutes),
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
	],
	entryComponents: [
		AppComponent
	],
	providers: [
		FlashMessageService,
		DatePipe,
		AuthGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
