import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageServiceModule } from 'angular-webstorage-service';
import { ChartsModule } from 'ng2-charts';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown'
// import { CKEditorModule } from 'ng2-ckeditor';
import { TreeModule } from 'angular-tree-component';
import { Ng2AutoCompleteModule } from "ng2-auto-complete";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputFieldErrorMessageComponent } from '../libraries/input-field-error-message/input-field-error-message.component';
import { CcLoaderComponent } from '../libraries/cc-loader/cc-loader.component';
import { PaginationComponent } from '../pagination/pagination.component';
import { CustomerService } from '../tenant_customer/customer.service';
import { TooltipModule } from "ngx-tooltip";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TagInputModule } from 'ngx-chips';
import { AgmCoreModule } from '@agm/core';
import { FileInputAccessorModule } from "file-input-accessor";
import { UiSwitchComponent } from '../libraries/ui-switch/ui-switch.component';
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { AngularDateTimePickerModule } from "angular2-datetimepicker";
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgxPaginationModule } from 'ngx-pagination';
import { Globals } from "../constants/globals";
import { UtcDatePipe } from '../customPipes/utc-date.pipe';
import { CronSelectionModule } from 'angular2-cron-jobs';
// custom pipes
import { KeyPipe } from '../pipes/key.pipe';
import { CapitalizeFirstPipe } from '../pipes/capitalize-first.pipe';
// material
import {
	MatButtonModule,
	MatFormFieldModule,
	MatInputModule,
	MatRippleModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatCheckboxModule,
	MatDialogModule
} from '@angular/material';
//Services
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from "../services/ApiService/api-service.service";
import { MerchantService } from '../tenant_merchant/merchant.service';
import { TenantRuleService } from '../tenant_rule/tenant-rule.service';
import { ConfigurationService } from '../tenant_base_configuration/configuration.service';
import { TierService } from '../customer_tier_management/tier.service';
import { PointTypeMasterService } from '../point_type_master/point-type-master.service';
import { PointDefinitionMasterService } from '../point_definition_master/point-definition-master.service';
import { PointService } from '../point/point.service';
import { EmailTemplateService } from '../tenant_email_template/email-template.service';
import { SmsTemplateService } from '../tenant_sms_template/sms-template.service';
import { WalletService } from '../tenant-wallet/wallet.service';
import { ExcelFileExportService } from '../../../src/app/excel-file-export.service';
import { DashBoardService } from '../../app/dashboard/dash-board.service'
import { SettingsService } from '../../app/settings/settings.service';
import { VelociytSerices } from '../../app/settings/Velocity_check/Velocity.service';
import { ReportsService } from '../tenant_report/reports.service';
import { RedemptionRulesService } from '../redemption-rules/redemption-rules.service';
import { FormDataProviderService } from '../services/form-data-provider/form-data-provider.service';
import { EmailConfigurationService } from '../configuration/email_configuration/email-configuration.service';
import { PagerService } from '../services/pager.service';
import { DownloadManagerService } from '../download-manager/download-manager.service';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { UserService } from '../services/user.service';
import { Add_earning_point_rule } from '../models/add_earning_point_rule';
import { Add_tier } from '../models/add_tier';
import { TenantUserService } from '../tenant-user-management/tenant-user.service';
import { RoleService } from '../tenant_user_role_management/role.service';
import { ProgramService } from '../tenant-program/program.service';
import { FlatFileModelService } from '../flat-file-model/flat-file-model.service';
import { TagService } from '../tags/tag.service';
import { AuthGuard } from '../guard/auth.guard';
import { CcRulesModuleComponent } from '../cc-rules-module/cc-rules-module.component';
import { RuleModalComponent } from '../cc-rules-module/rule-modal/rule-modal.component';
import { CcRulesUiComponent } from '../cc-rules-module/cc-rules-ui/cc-rules-ui.component';
import { SearchComponentComponent } from '../libraries/search-component/search-component.component';
import { RuleQueryComponent } from '../rule-query/rule-query.component';
import { UrlLinkDirective } from '../url-link.directive';
import { ManageCategoriesFormComponent } from '../tenant_base_configuration/manage-categories-form/manage-categories-form.component';
import { ManageSubCategoriesFormComponent } from '../tenant_base_configuration/manage-sub-categories-form/manage-sub-categories-form.component';
import { ActivityService } from '../activities/activity.service';
import { DeveloperService } from '../developer_panel/developer-service/developer.service';
import { DashboardComponent } from '../../app/dashboard/dashboard.component';
import { PushNotificationService } from '../push-notification/push-notification.service';

import { CountryService } from '../country/country.service';
import { TinyEditorcomponent } from '../tiny-editor/tinyeditor.component';
import { PartnerService } from '../partner/partner.service';
import { CommissionService } from '../commission/commission.service';
import { AffiliateReportService } from '../affiliate-reports/affiliate-report.service';
import { CampaignService } from '../referral_campaign/campaign.service';
import { CountryBulkUpdateService } from '../country/country-bulk-update-upload-form/country-bulk-update-upload-model.service';
import { LinkDirective } from '../link.directive';
import { FlashMessageService } from '../libraries/flash-message/services/flash-message.service';
import { LanguageService } from '../language-master/language.service';
import { CustomRoutes } from '../custom-routes';
import { LanguageListComponent } from '../language-master/language-list/language-list.component';
import { ReferralProgramTypeService } from '../referral-program-type/referral-program-type.service';
import { ReferralService } from '../referral/referral.service';
import { DateTimePickerComponent } from '../libraries/date-time-picker/date-time-picker.component';
// import { FormComponentStruct } from '../libraries/form-component-struct';
import { NotificationService } from '../notification/notification.service';
import { PromocodeService } from '../promocodes/promocode.service';
import { RoundOffMechanismComponent } from "../round-off-mechanism/round-off-mechanism.component";

import { CohortService } from '../cohort/cohort.service';
import { ConfirmationPopUpComponent } from '../libraries/confirmation-pop-up/confirmation-pop-up.component';
import {PopupListComponent} from '../libraries/popup-modal/popup-list.component';

import { LobService} from '../../app/masters/lob/lob.service';
import { FundingPartnerService } from '../../app/masters/funding_partner/funding-partner.service';
import { SegmentService } from "../../app/customer_segment/segment.service";
import { FundingAttributesService } from "../../app/masters/funding_attributes/funding-attributes.service";
import { GroupsetService } from "../../app/masters/groupset/groupset.service";
import { AttributesService } from "../../app/masters/attributes/attributes.service";
import { RewardEngineService } from "../../app/reward_engine/reward-engine.service";
import { BaseConfigurationService} from '../base_configuration/configuration.service';







// import { ApiValidatorComponent } from '../developer_panel/api-validator/api-validator.component';
@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatRippleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCheckboxModule,
		TooltipModule,
		NgCircleProgressModule.forRoot({
			// set defaults here
			radius: 100,
			outerStrokeWidth: 16,
			innerStrokeWidth: 8,
			outerStrokeColor: "#78C000",
			innerStrokeColor: "#C7E596",
			animationDuration: 300,
			imageHeight: 20,
			imageWidth: 20,
		}),
		TagInputModule,
		HttpClientModule,
		StorageServiceModule,
		ChartsModule,
		AngularMultiSelectModule,
		// CKEditorModule,
		Ng2AutoCompleteModule,
		TreeModule,
		NgSelectModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyCJZdl07xzgb8_wHc6PX-un8Jx3PxDY4_I',
			libraries: ["places"]
		}),
		MyDatePickerModule,
		MyDateRangePickerModule,
		AngularDateTimePickerModule,
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		NgxPaginationModule,
		CommonModule,
		MatDialogModule,
		CronSelectionModule,
		FileInputAccessorModule,
		// FlashMessageService,
		// CustomRoutes
		NgMultiSelectDropDownModule.forRoot()
	],
	declarations: [
		PaginationComponent,
		CcLoaderComponent,
		InputFieldErrorMessageComponent,
		CcRulesModuleComponent,
		CcRulesUiComponent,
		RuleModalComponent,
		CapitalizeFirstPipe,
		KeyPipe,
		UiSwitchComponent,
		LanguageListComponent,
		// FlashMessageComponent,
		UtcDatePipe,
		SearchComponentComponent,
		RuleQueryComponent,
		UrlLinkDirective,
		ManageCategoriesFormComponent,
		ManageSubCategoriesFormComponent,
		// ApiValidatorComponent,
		DashboardComponent,
		// FormComponentStruct,
		TinyEditorcomponent,
		LinkDirective,
		DateTimePickerComponent,
		RoundOffMechanismComponent,
		ConfirmationPopUpComponent,
		PopupListComponent
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		InputFieldErrorMessageComponent,
		CcRulesModuleComponent,
		CcRulesUiComponent,
		RuleModalComponent,
		CcLoaderComponent,
		PaginationComponent,
		LanguageListComponent,
		// FormComponentStruct,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatRippleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCheckboxModule,
		TooltipModule,
		NgCircleProgressModule,
		TagInputModule,
		CapitalizeFirstPipe,
		KeyPipe,
		HttpClientModule,
		StorageServiceModule,
		ChartsModule,
		AngularMultiSelectModule,
		// CKEditorModule,
		Ng2AutoCompleteModule,
		TreeModule,
		NgSelectModule,
		AgmCoreModule,
		UiSwitchComponent,
		// FlashMessageComponent,
		UtcDatePipe,
		MyDatePickerModule,
		MyDateRangePickerModule,
		AngularDateTimePickerModule,
		FroalaEditorModule,
		FroalaViewModule,
		NgxPaginationModule,
		MatDialogModule,
		CronSelectionModule,
		FileInputAccessorModule,
		CommonModule,
		SearchComponentComponent,
		RuleQueryComponent,
		UrlLinkDirective,
		ManageCategoriesFormComponent,
		DashboardComponent,
		ManageSubCategoriesFormComponent,
		// ApiValidatorComponent,
		TinyEditorcomponent,
		DateTimePickerComponent,
		ConfirmationPopUpComponent,
		PopupListComponent,
		RoundOffMechanismComponent
		// LinkDirective,
		// FlashMessageService,
		// UserService,
		// CustomRoutes
	],
	providers: [
		CustomerService,
		CookieService,
		DatePipe,
		// FlashMessageService,
		PagerService,
		ApiService,
		PaginationComponent,
		Globals,
		Custom_api_calling,
		// UserService,
		AuthGuard,
		Add_earning_point_rule,
		Add_tier,
		MerchantService,
		TenantRuleService,
		BaseConfigurationService,
		TierService,
		PointTypeMasterService,
		PointDefinitionMasterService,
		PointService,
		EmailTemplateService,
		SmsTemplateService,
		TenantUserService,
		RoleService,
		ProgramService,
		ExcelFileExportService,
		WalletService,
		DashBoardService,
		FlatFileModelService,
		SettingsService,
		VelociytSerices,
		EmailConfigurationService,
		TagService,
		DownloadManagerService,
		ReportsService,
		RedemptionRulesService,
		FormDataProviderService,
		// CustomRoutes,
		ActivityService,
		DeveloperService,
		PushNotificationService,
		CountryService,
		PartnerService,
		CommissionService,
		AffiliateReportService,
		CampaignService,
		CountryBulkUpdateService,
		LanguageService,
		ReferralProgramTypeService,
		ReferralService,
		NotificationService,
		PromocodeService,
		CohortService,
		LobService,
		FundingPartnerService,
		SegmentService,
		FundingAttributesService,
		GroupsetService,
		AttributesService,
		RewardEngineService,
		UserService,
		ConfigurationService
		
	]
})
export class SharedModule { }
