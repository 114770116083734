// import { Component, OnInit } from '@angular/core';
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-cc-loader',
  templateUrl: './cc-loader.component.html',
  styleUrls: ['./cc-loader.component.css']
})
export class CcLoaderComponent {

  constructor() { }

  ngOnInit() {
  }

}
