import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';
@Injectable()
export class VelociytSerices {

    constructor(private customApiCalling: Custom_api_calling) { }


    update_velocity(form_data, callback) {
        this.customApiCalling.http_calling_file_post("api/tenant/velocity_check/update_velocity", form_data, (result) => callback(result));
    }


    get_velocity(callback) {
        this.customApiCalling.http_calling_get("api/tenant/velocity_check/get_velocity_details_by_id", (result) => callback(result));
    }



};
