import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';

@Injectable()
export class EmailConfigurationService {

  constructor(private customCallingApi: Custom_api_calling) { }

  add_email_providers(data,callback){
    let emailProvidersApi: string = 'api/tenant/email_providers/add_email_providers';
    this.customCallingApi.http_calling_file_post(emailProvidersApi, data.body, (result) => {
        callback(result);       
    })
  }
  get_email_providers_list(data, callback) {
    this.customCallingApi.http_calling_get("api/tenant/email_providers/get_email_providers_list", (result) => callback(result));
  }
  
  get_email_provider(email_provider_id, callback) {
    this.customCallingApi.http_calling_get("api/tenant/email_providers/get_email_provider?email_provider_id=" + email_provider_id, (result) => callback(result));
  }
  edit_email_provider(data, callback) {
    this.customCallingApi.http_calling_file_put("api/tenant/email_providers/edit_email_provider", data.body, (result) => callback(result));
  }
}
