import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()

export class RedemptionRulesService {

    constructor(private customCallingApi: Custom_api_calling) {

    }

    public loadRedemptionRules(query_data: any, callback) {
        let redemptionRulesListApi: string = 'api/tenant/redemption_rules/get_redemption_rules_list?search=' + query_data['search'] + '&&group_code_id=' + query_data['group_code_id'];
        this.customCallingApi.http_calling_get(redemptionRulesListApi, (data) => {
            callback(data);
        });
    }

    public fetchRedemptionRule(redemption_rule_id: number, callback) {
        let redemptionRuleApi: string = 'api/tenant/redemption_rules/get_redemption_rule/' + redemption_rule_id;

        this.customCallingApi.http_calling_get(redemptionRuleApi, (data) => {
            callback(data['values'][0]);
        });
    }

    public fetchRuleFormData(callback: Function) {
        this.customCallingApi.http_multi_url_get({
            'customer_tiers': 'api/tenant/customer_tiers/get_customer_tier_name_list',
            'group_codes': 'api/tenant/redemption_rules/get_group_codes',
        }, callback)
    }

    public fetchMerchantDropdownData(callback) {
        let dropdownApi: string = 'api/tenant/redemption_rules/get_merchant_list'

        this.customCallingApi.http_calling_get(dropdownApi, (data) => {
            callback(data);
        });
    }

    public fetchCategoryDropdownData(callback) {
        let dropdownApi: string = 'api/tenant/redemption_rules/get_category_list'

        this.customCallingApi.http_calling_get(dropdownApi, (data) => {
            callback(data);
        });
    }

    public fetchSkuDropdownData(identifier: number, callback) {
        const dropdownApi: string = 'api/tenant/redemption_rules/get_sku_list';
        const body = { group_code_identifier: identifier };

        this.customCallingApi.http_calling_file_post(dropdownApi, body, (data) => {
            callback(data);
        });
    }

    public fetchGroupCodeList(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/redemption_rules/get_group_codes?' + query_data, (result) => {
            callback(result);
        });
    }

    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

}
