import { Injectable } from '@angular/core';
import { Custom_api_calling } from "../../adapters/Custom_api_calling";

@Injectable()
export class ApiService {

	constructor(private api_adapter: Custom_api_calling) {
	}

	/*---------------------------------------*/
	/*---- Admin  User Role API's Start -----*/

	/*---------------------------------------*/

	get_merchant_init_form_information(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/merchant/initial_form_info", (result) => callback(result));
	}




	get_merchant_categories(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/merchant/get_specific_merchant_information_for_voucher?merchant_id=" + data.merchant_id, (result) => callback(result));
	}


	get_products(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/products/get_product_list?" + data.queryParam, (result) => callback(result));
	}

	get_products_brg_list(data, callback) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		this.api_adapter.http_calling_get("api/tenant/products/brg_list?" + query_data, (result) => callback(result));
	}


	get_vouchers_list(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/voucher/get_vouchers_list", (result) => callback(result));
	}

	get_vouchers_brg_list(data, callback) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		this.api_adapter.http_calling_get("api/tenant/voucher/brg_list?" + query_data, (result) => callback(result));
	}

	/*---------------------------------------*/
	/*--- Gift Card Category API's Start ----*/


	get_tenant_customer_list(data, callback) {
		let ListApi: string = 'api/tenant/customers/getTenantCustomersList';
		this.api_adapter.http_calling_get(ListApi, (result) => callback(result));
	}



	/*---------------------------------------*/
	/*------- Categories API's Start --------*/

	/*---------------------------------------*/

	save_categories(data, category_id, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/category/editCategory/" + category_id, data, (result) => callback(result));
	}

	create_category_or_sub_category(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/category/addCategory", data, (result) => callback(result));
	}

	get_sub_categories_by_category_id(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/category/get_sub_categories_by_category_id?" + query_data, (result) => callback(result));
	}
	get_category_id(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/category/getCategoryById/" + data.category_id, (result) => callback(result));
	}
	get_sub_category_id(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/category/get_sub_category_id?" + query_data, (result) => callback(result));
	}
	/*---------------------------------------*/
	/*------- Categories API's End --------*/
	/*---------------------------------------*/


	/*---------------------------------------*/
	/*------ HTML Templates API's Start -----*/

	/*---------------------------------------*/

	save_html_template(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/html_templates", data.body, (result) => callback(result));
	}


	get_html_templates(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/html_templates?" + query_data, (result) => callback(result));
	}

	/*---------------------------------------*/
	/*----- HTML Templates API's End --------*/

	/*---------------------------------------*/

	/*---------------------------------------*/
	/*----- Customer Segments API's End --------*/

	/*---------------------------------------*/
	create_rule_customer_segment(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/customer_rule_segments", data.body, (result) => callback(result));
	}

	modify_rule_customer_segment(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/customer_rule_segments", data.body, (result) => callback(result));
	}


	get_customer_rule_segment_details(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/customer_rule_segments?" + query_data, (result) => callback(result));
	}



	get_customer_rule_segments(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/customer_rule_segments/list?" + query_data, (result) => callback(result));
	}



	/*---------------------------------------*/
	/*----- Customer Segments API's End --------*/

	/*---------------------------------------*/

	/*---------------------------------------*/
	/*----- Email Campaign API's Start ------*/

	/*---------------------------------------*/


	create_email_campaign(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/email_campaign", data.body, (result) => callback(result));
	}

	modify_email_campaign(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/email_campaign", data.body, (result) => callback(result));
	}

	get_email_campaign_list(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/email_campaign/list", (result) => callback(result));
	}

	send_email_campaign(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/email_campaign/send?" + query_data, (result) => callback(result));
	}

	get_email_campaign_form_primary_data(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/email_campaign/get_primary_form_data?" + query_data, (result) => callback(result));
	}


	get_email_campaign_log(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/email_campaign/log?" + query_data, (result) => callback(result));
	}


	get_email_campaign_email_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/email_campaign/email_list?" + query_data, (result) => callback(result));
	}

	get_email_campaign_info(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/email_campaign?" + query_data, (result) => callback(result));
	}

	/*----- Cron Jobs API's Start ------*/
	add_cron_job(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/cron", data.body, (result) => callback(result));
	}

	modify_cron_job(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/cron", data.body, (result) => callback(result));
	}

	cron_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/cron/list?" + query_data, (result) => callback(result));
	}
	start_or_stop_cron(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/cron/action", data.body, (result) => callback(result));
	}
	get_cron_details(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/cron?" + query_data, (result) => callback(result));
	}
	/*----- Cron Jobs API's End ------*/

	/*----- API Permissions Start ------*/
	get_permissions_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/api_permissions/list?" + query_data, (result) => callback(result));
	}

	update_role_privilage(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/api_permissions", data.body, (result) => callback(result));
	}

	update_status(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/developer_panel/status", data.body, (result) => callback(result));
	}

	check_permission_token(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/api_permissions/check_token?" + query_data, (result) => callback(result));
	}
	/*----- API Permissions End ------*/

	/*----- Developer Panel APIS Start ------*/
	get_tenant_types(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/developer_panel/tenant_types?" + query_data, (result) => callback(result));
	}
	getAllRoutes(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/developer_panel/routes?" + query_data, (result) => callback(result));
	}
	updateAllRoutes(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/developer_panel/routes", data.body, (result) => callback(result));
	}
	update_route_group_permission(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/developer_panel/routes/update_api_permission_group", data.body, (result) => callback(result));
	}
	update_route_permission_type(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/developer_panel/routes/update_api_permission_type", data.body, (result) => callback(result));
	}
	update_route_group_code(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/developer_panel/routes/update_group_codes", data.body, (result) => callback(result));
	}

	update_route_valid_permission(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/developer_panel/routes/update_route_valid_permission", data.body, (result) => callback(result));
	}
	update_enable_disabled_route(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/developer_panel/routes/enable_route", data.body, (result) => callback(result));
	}
	/*----- Developer Panel APIS End ------*/

	get_routes(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/routes?" + query_data, (result) => callback(result));
	}
	deleteFCMToken(callback) {
		this.api_adapter.http_calling_get("api/tenant/delete_fcm_token", (result) => callback(result));
	}

	get_heatmap(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/heatmap?" + query_data, (result) => callback(result));
	}

	get_customer_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/customers/getCustomersList?" + query_data, (result) => callback(result));
	}


	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
				query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}

	downgrade_tier(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/customers/customerDowngradeTier", data, (result) => callback(result));
	}

	get_accrued_point_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/point/get_accrued_point_list?" + query_data, (result) => callback(result));
	}

	get_redeem_point_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/point/get_redeemed_point_list?" + query_data, (result) => callback(result));
	}

	get_redeem_point(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/point/get_redeemed_point?" + query_data, (result) => callback(result));
	}

	get_accrued_point(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/point/get_accrued_point?" + query_data, (result) => callback(result));
	}


	// get state list based on country
	getStateListOnCountry(country_id, callback) {
		this.api_adapter.http_calling_get('api/tenant/state/get_state_by_country_id/' + country_id, (result) => callback(result));
	}

	// get city list based on state
	getCityListOnState(state_id, callback) {
		this.api_adapter.http_calling_get('api/tenant/city/get_city_by_state_id/' + state_id, (result) => callback(result));
	}


	save_recommended_info(data, callback) {
		this.api_adapter.http_calling_file_post('api/tenant/recommended/save_recommended_info/', data, (result) => {
			callback(result)
		});
	}

	remove_recommended_info(data, callback) {
		this.api_adapter.http_calling_file_put('api/tenant/recommended/remove_recommended_info/', data, (result) => {
			callback(result)
		});
	}

	get_recommended_info(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get('api/tenant/recommended/get_recommended_info?' + query_data, (result) => {
			callback(result)
		});
	}

	modify_recommended_order(data, callback) {
		this.api_adapter.http_calling_file_put('api/tenant/recommended/modify_recommended_order/', data, (result) => {
			callback(result)
		})
	}

	get_activity_email_logs(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/email_template/get_activity_email_logs?" + query_data, (result) => callback(result));
	}

	get_activity_notification_logs(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/notification_template/get_activity_notification_logs?" + query_data, (result) => callback(result));
	}

	get_activity_sms_logs(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/sms/get_sms_activities_list?" + query_data, (result) => callback(result));
	}

	create_brand(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/brand/add_brand", data.body, (result) => callback(result));
	}

	modify_brand(data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/brand/edit_brand/" + data.brand_id, data.body, (result) => callback(result));
	}

	get_brand(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/brand/get_brand_by_id/" + data, (result) => callback(result));
	}

	get_all_brand(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/brand/get_brand_list?" + query_data, (result) => callback(result));
	}

	// need to be change api path member type edit by
	create_member_type(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/member_type/addMemberType", data.body, (result) => callback(result));
	}

	modify_member_type(id, data, callback) {
		this.api_adapter.http_calling_file_put("api/tenant/member_type/editMemberType/" + id, data.body, (result) => callback(result));
	}

	get_member_By_id(data, callback) {
		this.api_adapter.http_calling_get("api/tenant/member_type/getMemberTypeById/" + data, (result) => callback(result));
	}

	get_all_member_type(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/member_type/getMemberType?" + query_data, (result) => callback(result));
	}

	get_member_type(callback) {
		this.api_adapter.http_calling_get('api/tenant/reports/get_member_type?', (result) => {
			callback(result)
		});
	}


	run_now(url, callback) {
		this.api_adapter.http_calling_get(url, (result) => {
			callback(result)
		});
	}

	get_login_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_login_report_list?" + query_data, (result) => callback(result));
	}

	get_accrual_point_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_accrual_point_request_list?" + query_data, (result) => callback(result));
	}

	get_redemption_point_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_redemption_point_request_list?" + query_data, (result) => callback(result));
	}

	// accrual_transaction_approved(data, callback) {

	// 	this.api_adapter.http_calling_get("api/tenant/reports/accrual_approved_transaction"+data, (result) => callback(result));
	// }
	accrual_transaction_approved(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/reports/accrual_approved_transaction", data, (result) => callback(result));
	}

	accrual_transaction_reject(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/accrual_reject_transaction?" + query_data, (result) => callback(result));
	}

	redemption_transaction_approved(data, callback) {
		this.api_adapter.http_calling_file_post("api/tenant/reports/redemption_approved_transaction", data, (result) => callback(result));
	}
	// redemption_transaction_approved(data, callback) {
	// 	this.api_adapter.http_calling_get("api/tenant/reports/redemption_approved_transaction/"+data, (result) => callback(result));
	// }

	redemption_transaction_reject(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/redemption_reject_transaction?" + query_data, (result) => callback(result));
	}

	get_login_reports(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_login_report_list?" + query_data, (result) => callback(result));
	}

	get_customer_profle_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_customer_profile_reports?" + query_data, (result) => callback(result));
	}

	export_profile_Report(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_customer_profile_reports?" + query_data, (result) => callback(result));
	}

	get_fraud_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_fraud_reports?" + query_data, (result) => callback(result));
	}
	get_all_activity(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_all_fraud_activity?" + query_data, (result) => callback(result));
	}
	export_fraud_Report(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_fraud_reports?" + query_data, (result) => callback(result));
	}

	get_tier_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_tier_reports?" + query_data, (result) => callback(result));
	}

	export_tier_Report(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_tier_reports?" + query_data, (result) => callback(result));
	}


	recon_view_report(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/recon_view_report?" + query_data, (result) => callback(result));
	}

	export_recon_view_report(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/recon_view_report?" + query_data, (result) => callback(result));
	}


	get_scan_list(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_scan_reports?" + query_data, (result) => callback(result));
	}

	export_scan_Report(data, callback) {
		let query_data = this.get_query_data(data);
		this.api_adapter.http_calling_get("api/tenant/reports/get_scan_reports?" + query_data, (result) => callback(result));
	}

}