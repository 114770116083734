import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { UserService } from "./services/user.service";
import { CustomRoutes } from './custom-routes';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	logged_in: boolean;
	isBaseConfigurationComplete: boolean = true;
	loader_status: boolean = true;
	constructor(private router: Router, private userService: UserService) {
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loader_status = true;
					break;
				}
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.loader_status = false;
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	ngOnInit() {
		CustomRoutes.router = this.router;
		if (!this.userService.getUserLoggedIn()) {
			CustomRoutes.navigate([CustomRoutes.routes.login]);
			this.logged_in = false;
		} else {
			this.logged_in = true;
			this.userService.loadLoggedInTenant(() => { this.isBaseConfigurationComplete = this.userService.getIsBaseConfigurationComplete() });
			
		}
	}

	userIsLogged() {
		return this.logged_in;
	}
}
