import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class CountryService {

  constructor(private api_adapter: Custom_api_calling) {
  }

  get_all_country(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/country/get_country_list?" + query_data, (result) => callback(result));
   
  }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }

  update_convert_rate(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/country/update_convert_rate", data, (result) => callback(result));
  }

  create_country(data, callback) {
    console.log("datata dsffdssddsfds",data);
    this.api_adapter.http_calling_file_post("api/tenant/country/add_country", data, (result) => callback(result));
  }

  modify_country(data, callback) {
    console.log(" modify_country data",data);
    this.api_adapter.http_calling_file_put("api/tenant/country/edit_country/"+ data.country_id, data, (result) => callback(result));
  }
  get_country(data, callback) {
    this.api_adapter.http_calling_get("api/tenant/country/get_country_by_id/" + data, (result) => callback(result));
  }
  
}
