import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from '../services/ApiService/api-service.service';
import { UserService } from '../services/user.service';

@Injectable()
export class MerchantService {
    merchants = new BehaviorSubject({});
    castMerchants = this.merchants.asObservable();

    allMerchantsBranches = new BehaviorSubject({});
    castAllMerchantsBranches = this.allMerchantsBranches.asObservable();

    merchantsProfileData = new BehaviorSubject({});
    castMerchantsProfileData = this.merchantsProfileData.asObservable();


    //store data
    merchant_primary_info: any = {};
    constructor(private customCallingApi: Custom_api_calling, private apiService: ApiService, private userService: UserService) { }


    // loadMerchants(searchParam:string){
    //     let merchantListApi:string = 'api/tenant/merchant/get_merchant_list?search='+searchParam;
    //     this.customCallingApi.http_calling_get(merchantListApi,(data)=>{
    //         console.log('merchant',data);
    //         if(data['status'] == true)        
    //             this.merchants.next(data['values']);
    //     });
    // }


    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

    loadMerchants(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/merchant/get_merchant_list?' + query_data, (result) => {
            callback(result)
        });
    }
    loadAllMerchantsBranches(searchParam: string) {
        let merchantsBranchesApi: string = 'api/tenant/merchant/get_merchant_branches?search=' + searchParam;
        this.customCallingApi.http_calling_get(merchantsBranchesApi, (data) => {
            if (data['status'] == true)
                this.allMerchantsBranches.next(data['values']);
        });
    }

    loadMerchantProfileInfo(merchantId: any) {
        let merchantsProfileInfoApi: string = 'api/tenant/merchant/get_merchant?merchant_id=' + merchantId;
        this.customCallingApi.http_calling_get(merchantsProfileInfoApi, (data) => {
            if (data['status'] === true) {
                this.merchantsProfileData.next(data['values'][0]);
            }
        });
    }
    getMerchantOffer(data, callback) {
        this.customCallingApi.http_calling_get("api/tenant/merchant/get_merchant_offers?merchant_id=" + data.merchant_id, (result) => callback(result));
    }
    saveMerchantPointRate(data, callback) {
        this.customCallingApi.http_calling_file_post('api/tenant/merchant/modify_merchant_point_rate?tenants_assigned_merchant_id=' + data.tenants_assigned_merchant_id, data.body, callback)
    }




    // private merchant
    public loadPrimaryInformation(data, callback: Function) {
        this.customCallingApi.http_multi_url_get({
            'countries': 'api/tenant/country/get_country_list',
            'categories': 'api/tenant/category/getAllCategory',
            'sub_categories': 'api/tenant/category/get_category_list?type=all'
        }, callback)
    }

    get_merchant_form_initial_information(data, callback) {
        this.customCallingApi.http_multi_url_get({
            'merchant_account_type_master': 'api/tenant/merchant/get_merchant_acc_type_master',
            'master_tenant_type': 'api/tenant/merchant/get_merchant_type_master',
            'merchant_tier': 'api/tenant/merchant/get_merchant_tiers'
        }, callback)
    }

    set_data(item, data) {
        this.merchant_primary_info[item] = data;
    }

    get_data() {
        return this.merchant_primary_info;
    }

    add_private_merchant(data, callback) {
        this.customCallingApi.http_calling_file_post("api/tenant/merchant/add_private_merchant", data, (result) =>
            callback(result));
    }

    modify_private_merchant(data, callback) {
        this.customCallingApi.http_calling_json_put("api/tenant/merchant/modify_private_merchant", data, (result) =>
            callback(result));
    }

    getPrivateMerchantInfoById(data, callback) {
        this.customCallingApi.http_calling_get("api/tenant/merchant/get_merchant?merchant_id=" + data.merchant_id + '&&is_private=' + data.is_private, (result) => callback(result));
    }

    create_merchant_branch(data, callback) {
        this.customCallingApi.http_calling_file_post("api/tenant/merchant/create_merchant_branch", data.body, (result) =>
            callback(result));
    }

    modify_merchant_branch(data, callback) {
        this.customCallingApi.http_calling_json_put("api/tenant/merchant/modify_merchant_branch", data.body, (result) =>
            callback(result));
    }

    get_merchant_branches_list(data, callback) {
        this.customCallingApi.http_calling_get("api/tenant/merchant/get_private_merchant_branches?merchant_id=" + data.merchant_id + '&&is_private=' + data.is_private, (result) => callback(result));
    }


    create_sub_merchant(data, callback) {
        this.customCallingApi.http_calling_file_post("api/tenant/merchant/sub_merchant", data.body, (result) =>
            callback(result));
    }

    modify_sub_merchant(data, callback) {
        this.customCallingApi.http_calling_json_put("api/tenant/merchant/modify_sub_merchant", data.body, (result) =>
            callback(result));
    }

    sub_merchant_list(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get("api/tenant/merchant/sub_merchant_list?" + query_data, (result) => callback(result));
    }

    get_merchant_bank_details(data, callback) {
        this.customCallingApi.http_calling_get("api/tenant/merchant/get_merchant_bank_details_by_id?bank_id=" + data.bank_id,
            (result) => callback(result));
    }


    add_bank_details(data, callback) {
        this.customCallingApi.http_calling_file_post("api/tenant/merchant/add_merchant_bank", data, (result) =>
            callback(result));
    }

    edit_bank_details(data, callback) {
        this.customCallingApi.http_calling_json_put("api/tenant/merchant/modify_merchant_bank", data, (result) =>
            callback(result));
    }

    getMerchantTypeList(data, callback) {
        let query_data = this.apiService.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/merchant_type/get_merchant_type?' + query_data, callback);
    }
    MerchantTypeInfo = new BehaviorSubject({});
    castMerchantTypeInfo = this.MerchantTypeInfo.asObservable();

    fetchSelectedMemberType(code_id: any) {
        let memberTypeInfoApi: string = 'api/tenant/merchant_type/get_merchant_type_by_id/' + code_id;
        this.customCallingApi.http_calling_get(memberTypeInfoApi, (data) => {
            this.MerchantTypeInfo.next(data);
        });
    }

    getCountryList(params, callback) {
        this.customCallingApi.http_calling_get('api/tenant/country/get_country_list', (result) => {
            callback(result);
        });
    }
}
