import { Component, OnInit, Input } from '@angular/core';
import { ComponentFixtureAutoDetect } from '@angular/core/testing';
import { Constants } from '../../constants/constants';


interface RuleFilter {
	operators: any[],
	filterSlug: string,
	filterName: string,
	values?: any[],
	type?: string,
}

interface RuleFilterCollection {
	customer_profile: Array<RuleFilter>,
	system_activities?: Array<RuleFilter>,
	customer_history?: Array<RuleFilter>,
	time_period?: Array<RuleFilter>,
	product_purchases?: Array<RuleFilter>,
	voucher_purchases?: Array<RuleFilter>,
}

interface RuleResult {
	operator: string,
	logicalOperator?: string,
	filterSlug: string,
	filterType: string,
	ruleValues?: any,
	child?: Array<RuleResult>,
	filterIndex?: number,
	disableAndOr?: boolean,
}

@Component({
	selector: 'app-cc-rules-ui',
	templateUrl: './cc-rules-ui.component.html',
	styleUrls: ['./cc-rules-ui.component.css']
})
export class CcRulesUiComponent implements OnInit {
	commonOperators: Array<string> = ['==', '<=', '>=', '!='];
	myDatePickerOptions = Constants.myDatePickerOptions;
	@Input() componentId: number = 0;

	ruleFilters: RuleFilterCollection = {
		'customer_profile': [{
			operators: this.commonOperators,
			filterSlug: 'age',
			filterName: 'Age',
		}, {
			operators: ['=='],
			filterSlug: 'gender',
			filterName: 'Gender',
			values: ['male', 'female'],
			type: 'selectBox'
		}, {
			operators: ['=='],
			filterSlug: 'age_group',
			filterName: 'Age Group',
			values: ['1-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-90'],
			type: 'selectBox'
		}, {
			operators: ['=='],
			filterSlug: 'birthday',
			filterName: 'Birthday',
			type: 'selectBox',
			values: ['true'],
		}, {
			operators: ['=='],
			filterSlug: 'anniversary',
			filterName: 'Anniversary',
			type: 'selectBox',
			values: ['true'],
		}, {
			operators: this.commonOperators,
			filterSlug: 'location',
			filterName: 'location',
		}],
		'system_activities': [{
			operators: ['=='],
			filterSlug: 'product_purchases',
			filterName: 'Product Purchases',
			type: "selectBox",
			values: ['true'],
		}, {
			operators: ['=='],
			filterSlug: 'voucher_purchases',
			filterName: 'Voucher Purchases',
			type: "selectBox",
			values: ['true'],
		}],
		'customer_history': [],
		'time_period': [{
			operators: this.commonOperators,
			filterSlug: 'time_period',
			filterName: 'Time Period',
			type: "selectBox",
			values: ['10 days', '20 days', '30 days', '40 days', '50 days', '60 days', '70 days'],
		}],
		'product_purchases': [{
			operators: ['==', 'In', 'notIn'],
			filterSlug: 'merchant_id',
			filterName: 'Merchant',
		}, {
			operators: ['==', 'In', 'notIn'],
			filterSlug: 'sku',
			filterName: 'Sku',
		}],
		'voucher_purchases': [
			{
				operators: ['==', 'In', 'notIn'],
				filterSlug: 'merchant_id',
				filterName: 'Merchant',
			}, {
				operators: ['==', 'In', 'notIn'],
				filterSlug: 'sku',
				filterName: 'Sku',
			}
		]
	};

	@Input() rules: Array<RuleResult> = [];
	constructor() { }

	ngOnInit() {

	}

	/* This function is used to select rule that is mapped with the rule filterTypes*/
	changeFilter(rule, value) {
		for (let index in this.ruleFilters[rule.filterType]) {
			if (this.ruleFilters[rule.filterType][index].filterSlug == value)
				rule.filterIndex = index;
		}
	}

	/* Return true if operator does not exist in a rule or if the second operator is same operator as rule operator*/
	checklogicalOperator(rule, logicalOperator) {
		if (typeof rule.disableAndOr != 'undefined' && rule.disableAndOr == true)
			return false;
		if (typeof rule.child[0] == 'undefined') {
			return true;
		}
		return rule.child[0].logicalOperator == logicalOperator;
	}

	/* add child rules to a rule set have either Or/And Operator */
	addRuleByOperator(rule, operator) {
		let filterType = rule.filterType;
		if (rule.filterType == 'system_activities')
			filterType = rule.filterSlug;
		rule.child.push({
			logicalOperator: operator,
			filterType: filterType,
			operator: '',
			filterSlug: '',
			filterIndex: 0,
			child: [],
			disableAndOr: rule.disableAndOr || false,
		})
	}
	/* Add a new Rule Set*/
	addRuleSet(filterType: string) {
		this.rules.push({
			operator: '',
			filterSlug: '',
			filterIndex: 0,
			filterType: filterType,
			child: []
		})
	}

	/* Add Time Period RuleFilter*/
	addTimePeriod(rule) {
		let timePeriodRule: any = {};
		Object.assign(timePeriodRule, rule);
		timePeriodRule.filterType = 'time_period';
		timePeriodRule.disableAndOr = true;
		this.addRuleByOperator(timePeriodRule, 'and')
	}

	/* Delete any rule or rule child*/
	deleteRuleChild(index) {
		this.rules.splice(index, 1);
	}
}
