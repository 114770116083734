import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';
import { Common_methods } from '../../libraries/Common_methods';

@Injectable()
export class LobService {

  constructor(private api_adapter: Custom_api_calling) { }
  getLobList(data, callback) {
    let query_data = Common_methods.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/lob/get_lob?" + query_data, (result) => callback(result))
  }

  addLob(body, callback) {
    this.api_adapter.http_calling_post("api/tenant/lob/post_lob", body, (result: any[]) => {
      callback(result)
    })
  }

  getLob(lob, callback) {
    let lob_id = parseInt(lob);
    this.api_adapter.http_calling_get(`api/tenant/lob/get_lob/${lob_id}`, (result) => callback(result))
  }

  updateLob(lob, body, callback) {
    let lob_id = parseInt(lob);
    this.api_adapter.http_calling_put(`api/tenant/lob/update_lob/${lob_id}`, body, (result: any[]) => callback(result))
  }

  update_lob_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/lob/update_status", data.body, (result) => callback(result));
  }

}
