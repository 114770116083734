import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-rule-modal',
	templateUrl: './rule-modal.component.html',
	styleUrls: ['./rule-modal.component.css']
})
export class RuleModalComponent implements OnInit {

	constructor() { }
	filterType: string;
	@Output() selectedFilter: EventEmitter<string> = new EventEmitter();
	element: HTMLElement;

	filterTypes: Array<any> = [
		{ slug: 'customer_profile', itemName: 'Customer Profile' },
		{ slug: 'system_activities', itemName: 'System Activities' },
		{ slug: 'customer_history', itemName: 'Customer History' },
	];

	ngOnInit() {
		this.element = document.getElementById('open-modal') as HTMLElement;
	}

	ruleModalAccessor() {
		this.element.click();
	}


	selectFilterType() {
		if (this.filterType == '') {
			return alert('Select a filter Type.')
		}
		this.element.click();
		this.selectedFilter.emit(this.filterType);
	}
}
