import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class TierService {
    customerTiers = new BehaviorSubject([]);
    castCustomerTiers = this.customerTiers.asObservable();

    customerTier = new BehaviorSubject({});
    castCustomerTier = this.customerTier.asObservable();

    customerTierUpgrade = new BehaviorSubject({});
    castCustomerTierUpgrade = this.customerTierUpgrade.asObservable();

    customerTierUpgrades = new BehaviorSubject({});
    castCustomerTierUpgrades = this.customerTierUpgrades.asObservable();

    pointTypeDatas = new BehaviorSubject({});
    castpointTypeDatas = this.pointTypeDatas.asObservable();

    constructor(private customCallingApi: Custom_api_calling) { }

    public loadCustomerTiers(searchParam: string) {
        let customerTiersListApi: string = 'api/tenant/customer_tiers/get_customer_tiers_list?search=' + searchParam;
        this.customCallingApi.http_calling_get(customerTiersListApi, (data) => {
            if (data['status'] == true)
                this.customerTiers.next(data['values']);
        });
    }

    get_all_tiers(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get("api/tenant/customer_tiers/get_customer_tiers_list?" + query_data, (result) => callback(result));

    }

    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

    public loadPointTypeData(callback) {
        let pointTypeDatasListApi: string = 'api/tenant/point_type/get_point_type_list';
        this.customCallingApi.http_calling_get(pointTypeDatasListApi, (data) => {
            // this.pointTypeDatas.next(data.values);
            callback(data);
        });
    }

    public fetchCustomerTiers(customer_tier_id: number) {
        let customerTierFetchApi: string = 'api/tenant/customer_tiers/get_customer_tier/' + customer_tier_id;
        this.customCallingApi.http_calling_get(customerTierFetchApi, (data) => {
            if (data['status'] == true)
                this.customerTier.next(data['values'][0]);
        });
    }

    public fetchCustomerTier(customer_tier_id: number, callback) {
        let customerTierFetchApi: string = 'api/tenant/customer_tiers/get_customer_tier/' + customer_tier_id;
        this.customCallingApi.http_calling_get(customerTierFetchApi, (data) => {
            callback(data)
        });
    }

    public loadCustomerTiersUpgrades(searchParam: any) {
        let customerTierUpgradesListApi: string = 'api/tenant/customer_tier_upgrades/get_customer_tier_upgrades_list?search=' + searchParam['search'];
        this.customCallingApi.http_calling_get(customerTierUpgradesListApi, (data) => {
            if (data['status'] == true)
                this.customerTierUpgrades.next(data['values']);
        });
    }

    public fetchCustomerTierUpgrade(customer_tier_upgrade_id: number) {
        let customerTierUpgradeFetchApi: string = 'api/tenant/customer_tier_upgrades/get_customer_tier_upgrade/' + customer_tier_upgrade_id;
        this.customCallingApi.http_calling_get(customerTierUpgradeFetchApi, (data) => {
            if (data['status'] == true)
                this.customerTierUpgrade.next(data['values'][0]);
        });
    }

    getTierList(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/customer_tiers/get_customer_tiers_list?' + query_data, callback);
    }


}