import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class TenantUserService {

    constructor(private custom_api_calling: Custom_api_calling) { }


    create_tenant_user(data, callback) {
        this.custom_api_calling.http_calling_file_post("api/tenant/user/addTenantUser ", data.body, (result) => callback(result));
    }

    edit_tenant_user(data, callback) {
        this.custom_api_calling.http_calling_json_put("api/tenant/user/editTenantUser/" + data.id, data.body, (result) => callback(result));
    }

    get_tenant_user_by_id(data, callback) {
        this.custom_api_calling.http_calling_get("api/tenant/user/getTenantUser/" + data.id, (result) => callback(result));
    }

    get_tenant_users(data, callback) {
        let query_data = this.get_query_data(data);
        this.custom_api_calling.http_calling_get('api/tenant/user/getTenantUsersList?'+query_data, (result) => callback(result));
    }
    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }


    get_tenant_user_privileges(data, callback) {
        this.custom_api_calling.http_calling_get("api/tenant/privileges/get_tenant_user_privileges?tenant_user_id=" + data.tenant_user_id, (result) => callback(result));
    }

    update_tenant_user_privilages(data, callback) {
        this.custom_api_calling.http_calling_json_put("api/tenant/privileges/update_tenant_user_privileges", data.body, (result) => callback(result));
    }

    get_tenant_branches(callback) {
        this.custom_api_calling.http_calling_get("api/tenant/user/getTenantBranchList", (result) => callback(result));
    }

    get_routes_by_role(role_id, callback){
        this.custom_api_calling.http_calling_get("api/tenant/user_management/get_routes_by_role?role_id=" + role_id, (result) => callback(result)
        );
      }

}
