import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';
import { Common_methods } from '../../libraries/Common_methods';

@Injectable()
export class CountryBulkUpdateService {

    constructor(private customCallingApi: Custom_api_calling) {
    }


    uploadGiftCardsFlatFile(data,callback){
        const api = 'api/tenant/country/upload_bulk_update_gift_cards_flat_file';
        this.customCallingApi.http_calling_file_post(api,data.body,callback);
    }

    fetchGiftCardsFlatFiles(data,callback){
        const api = 'api/tenant/country/fetch_country_file_list?' + Common_methods.get_query_data(data.body);
        this.customCallingApi.http_calling_get(api,callback);
    }

    fetchGiftCardsFlatFileData(data,callback){
        const api = 'api/tenant/country/fetch_country_flat_file_data?' + Common_methods.get_query_data(data.body);
        this.customCallingApi.http_calling_get(api,callback);
    }
}
