import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/ApiService/api-service.service';
import { FlashMessageService } from '../../libraries/flash-message/services/flash-message.service';
import { Constants } from '../../constants/constants';
import { CustomValidators } from '../../constants/CustomValidators';
import { CustomRoutes } from '../../custom-routes';

@Component({
  selector: 'app-manage-categories-form',
  templateUrl: './manage-categories-form.component.html',
  styleUrls: ['./manage-categories-form.component.css']
})
export class ManageCategoriesFormComponent implements OnInit {

  manageCategoryForm: FormGroup;
  manageCategoryForm_error: string = '';
  manageCategoryFormErrorClass: string = '';
  category_id: number;
  sub_categories_list: any = [];
  category_data: any = [];
  categories_type_list:any=[];
  myFile:any="";
  dropdown_status: any;
  dropdown_setting: any;
  apiUrl: string = Constants.API_URL;
  isImg: boolean = false;
  loader_status: boolean = true;
  routes = CustomRoutes.routes;
  @ViewChild('FileImage', {static: true}) FileImage;
  @Input() formType: any;
  constructor(private fb: FormBuilder, private userService: UserService, private flashMessageService: FlashMessageService, private apiService: ApiService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.manageCategoryForm = this.fb.group({
      'category_label': [null,  Validators.compose([Validators.required,CustomValidators.nameField])],
      'status': [null, Validators.required],
      'category_image': [null],
      'category_type':[null,Validators.required],
    });
    this.dropdown_status = [
      { id: "1", itemName: "Active" },
      { id: "0", itemName: "Inactive" }
    ];

    this.categories_type_list= [
      {  itemName: "affiliated" },
      {  itemName: "main_program" }
    ];
    this.dropdown_setting = {
      singleSelection: true
    };
  }

  ngOnInit() {

    if (this.formType == 'edit') {
      let routeSubscription = this.activatedRoute.params.subscribe((params) => {
        this.category_id = params.id;
      });
      this.userService.addModuleSubscription('ManageCategoriesFormComponent', routeSubscription);
      this.get_category_data();
    }else{
      this.loader_status = false;
    }
  }

  get_category_data() {
    this.loader_status = true;
    this.apiService.get_category_id({ category_id: this.category_id }, (result) => {
      this.loader_status = false;
      if (result.status) {
        this.category_data = result.values[0];
        this.manageCategoryForm.patchValue({
          category_label: this.category_data.name,
          category_image:this.category_data.image,
          category_type:this.category_data.category_type,
          status: Constants.setAngularMultiSelectData(this.dropdown_status, this.category_data.status)
        });
      }
      else {
        this.flashMessageService.showMessage(result.message, 'alert alert-danger');
      }
    })
  }


  saveCategories() {
    if (this.userService.isFormValid(this.manageCategoryForm)) {
      this.loader_status = true;
      let image_path = this.FileImage.nativeElement;
      let body = this.manageCategoryForm.value;
      let category_data = {};
      category_data['category_id'] = this.category_id;
      category_data['name'] = body['category_label'];
      category_data['status'] = body['status'][0].id;
      category_data['category_type']=body['category_type'];
      if (image_path.files && image_path.files[0]) {
        category_data['image'] = image_path.files[0];
      }
  
      var form_data = new FormData();
      for (let key in category_data) {
        form_data.append(key, category_data[key]);
      }
      if (this.formType == 'edit') {
        this.apiService.save_categories(form_data,this.category_id, (result) => {
          this.api_result(result);
        })
      }else{
        this.loader_status = false;
        this.apiService.create_category_or_sub_category(form_data, (result) => {
          this.api_result(result);
        })
      }      
    }
  }

  api_result(result){
    this.loader_status = false;
    if (result.status) {
      this.flashMessageService.showMessage(result.message, 'alert alert-success');
      CustomRoutes.navigate([CustomRoutes.routes.tenant_base_configuration.categories]);
    } else {
      this.flashMessageService.showMessage(result.message, 'alert alert-danger');
    }
  }

  onChange(evt: any) {
    if (Constants.imageChecker(evt.target.files[0].name)) {
      // var reader = new FileReader();
      // reader.readAsDataURL(evt.target.files[0]);
      // reader.onload = (e) => {
      //   var image = new Image();
      //   image.src = reader.result;
      //   image.onload = () => {
      //     var height = image.height;
      //     var width = image.width;
      //     if (height > 64 || width > 64) {
      //       alert("Height and Width must not exceed 64px.");
      //       this.manageCategoryForm.controls['category_image'].patchValue(null);
      //       return false;
      //     }
      //     else {
      //       return true;
      //     }
      //   };
      // }
      console.log("image is valid.")
    }
    else {
      this.manageCategoryForm.controls['category_image'].patchValue(null);
    }
  }
}
