import { Component, OnInit, ViewChild } from '@angular/core';
import { DownloadManagerService } from "../download-manager/download-manager.service";
import { saveAs } from 'file-saver';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Constants } from "../constants/constants";
import { UtcDatePipe } from '../customPipes/utc-date.pipe';
import { FlashMessageService } from '../libraries/flash-message/services/flash-message.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { UserService } from '../services/user.service';

@Component({
	selector: 'app-download-manager',
	templateUrl: './download-manager.component.html',
	styleUrls: ['./download-manager.component.css']
})
export class DownloadManagerComponent implements OnInit {
	loader_status: boolean;
	downloadList: any = [];
	searchForm: FormGroup;
	current_page = 1;
	limit = 10;
	total_pages = 1;
	search: any;
	current_date:any;
	tenant_role_id = this.userService.getFromLocalStorage('log_in_role');
	@ViewChild('paginationComponent', {static: true}) paginationComponent: PaginationComponent;

	constructor(private flashMessageService: FlashMessageService, private downloadManagerService: DownloadManagerService, private customCallingApi: Custom_api_calling, private fb: FormBuilder, private userService: UserService) {
		this.searchForm = fb.group({
			'search': [null],
			'start_date': [null],
			'end_date': [null]
		});
	}

	ngOnInit() {
		this.get_downloadManager_data({ page: this.current_page });
	}

	downloadFile(download) {
		this.downloadManagerService.download_report({ 'path': download.file_path }, (result) => {
			if (result['status'] == true) {
				window.location.href = Constants.API_URL + result['path'];
			} else {
				this.flashMessageService.showMessage("File / File path is not present on the server", "alert alert-error");
			}
		});
	}


	refresh() {
		this.get_downloadManager_data({ page: this.current_page });
	}

	// searchFormSubmit() {
	//     this.loader_status = true;
	//     this.paginationComponent.setPage(1);
	//     this.get_downloadManager_data({page: this.current_page});
	// }

	get_downloadManager_data(data) {
		this.loader_status = true;
		this.current_page = data.page;

		let per_data = this.searchForm.value;
		per_data.limit = this.limit;
		per_data.offset = (this.current_page - 1) * this.limit;
		per_data.tenant_role_id = this.tenant_role_id;
		this.downloadManagerService.get_download_list(per_data, (data) => {
			if (data['status'] === true) {
				this.loader_status = false;
				this.downloadList = data.values['download_list'];
				this.total_pages = data.values['total_records'];

				// if(Constants.envName == 'production') {
				// 	this.downloadList.map(download => {
				// 		let flag1 = 0;
				// 		let flag2 = 0;
				// 		this.downloadManagerService.fileExists(Constants.API1_URL + download['file_path'], (d) => {
				// 			if(!d.status) {
				// 				flag1 = 1;
				// 			}
				// 		});
				// 		this.downloadManagerService.fileExists(Constants.API2_URL + download['file_path'], (d) => {
				// 			if(!d.status) {
				// 				flag2 = 1;
				// 			}
				// 		});
				//
				// 		if(flag1 == 1 && flag2 == 1) {
				// 			download.status = 'completed';
				// 		} else {
				// 			download.status = 'inprogress';
				// 		}
				// 	});
				// }
			} else {
				this.loader_status = false;
				this.downloadList = [];
			}
		});
	}

	setfieldReset() {
		this.searchForm.reset();
		this.get_downloadManager_data({ page: 1 });
	}
}
