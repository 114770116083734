import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { Common_methods } from '../libraries/Common_methods';

@Injectable()
export class FlatFileModelService {

    constructor(private customCallingApi: Custom_api_calling) {
    }

    addFlatFileModel(data,callback){
        const api = 'api/tenant/flat_file/add_flat_file_model';
        this.customCallingApi.http_calling_file_post(api,data.body,callback);

    }

    editFlatFileModel(data,callback){
        const api = 'api/tenant/flat_file/edit_flat_file_model?flat_file_model_id='+data.flat_file_model_id;
        this.customCallingApi.http_calling_file_put(api,data.body,callback);

    }

    getFlatFileModel(data,callback){
        const api = 'api/tenant/flat_file/get_flat_file_model?flat_file_model_id='+data.flat_file_model_id;
        this.customCallingApi.http_calling_get(api,callback);
    }

    getFlatFileModelList(data,callback){
        const api = 'api/tenant/flat_file/get_flat_file_model_list';
        this.customCallingApi.http_calling_get(api,callback);
    }

    changeStatus(data,callback){
        const api = 'api/tenant/flat_file/change_flat_file_model_status';
        this.customCallingApi.http_calling_file_put(api,data.body,callback);
    }

    uploadAccrualFlatFile(data,callback){
        const api = 'api/tenant/flat_file/upload_flat_file';
        this.customCallingApi.http_calling_file_post(api,data.body,callback);
    }

    fetchAccrualFlatFiles(data,callback){
        const api = 'api/tenant/flat_file/fetch_flat_file_list?' + Common_methods.get_query_data(data.body);
        this.customCallingApi.http_calling_get(api,callback);
    }

    fetchAccrualFlatFileData(data,callback){
        const api = 'api/tenant/flat_file/fetch_flat_file_data?' + Common_methods.get_query_data(data.body);
        this.customCallingApi.http_calling_get(api,callback);
    }
}
