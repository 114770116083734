import { Component, OnInit, Inject } from '@angular/core';
import { WalletService } from '../wallet.service';
import { FlashMessageService } from '../../libraries/flash-message/services/flash-message.service';
import { Constants } from '../../constants/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-wallet-point-type',
  templateUrl: './wallet-point-type.component.html',
  styleUrls: ['./wallet-point-type.component.css']
})
export class WalletPointTypeComponent implements OnInit {

  priorityArrWithId:any = [];
  loader_status:boolean = true;
  wallet_Point_type_list:any;
  loader_image = Constants.LOADER_IMAGE_CONFIGURATION; 
  loading: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public walletId: any,
              private walletService : WalletService,
              private flashMessageService:FlashMessageService,
              private dialogref : MatDialogRef<WalletPointTypeComponent>) { }

  ngOnInit() {
    this.loading = true;
console.log("wallet ID",this.walletId)
    this.walletService.getWallet({wallet_id:this.walletId},(result) => {
      if(result.status){
        this.wallet_Point_type_list = result.values;
        for(var i = 0; i < this.wallet_Point_type_list.length; i++) {
          let obj ={
              'point_type_id':this.wallet_Point_type_list[i]['point_type_id'],
              'point_priority':this.wallet_Point_type_list[i]['point_priority'],
              'input_id':i,
          }
          this.priorityArrWithId.push(obj);
       }
      }
      this.loading = false;
    })
   }


  
  submitPriority(){
    this.priorityArrWithId;
    this.loader_status = true;
    
    let body ={}
    body['wallet_id'] = this.walletId;
    body['priority_data'] = this.priorityArrWithId;
    this.walletService.updatePointPriorityOnWallet(body, (result) => {
        this.loader_status = false;
        if(result.status){                
            this.flashMessageService.showMessage(result.message,'alert alert-success');
            this.dialogref.close();
        }else{
            this.flashMessageService.showMessage(result.message,'alert alert-danger');
        }
    });
}

  onPriorityChange(event1,point_id,input){
    let event_val = parseInt(event1);
    let prev_id:number;
    let set_prev_val = '';
    let _arr_index:number;
    let element:HTMLInputElement = document.getElementById('priority_input'+input) as HTMLInputElement;

    if(event_val <= this.priorityArrWithId.length){
        element.style.borderColor = "#CCC";
        for(var i = 0; i < this.priorityArrWithId.length; i++) {  
            let elementEnable:HTMLInputElement = document.getElementById('priority_input'+i) as HTMLInputElement;
            elementEnable.disabled = false; 
            if(this.priorityArrWithId[i]['point_priority'] == event_val){
                prev_id = this.priorityArrWithId[i]['input_id'];
                _arr_index = i;
            }
            if(this.priorityArrWithId[i]['input_id'] == input){
                set_prev_val =this.priorityArrWithId[i]['point_priority'];
                this.priorityArrWithId[i]['point_priority'] = event_val;
                $("#invalid-priority"+input).remove();
            }
        }
        this.priorityArrWithId[_arr_index]['point_priority'] = set_prev_val;
        let element1:HTMLInputElement = document.getElementById('priority_input'+prev_id) as HTMLInputElement;
        element1.value = set_prev_val;    
    }else{
        element.style.borderColor = "red";           
            let x = document.createElement("label");
            let t = document.createTextNode("Invalid priority set.");
            x.setAttribute("for", "error");
            x.setAttribute("class", "invalid-priority");
            x.setAttribute("id", "invalid-priority"+input);
            x.setAttribute("style", "margin: 0;float: right;color:#fff;padding: 4px 10px 0px;text-align: center;position: relative;background-color: rgba(231, 76, 60, 0.88);");
            x.appendChild(t);
            document.getElementById("tbl-th"+input).insertBefore(x,document.getElementById("priority_input"+input));
         
            setTimeout(()=>{
                $('#invalid-priority'+input).remove();
            },3000);
            for(var i = 0; i < this.priorityArrWithId.length; i++) {
                let element1:HTMLInputElement = document.getElementById('priority_input'+i) as HTMLInputElement;                    
                if(i !== input){
                    element1.disabled = true;
                }
            }
    }
}
}
