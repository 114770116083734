import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { UserService } from '../services/user.service';

@Injectable()
export class PointService {
    private basePointRate;
    private sellingPointRate;

    constructor(private customCallingApi: Custom_api_calling, private userService:UserService) {
        this.userService.castTenant.subscribe((data)=>{
            this.basePointRate = data['base_point_rate'];
            this.sellingPointRate = data['selling_point_rate'];
        })
    }

    public debit_points(debit_data:any){
        let debitPointsApi: string = 'api/tenant/point/debit_points';
        return new Promise((resolve,reject)=>{
            this.customCallingApi.http_calling_json_put(debitPointsApi, debit_data , (data) => {
                if(data['status']==true){ resolve(data['values']) }else{ reject(data['message'])}
            });
        })
    }

    public convertMoneyToPoints(value:number){
        return (value / this.basePointRate).toFixed(2);
    }

    public convertPointsToMoney(value:number){
        return (value * this.basePointRate).toFixed(2);
    }
}
