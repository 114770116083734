import { Component, OnInit } from '@angular/core';
import { Constants } from '../../constants/constants';

@Component({
  selector: 'app-setup-success',
  templateUrl: './setup-success.component.html',
  styleUrls: ['./setup-success.component.css']
})
export class SetupSuccessComponent implements OnInit {

  constructor() { }
  dashboardUrl = Constants.BASE_URL;
  ngOnInit() {
  }

}
