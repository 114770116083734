export class TierLanguage{
    
    id: number;
    tier_id: number;
    language_code: string;
    language_name: string;
    tier_name: string;
    description: string;

    constructor(info){
        this.id = info.id;
        this.tier_id = info.tier_id;
        this.language_code = info.language_code;
        this.language_name = info.language_name;
        this.tier_name = info.tier_name;
        this.description = info.description;
   }
}