import { Component, OnInit,ViewChild, Output, Input, ViewContainerRef, ComponentFactoryResolver, Type } from '@angular/core';
// import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {Custom_api_calling} from "../../adapters/Custom_api_calling";
import { UserService } from '../../services/user.service';
import { WelcomeComponent } from '../welcome/welcome.component';
import { TenantProgramFormComponent } from '../../tenant-program/tenant-program-form/tenant-program-form.component';
import { PointConfigurationComponent } from '../point-configuration/point-configuration.component';
import { PointTypeFormComponent } from '../../point_type_master/point-type-form/point-type-form.component';
import { TierFormComponent } from '../../customer_tier_management/tier-form/tier-form.component';
import { SetupSuccessComponent } from '../setup-success/setup-success.component';
import { TenantWalletFormComponent } from '../../tenant-wallet/tenant-wallet-form/tenant-wallet-form.component';

declare let $:any;

@Component({
  selector: 'app-tenant-base-configuration',
  templateUrl: './tenant-base-configuration.component.html',
  styleUrls: ['./tenant-base-configuration.component.css']
})
export class TenantBaseConfigurationComponent implements OnInit {
    @ViewChild('container', {
        read: ViewContainerRef,
        static: false
    }) container: ViewContainerRef;
    stepIndex:number = 0;
    programFlowComponents = [
        WelcomeComponent,
        TenantProgramFormComponent,
        TenantWalletFormComponent,
        PointConfigurationComponent,
        PointTypeFormComponent,
        TierFormComponent,
        SetupSuccessComponent,
    ]
    stepTitle = [
        "",
        "Kick Start Your New Program",
        "Add Base Wallet",
        "Point Configuration",
        "Create your own point type",
        "Add Base Tier",
        "Process Complete"
    ]
    constructor(private componentFactoryResolver:ComponentFactoryResolver, private userService:UserService) {
    }

    ngOnInit() {
        this.addComponent(this.programFlowComponents[0]);
    }

    addComponent(componentClass : Type<any>){
        //** remove previous flow **//
        this.userService.destroyModuleSubscriptions('TenantBaseConfigurationComponent');
        this.container.remove(0);

        /* Add Next Flow */
        let newComponent = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        let component = this.container.createComponent(newComponent);

        let flowIndex = this.programFlowComponents.map((flow)=> { return flow.name }).indexOf(component.instance.constructor.name)

        this.stepIndex = flowIndex;
        let nextFlowIndex = ++flowIndex;
        let componentSubscription = component.instance['processSuccess'].subscribe((data)=>{
            console.log('EHERE',nextFlowIndex)
            this.addComponent(this.programFlowComponents[nextFlowIndex]);
        });

        if(['TenantProgramFormComponent','PointTypeFormComponent','TierFormComponent','TenantWalletFormComponent'].indexOf(component.instance['processSuccess']) > -1)
            component.instance.formType = 'add';
        this.userService.addModuleSubscription('TenantBaseConfigurationComponent',componentSubscription)
    }

}
