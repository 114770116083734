/*
 *
 * Created By Brijesh Kumar Khatri
 * 21 fab 2018
 *
 */
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'app-input-field-error-message',
	templateUrl: './input-field-error-message.component.html',
	styleUrls: ['./input-field-error-message.component.css']
})
export class InputFieldErrorMessageComponent implements OnInit, OnChanges {

	controll: any;
	errorMsg: any;
	hasFileError: boolean = false;

	@Input()
	set errors(err: any) {
		this.errorMsg = err;
	}

	@Input()
	set ctrl(ctrl: any) {
		this.controll = ctrl;
	}

	displayError: boolean;
	error: any;

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.hasFileError = false;
		if (this.errorMsg) {
			let keys = Object.keys(this.errorMsg);
			let len = keys.length;
			if (len > 0) {
				switch (keys[0]) {
					case "required":
						this.error = "This field is required.";
						break;
					case "minlength":
						this.error = "Minimum " + this.errorMsg['minlength']['requiredLength'] + " length required.";
						break;
					case "maxlength":
						this.error = "Maximum " + this.errorMsg['maxlength']['requiredLength'] + " length required.";
						break;
					case "email":
						this.error = "Please enter a valid email address";
						break;
					case "cms_cust_id":
						this.error = "Please enter a valid cms customer id";
						break;
					case "min":
						this.error = "Please enter less than " + this.errorMsg['min']['min'];
						break;
					case "max":
						this.error = "Please enter greater than " + this.errorMsg['max']['max'];
						break;
					case "pattern":
						this.error = "Invalid Input";
						break;
					case "nameField":
						this.error = "This Field accepts only Alphabets";
						break;
					case "phoneValid":
						this.error = "Please enter valid Phone No";
						break;
					case "alphaNumeric":
						this.error = "This Field accepts only AlphaNumeric characters";
						break;
					case "splChar":
						this.error = "This Field accepts only Special characters";
						break;
					case "email":
						this.error = "Please enter a valid email";
						break;
					case "url":
						this.error = "Enter valid link";
						break;
					case "quantityValid":
						this.error = "This field accepts only Integers";
						break;
					case "alpAndSplChar":
						this.error = "This field accepts only alphabets and special characters";
						break;
					case "validationForDomainAndUrl":
						this.error = "Enter valid Url";
						break;
					case "priceValid":
						this.error = "This field accepts only Decimal numbers";
						break;
					case "emailValid":
						this.error = "Please enter a valid Email";
						break;
					case "extension":
						this.error = "Invalid Extension.";
						break;
					case "maxSize":
						this.error = "File size should be less than maximum size.";
						break;
					case "dimension":
						this.error = "Upload file 300 x 300 dimensions.";
						break;
					case "custom_message":
						this.error = this.errorMsg['custom_message']
				}
			}
		}
	}

	checkFileError(errorKey) {
		if (['extension', 'maxSize', 'dimension'].includes(errorKey))
			this.hasFileError = true;
	}

}
