import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Custom_api_calling } from "../adapters/Custom_api_calling";
import { CustomRoutes } from '../custom-routes';
declare let jquery: any;
declare let $: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	loginUserForm: FormGroup;
	error_class: string;
	is_session_expired = this.userService.getFromLocalStorage("session_expired");
	login_error: string = '';
	constructor(private router: Router, private fb: FormBuilder, private cookieService: CookieService, private api_adapter: Custom_api_calling, private userService: UserService) {
		if (this.userService.getUserLoggedIn) {
			console.log('Logged in role', this.userService.getFromLocalStorage('log_in_role') === 43)
			if(this.userService.getFromLocalStorage('log_in_role') == 42) {
				CustomRoutes.navigate([CustomRoutes.routes.customer.customer_list]);
			} else if(this.userService.getFromLocalStorage('log_in_role') == 43){
				CustomRoutes.navigate([CustomRoutes.routes.dashboard.dashboard_program_calendar]);
			}else{
				CustomRoutes.navigate([CustomRoutes.routes.dashboard.dashboard]);
			}
		} else {
			CustomRoutes.navigate([CustomRoutes.routes.login]);
		}
		this.loginUserForm = fb.group({
			'user_id': [null, Validators.compose([Validators.required, Validators.minLength(3),])],
			'password': [null, Validators.required],
			'validate': ''
		})
	}



	ngOnInit(): void {
		if (this.is_session_expired !== '') {
			console.log("yeeesssssss", this.is_session_expired);
			this.login_error = this.is_session_expired
			this.error_class = "error_fail";
		}


	}

	loginUser(post) {
		// $(".login_error").html("");
		$('.loader_show').html("&nbsp;&nbsp;<img src='../../assets/images/loader.gif' style='width:22px;border-radius:50px;background:#fff;' >");
		let username = post.user_id;
		let password = post.password;
		let body = "username=" + username + "&password=" + password;
		this.api_adapter.http_calling_post("api/tenant/user/login", body, (data: any[]) => {
			if (data["status"]) {
				let values = data['values'][0];
				let name = values.name;
				let email = values.email;
				let token = values.token;
				let userID = values.tenant_id;
				let role = values.role;
				this.userService.addToLocalStorage('log_in_token', token);
				this.userService.addToLocalStorage('log_in_email', email);
				this.userService.addToLocalStorage('log_in_name', name);
				this.userService.addToLocalStorage('log_in_userID', userID);
				this.userService.addToLocalStorage('log_in_role', role);
				this.userService.addToLocalStorage('session_expired', '');
				this.userService.setIsBaseConfigurationComplete(values.is_base_configuration_complete);
				this.error_class = "error_success";
				this.login_error = "Login Successful...";
				window.location.reload();
			} else {
				this.error_class = "error_fail";
				this.login_error = "Username or Password does not match";
				$(".loader_show").html("");
			}
		});
	}
}
