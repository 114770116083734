import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';

@Injectable()
export class FundingPartnerService {

  constructor(private api_adapter: Custom_api_calling) { }

  create_funding_partner(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/partner/add_funding_partner", data.body, (result) => callback(result));

  }
  modify_funding_partner(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/partner/edit_funding_partner", data.body, (result) => callback(result));

  }

  funding_partner_by_id(funding_partner_id: number, callback) {
    this.api_adapter.http_calling_get('api/tenant/partner/get_by_id?id=' + funding_partner_id, (result) => callback(result));
  }

  get_all_funding_partner(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/partner/get_funding_partner?" + query_data, (result) => callback(result));
  }


  get_all_merchant_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/partner/get_merchant?" + query_data, (result) => callback(result));
  }

  update_attribute_status(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/partner/update_status", data.body, (result) => callback(result));
  }


  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }

  get_store_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/partner/store_list?" + query_data, (result) => callback(result));
  }
}
