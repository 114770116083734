import {
    Injectable
} from '@angular/core';
import {
    Custom_api_calling
} from '../adapters/Custom_api_calling';
import {
    BehaviorSubject
} from 'rxjs/BehaviorSubject';

@Injectable()
export class PointTypeMasterService {
    pointTypeListApi: any;
    pointTypeApi: any;
    point_types = new BehaviorSubject({});
    point_type = new BehaviorSubject({});
    castPointTypes = this.point_types.asObservable();
    castPointType = this.point_type.asObservable();
    constructor(private customCallingApi: Custom_api_calling) { }

    // loadPointType(searchParam: string) {
    //     this.pointTypeListApi = 'api/tenant/point_type/get_point_type_list?search=' + searchParam;
    //     this.customCallingApi.http_calling_get(this.pointTypeListApi, (data) => {
    //         this.point_types.next(data.values);
    //     });
    // }

    loadPointType(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/point_type/get_point_type_list?' + query_data, (result) => {
            callback(result);
        });
    }

    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

    fetchPointType(pointTypeId) {
        this.pointTypeApi = 'api/tenant/point_type/get_point_type_id/' + pointTypeId;
        this.customCallingApi.http_calling_get(this.pointTypeApi, (data) => {
            this.point_type.next(data.values[0]);
        });
    }
}
