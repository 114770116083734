import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from "@angular/common/http";
import {Injectable ,Inject} from "@angular/core";
import {Constants} from "../constants/constants";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {UserService} from "../services/user.service";

@Injectable()
export class Custom_api_calling {
    constructor(private httpClient: HttpClient, @Inject(LOCAL_STORAGE) private storage:WebStorageService) {
    }
    re_http_get(api, callback) {
        const requestOptions = { headers: new HttpHeaders(Constants.RE_API_HEADER) };
        let result = this.httpClient.get(Constants.RULES_ENGINE_URL + api, requestOptions);

        result.subscribe(callback);
    }

    http_calling_post(api, body,callback) {
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_HEADER)
        };
        let result = this.httpClient.post(Constants.API_URL + api, body, requestOptions);

        result.subscribe((data)=>{
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
           else{
                callback(data);
            }
        });


    }
    http_calling_file_post(api, body,callback) {
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_FILE_HEADER)
        };
        this.httpClient.post(Constants.API_URL + api, body, requestOptions).subscribe((data)=>{
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
           else{
                callback(data);
            }
        });

    }
    http_calling_put(api, body, callback) {
        // headers['USER_TOKEN'] = this.get_user_token();
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_FILE_HEADER)
        };
       
        this.httpClient.put(Constants.API_URL + api, body, requestOptions).subscribe((data) => {
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
            else {
                callback(data);
            }
        });

    }

    http_calling_json_put(api, body,callback) {
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_FILE_HEADER)
        };
        this.httpClient.put(Constants.API_URL + api, body, requestOptions).subscribe((data)=>{
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
           else{
                callback(data);
            }
        });

    }

    http_calling_get(api,callback) {
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_HEADER)
        };
         this.httpClient.get(Constants.API_URL + api, requestOptions).subscribe((data)=>{
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
            else{
                callback(data);
            }
        });
    }

    http_calling_file_get(api,callback) {
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_FILE_HEADER)
        };
         this.httpClient.get(Constants.API_URL + api, requestOptions).subscribe((data)=>{
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
            else{
                callback(data);
            }
        });
    }

    http_multi_url_get(apis:any,callback){
        let apiArray = [];
        let apisOjectKeys = [];
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_HEADER)
        };
        for(let api in apis){
            apiArray.push(this.httpClient.get(Constants.API_URL + apis[api],requestOptions));
        }

        Observable.forkJoin(apiArray).map((data:any[])=>{
            let returnData = {};
            apisOjectKeys = Object.keys(apis);
            apisOjectKeys.forEach((key,index)=>{
                returnData[key] = data[index];
            })
            return returnData;
        }).subscribe((data)=>{
            if (data['status_code'] == 6) {
                this.storage.remove('log_in_email');
                this.storage.remove('log_in_token');
                this.storage.set('session_expired', data['message']);
                // document.getElementById("dashboard_container").remove();
                window.location.reload();
            }
           else{
                callback(data);
            }
        })
    }
    http_calling_file_put(api, body,callback) {
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_FILE_HEADER)
        };
        this.httpClient.put(Constants.API_URL + api, body, requestOptions).subscribe((data)=> this.api_return_result(data,callback),(error)=>{
            callback(error);
        });

    }

    http_calling_patch(api,body,callback){
        const requestOptions = {
            headers: new HttpHeaders(Constants.API_FILE_HEADER)
        };
        let result = this.httpClient.patch(Constants.API_URL + api, body, requestOptions);

        result.subscribe((data)=> this.api_return_result(data,callback),(error)=>{
            callback(error);
        });
    }

    http_calling_post_with_url(url, body,headers, callback) {
        const requestOptions = {
            headers: new HttpHeaders(headers)
        };
        this.httpClient.post(url, body, requestOptions).subscribe((data)=>{
            callback(data);
        });

    }

   
    
    private api_return_result(data, callback){
        if (data['status_code'] == 6) {
            this.storage.remove('log_in_email');
            this.storage.remove('log_in_token');
            this.storage.set('session_expired', data['message']);
            // document.getElementById("dashboard_container").remove();
            window.location.reload();
        }
        else{
            callback(data);
        }
    }


}
