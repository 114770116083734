import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { ApiService } from '../services/ApiService/api-service.service';

@Injectable()
export class WalletService {

    constructor(private customCallingApi: Custom_api_calling,private apiService:ApiService) {}

    addWallet(data,callback){
        const api = 'api/tenant/wallet/add_wallet';
        this.customCallingApi.http_calling_file_post(api,data.body,callback);

    }

    editWallet(data,callback){
        const api = 'api/tenant/wallet/edit_wallet?wallet_id='+data.wallet_id;
        this.customCallingApi.http_calling_file_put(api,data.body,callback);

    }

    updatePointPriorityOnWallet(data,callback){
        const api = 'api/tenant/wallet/updatePointPriorityOnWallet';
        this.customCallingApi.http_calling_file_put(api,data,callback);

    }

    getWallet(data,callback){
        const api = 'api/tenant/wallet/get_wallet?wallet_id='+data.wallet_id;
        this.customCallingApi.http_calling_get(api,callback);
    }

    getWalletList(data,callback){
       let api = 'api/tenant/wallet/get_wallet_list?';
        api += "&search=" + data.search;
        api += "&limit=" + data.limit;
        api += "&offset=" + data.offset
        this.customCallingApi.http_calling_get(api,callback);
    }    

    loadAllPointTypesByWalletId(data, callback) {
        let query_data = this.apiService.get_query_data(data);
        this.customCallingApi.http_calling_get("api/tenant/wallet/get_point_types_of_wallet_by_cust_id?" + query_data, (result) => callback(result));
    }

    getTotalPointsOfWallet(data, callback) {
        this.customCallingApi.http_calling_get("api/tenant/point/fetch_customer_points?wallet_id=" + data.wallet_id +'&&customer_id='+data.customer_id, (result) => callback(result));
    }    

    changeStatus(data,callback){
        const api = 'api/tenant/wallet/change_wallet_status';
        this.customCallingApi.http_calling_file_put(api,data.body,callback);
    }

}
