import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class FlashMessageService {

    constructor() {
    }

    @Output() passMessage: EventEmitter<any> = new EventEmitter();

    showMessage(message,classes) {
        this.passMessage.emit({
            message:message,
            classes:classes
        });
    }

}
