import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PartnerService {

    constructor(private api_adapter: Custom_api_calling, private http: HttpClient) {

    }

    getPartnerList(data: any, callback: any) {
        let queryData = this.queryData(data);
        this.api_adapter.http_calling_get("api/tenant/partner/getPartnerList?" + queryData, (result: any) => callback(result));
    }

    getPartnerDropdownList(data: any, callback: any) {
        let queryData = this.queryData(data);
        this.api_adapter.http_calling_get("api/tenant/partner/getPartnerDropdownList?" + queryData, (result: any) => callback(result));
    }

    getPartnerById(data: any, callback: any) {
        this.api_adapter.http_calling_get("api/tenant/partner/getPartnerById/" + data, (result: any) => callback(result));
    }

    addPartner(data: any, callback: any) {
        this.api_adapter.http_calling_file_post("api/tenant/partner/addPartner", data, (result: any) => callback(result));
    }

    editPartner(data: any, callback: any) {
        this.api_adapter.http_calling_file_put("api/tenant/partner/editPartner", data, (result: any) => callback(result));
    }

    uploadLogoFile(data: any, callback: any) {
        this.api_adapter.http_calling_file_post("api/tenant/partner/uploadLogoFile", data, (result: any) => callback(result));
    }

    getPartnerListByCountry(data: any, callback: any) {
        let queryData = this.queryData(data);
        this.api_adapter.http_calling_get("api/tenant/partner/getPartnerListByCountry?" + queryData, (result: any) => callback(result));
    }

    insertPartnerDisplayOrdersByCountry(data: any, callback: any) {
        this.api_adapter.http_calling_file_post("api/tenant/partner/insertPartnerDisplayOrdersByCountry", data, (result: any) => callback(result));
    }

    // common functions

    queryData(data: any) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

    getPrimaryData(data: any, callback: any) {
        this.api_adapter.http_calling_get("api/tenant/partner/getPrimaryData", (result: any) => callback(result));
    }

}
