import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../../adapters/Custom_api_calling';

@Injectable()
export class FundingAttributesService {

  constructor(private api_adapter: Custom_api_calling) { }

  create_funding_attributes(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/funding_attributes/add_funding_attributes", data.body, (result) => callback(result));

    }
    modify_funding_attributes(data, callback)
    {
      this.api_adapter.http_calling_file_put("api/tenant/funding_attributes/edit_funding_attributes", data.body, (result) => callback(result));

    }

    funding_attributes_by_id(funding_attributes_id: number, callback) {
      this.api_adapter.http_calling_get('api/tenant/funding_attributes/get_by_id?id=' + funding_attributes_id, (result) => callback(result));
    }
    get_all_funding_attributes(data, callback)
    {
      let query_data = this.get_query_data(data);
      this.api_adapter.http_calling_get("api/tenant/funding_attributes/get_funding_attributes?" + query_data, (result) => callback(result));


    }


    update_attribute_status(data, callback) {
      this.api_adapter.http_calling_file_put("api/tenant/funding_attributes/update_status", data.body, (result) => callback(result));
    }


    get_query_data(data) {
      // console.log('data----',data);
      let query_data = "";
      let keys = Object.keys(data);
  
      for (let i = 0; i < keys.length; i++) {
        if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
          query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
      }
      return query_data;
    }
}
