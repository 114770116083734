import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import {Constants} from "../constants/constants";
import { ApiService } from '../services/ApiService/api-service.service';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';

@Injectable()
export class DownloadManagerService {

  constructor(private customCallingApi: Custom_api_calling, private apiService: ApiService,
			  private httpClient: HttpClient) { }

  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
            query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
}

  get_download_list(data, callback) {
    let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get("api/tenant/download_list/get_download_list?" + query_data, (result) => {
          callback(result)
        });
  }

	public fileExists(url: string, callback) {
		return this.httpClient.get(url).subscribe((d) => {
			callback(d);
		});
	}

  download_report(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    console.log(query_data);
    // var xhttp = new XMLHttpRequest();
        this.customCallingApi.http_calling_post("api/tenant/download_list/download_report", query_data, (result) => {
          callback(result)
        });
        // xhttp.open("GET", Constants.API_URL + "api/tenant/download_list/download_report?" + query_data, true);
        // xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        // xhttp.setRequestHeader("Access-Control-Allow-Headers", "Content-Type");
        // xhttp.setRequestHeader("API_TOKEN", Constants.API_TOKEN);
        // xhttp.setRequestHeader("USER_TOKEN", Constants.USER_TOKEN);
        // xhttp.setRequestHeader("USER_AGENT", "Website");
        // xhttp.responseType = 'blob'
        // xhttp.send();
        // xhttp.onreadystatechange = function (data) {
        //     if (this.readyState == 4 && this.status == 200) {
        //         callback(this.response);
        //     }
        // };
  }

}
