import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Constants } from "../../constants/constants";


@Component({
	selector: 'app-search-component',
	templateUrl: './search-component.component.html',
	styleUrls: ['./search-component.component.css']
})
export class SearchComponentComponent implements OnInit, OnChanges {

	Show: boolean = true;
	searchForm: FormGroup;
	fields: any;
	Object = Object;
	sFields = [];
	col_classes = "col-md-3";
	dropdown_status: any = [
		{ id: "both", itemName: "Both" },
		{ id: 0, itemName: "Inactive" },
		{ id: 1, itemName: "Active" }
	];
	defaultValues = {};
	settings = {
		bigBanner: true,
		timePicker: true,
		format: 'dd-MM-yyyy HH:mm',
		defaultOpen: false
	};

	@Output() onSubmit = new EventEmitter();
	@Output() onChange = new EventEmitter();
	@Input()
	set show(value: boolean) {
		this.Show = value;
	}

	@Input()
	set cols(col) {
		switch (col) {
			case 1:
				this.col_classes = "col-md-12";
				break;
			case 2:
				this.col_classes = "col-md-6";
				break;
			case 3:
				this.col_classes = "col-md-4";
				break;
			case 4:
				this.col_classes = "col-md-3";
				break;
			case 6:
				this.col_classes = "col-md-2";
				break;
			default:
				this.col_classes = "col-md-3"
		}
	}

	@Input()
	set searchFields(fields: any) {
		console.log("Brijesh",fields);
		this.sFields = fields;
		let keys = Object.keys(fields);
		let form = {};
		let defualt_value = {};
		try {
			for (let i = 0; i < keys.length; i++) {
				form[keys[i]] = [null];
				this.defaultValues[keys[i]] = fields[keys[i]].values;
				this.defaultValues[keys[i]] = this.defaultValues[keys[i]] == undefined ? [] : this.defaultValues[keys[i]];
			}
		} catch (e) {
			console.log(e);
		}
		this.searchForm = this.fb.group(form);
		this.searchForm.patchValue({
			status: [{ id: "both", itemName: "Both" }]
		});
	}

	@Input() defaultVal: any;
	@Input()
	set default_values(values: any) {
		console.log("Default Values: ", values);
		let keys = Object.keys(values);

		for (let i = 0; i < keys.length; i++) {
			try {
				this.defaultValues[keys[i]] = values[keys[i]].values;
				this.searchForm.controls[keys[i]].setValue(values[keys[i]].default_value)
			} catch (e) {
				console.log(e);
			}
		}

		if (this.defaultValues['status'] === undefined) {
			this.defaultValues['status'] = this.dropdown_status
		}

	}

	ngOnChanges() {
		console.log("Default Values: ", this.defaultVal);
	}

	constructor(private fb: FormBuilder) {
		this.searchForm = fb.group({});
	}

	ngOnInit() {

		this.defaultValues['status'] = this.dropdown_status
	}

	searchOfferRecords() {
		let keys = Object.keys(this.sFields);
		let body = this.searchForm.value;
		for (let i = 0; i < keys.length; i++) {
			if (this.sFields[keys[i]].field == 'status') {
				try {
					body[keys[i]] = Constants.getIDSAngular2MultiselectDropdownList(this.searchForm.controls['status'].value)[0];
					if (body[keys[i]] === undefined || body[keys[i]] === null) {
						body[keys[i]] = 'both';
						this.searchForm.patchValue({
							status: [{ id: "both", itemName: "Both" }]
						});
					}
				} catch (e) {
					body[keys[i]] = 'both';
					this.searchForm.patchValue({
						status: [{ id: "both", itemName: "Both" }]
					});
				}
			}
		}
		this.onSubmit.emit(body);
	}


	onItemSelect(item: any, key: any) {
		let body = {};
		body[key] = item;
		this.onChange.emit(body);
	}
	OnItemDeSelect(item: any, key: any) {
		let body = {};
		body[key] = item;
		this.onChange.emit(body);
	}
	onSelectAll(items: any, key: any) {
		let body = {};
		body[key] = items;
		this.onChange.emit(body);
	}
	onDeSelectAll(items: any, key: any) {
		let body = {};
		body[key] = items;
		this.onChange.emit(body);
	}

	set_default_values(values) {
		console.log("Default Values: ", values);
		let keys = Object.keys(values);

		for (let i = 0; i < keys.length; i++) {
			try {
				this.defaultValues[keys[i]] = values[keys[i]];
				this.searchForm.controls[keys[i]].setValue(values[keys[i]].default_value)
			} catch (e) {
				console.log(e);
			}
		}

		if (this.defaultValues['status'] === undefined) {
			this.defaultValues['status'] = this.dropdown_status
		}
	}
}
