import { Injectable } from '@angular/core';
import {Custom_api_calling} from '../adapters/Custom_api_calling';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class ConfigurationService {
	bgtConfig = new BehaviorSubject({});
	castBgtConfig = this.bgtConfig.asObservable();

	pointConfig = new BehaviorSubject({});
	castPointConfig = this.pointConfig.asObservable();

	redemptionchannelMaster=new BehaviorSubject({});
	castredemptionchannelMaster=this.redemptionchannelMaster.asObservable();

    constructor(private customApiCalling:Custom_api_calling) { }

	loadBgtConfiguration(){
		this.customApiCalling.http_calling_get('api/tenant/tenants/get_bgt_configuration/', (data)=>{
			if(data['status']===true)
				this.bgtConfig.next(data['values'][0]);
		});
	}

	loadPointsConfiguration(){
		this.customApiCalling.http_calling_get('api/tenant/user/getProfile', (data)=>{
            if(data['status'] === true){
				this.pointConfig.next({
                    selling_point_rate : data['values'][0].selling_point_rate,
                    base_point_rate : data['values'][0].base_point_rate,
                    aging_mechanism : data['values'][0].aging_mechanism,
                    round_off_threshold : data['values'][0].round_off_threshold,
                    round_off_type : data['values'][0].round_off_type,
                    base_burn_mechanism : data['values'][0].base_burn_mechanism,
                    base_burn_point_rate : data['values'][0].base_burn_point_rate,
                    base_max_burn_points_percent : data['values'][0].base_max_burn_points_percent,
                });
            }else{
                this.pointConfig.next({
                    selling_point_rate : '',
                    base_point_rate : '',
                    aging_mechanism : '',
                    round_off_threshold : '',
                    round_off_type : '',
                    base_burn_mechanism : '',
                    base_burn_point_rate : 0,
                    base_max_burn_points_percent : 0,

                });
            }
		});
	}

	public fetchRuleFormData(callback:Function){
		this.customApiCalling.http_multi_url_get({
			'redemption_channel_Master' : 'api/tenant/user/getRedemptionChannel',

		   },callback)
	}

	get_all_categories(data,callback){
		let query_data = this.get_query_data(data);
		this.customApiCalling.http_calling_get('api/tenant/category/getAllCategory?'+query_data,(result)=>{
			callback(result);
		});
	}

	get_query_data(data) {
		let query_data = "";
		let keys = Object.keys(data);
		for (let i = 0; i < keys.length; i++) {
			if(data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]]!= undefined)
			query_data += (query_data === ""  ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
		}
		return query_data;
	}




}
