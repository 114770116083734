import { Directive, Input, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: 'href'
})
export class LinkDirective {

  constructor(elem: ElementRef, renderer: Renderer2) { 
    // renderer.setStyle(elem.nativeElement, 'box-shadow', '2px 2px 12px #58A362');
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}